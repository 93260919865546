import { FormGroup, IconButton, MenuItem } from '@mui/material';
import React from 'react';
import { Container, CustomButton, H1, Input, Label, P, PaperBox,Image, CustomSelect, CheckedBox, SearchableSelect, WarningModal } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import { RIDER_LIST } from '../../../../components/Constants';
import DataGridDemo, { CustomPagination } from '../../../../components/Tables';
import Images from '../../../../components/images'
import { ArrowBack, ArrowUpward, CheckBox, Search } from '@mui/icons-material';
import styles from './styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiFunctions, useFetchAreaRiders, useFetchAreasByLCDA, useFetchCountries, useFetchLCDAsByState, useFetchStatesByCountry, useFetchUnassignedRiders } from '../../../../utils/api';
import { Capitalize, ToastError, ToastSuccess } from '../../../../utils/functions';
import moment from 'moment';
import numeral from 'numeral';
import { useMutation, useQueryClient } from 'react-query';


const Riders = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [page,setPage] = React.useState(1)
    const [fPage] = React.useState(1)
    const [selected,setSelected] = React.useState([])
    const [pageCount,setPageCount] = React.useState(1)
    const [show,setShow] = React.useState(false)
    const [picked,setPicked] = React.useState({})
    
    const queryClient = useQueryClient()

    const {area_id,name} = useParams()

    const selectHandler = (item) => {
        let arr = [...selected]
        if(selected.includes(item.id)){
            arr.splice(arr.indexOf(item.id),1)
            return setSelected(arr)
        }
        return setSelected([...arr,item.id])
    }

    const [data,setData] = React.useState({
        country_id : "",
        state : {},
        lcda : {},
        area : {}
    })
    const [inputValue,setInputValue] = React.useState({
        state : "",
        lcda : "",
        area : ""
    })

    const [filterValue,setFilterValue] = React.useState({
        state : "",
        lcda : "",
    })
    const [filter,setFilter] = React.useState({
        country_id : "",
        state : {},
        lcda : {}
    })
    // These are used to filter the list of riders
    const {
        data : countries
    } = useFetchCountries(fPage)

    const {
        data : states,
        isLoading : loadingStates
    } = useFetchStatesByCountry(fPage,filter.country_id)

    const {
        data : lcdas,
        isLoading : loadingLCDAs
    } = useFetchLCDAsByState(fPage,filter?.state?.id)


    // These are used to assign riders to area

    const {
        data : country_list
    } = useFetchCountries(fPage)

    const {
        data : state_list,
        isLoading : loadingStateList
    } = useFetchStatesByCountry(fPage,data?.country_id)

    const {
        data : lcda_list,
        isLoading : loadingLCDAList
    } = useFetchLCDAsByState(fPage,data?.state?.id)

    const {
        data : areas,
        isLoading : loading
    } = useFetchAreasByLCDA(page,data?.lcda?.id)


    const {
        data : riders,
        isLoading
    } = useFetchUnassignedRiders(area_id,page)

    const {
        mutateAsync : deleteRider,
        isLoading : isDeleting
    } = useMutation(apiFunctions.deleteUser)

    const {
        data : areaRiders,
        isFetching
    } = useFetchAreaRiders(area_id,page)

    const onPageChange = (value) => {
        setPage(value)
    }

    React.useEffect(()=>{
        if(!riders) return
        setPage(riders?.data?.current_page)
        setPageCount(riders?.data?.last_page)
    },[riders])

    React.useEffect(()=>{
        if(!areaRiders) return
        setPage(areaRiders?.data?.current_page)
        setPageCount(areaRiders?.data?.last_page)
    },[areaRiders])


    const {
        mutateAsync,
        isLoading : assigning
    } = useMutation(apiFunctions.assignRider)

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const handleDeleteWarning = (item) => {
        setPicked(item)
        setShow(true)
    }

    const handleDelete = async () => {
        try{
            await deleteRider(picked?.id)
            ToastSuccess(`${picked?.rider?.first_name} has been deleted`)
            queryClient.invalidateQueries("area_riders")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
    }

    const handleSubmit = async () => {
        try{
            if(!Object.values(data.area).length) return ToastError("Please select an area")
            if(!selected.length) return ToastError("Please select at least one rider")

            let load = {
                "rider_id": selected,
                "area_id": selected.map(item=>data.area.id)
            }
            await  mutateAsync(load)
            let msg = selected.length > 1 ? "Selected riders have successfully assigned" : "Selected rider has been successfully assigned"
            ToastSuccess(msg)
            setData({
                country_id : "",
                state : {},
                lcda : {},
                area : {}
            })
            setInputValue({
                state : "",
                lcda : "",
                area : ""
            })
            setSelected([])
            queryClient.invalidateQueries("unassigned_riders")
        }catch(err){
            ToastError(err.msg)
        }
    }

    const columns = [
        {
            field : "name",
            header : "Name",
            renderCell : (row) => area_id ? <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.rider?.first_name ? `${Capitalize(row?.rider?.first_name)} ` : null} {row?.rider?.last_name ? Capitalize(row?.rider?.last_name) : null}</H1> : <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.first_name ? `${Capitalize(row?.first_name)} ` : null} {row?.last_name ? Capitalize(row?.last_name) : null}</H1>
        },
        {
            field : "email",
            header : "Email",
            renderCell : (row) => area_id ? <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.rider?.email}</H1> : <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.email}</H1>
        },
        {
            field : "phone",
            header : "Phone",
            renderCell : (row) => area_id ? <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.rider?.phone}</H1> : <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.phone}</H1>
        },
        {
            field : "state",
            header : "State",
            renderCell : (row) => area_id ? <CustomButton height="auto" background={AppColors.lightPurple} borderradius={"10rem"} >
            <H1 color={AppColors.purple} fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.area?.state?.name ? Capitalize(row?.area?.state?.name) : null}</H1>
        </CustomButton>  : <CustomButton height="auto" background={AppColors.lightPurple} borderradius={"10rem"} >
                <H1 color={AppColors.purple} fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.state?.name ? Capitalize(row?.state?.name) : null}</H1>
            </CustomButton>
        },
        {
            field : "lcda",
            header : "LCDA",
            renderCell : (row) => area_id ? <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.area?.lcda?.name ? Capitalize(row?.area?.lcda?.name) : null}</H1> : <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.lcda?.name ? Capitalize(row?.lcda?.name) : null}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.created_at ? moment(row?.created_at).format("DD/MM/YYYY") : null}</H1>
        },
        {
            field : `${location.pathname.includes("/area-riders") ? "delete" : "select"}`,
            header : `${location.pathname.includes("/area-riders") ? "Delete" : "select"}`,
            renderCell : (row) => location.pathname.includes("/area-riders") ? <IconButton onClick={()=>handleDeleteWarning(row)}> 
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton> : <CheckedBox onClick={()=>selectHandler(row)}
                checked={selected.includes(row?.id)}
            />
        }
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"} marginbottom={"2rem"}>
            <Container>
                <DataGridDemo columns={columns}
                    hideFooter={false}
                    rows={area_id && areaRiders?.data?.data && Array.isArray(areaRiders?.data?.data) ? areaRiders?.data?.data : riders?.data?.data && Array.isArray(riders?.data?.data) ? riders?.data?.data : []}
                    loading={isLoading || isFetching}
                    CustomPagination={RenderPagination}
                    Toolbar={()=><Container display={"flex"} alignItems={"center"} marginBottom={"1rem"}>
                        {
                            !location.pathname.includes('/area-riders') ? <Container display={"flex"}
                                justifyContent={"space-between"}
                            >
                                <Container 
                                    width={"60%"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <H1 variant='h6' fontWeight={"bold"} fontSize={"1.011rem"}
                                        marginRight={"1rem"}
                                    >Rider’s List ({riders?.data?.data && Array.isArray(riders?.data?.data) ? numeral(riders?.data?.data.length).format("0,0") : 0})</H1>
                                    <CustomSelect 
                                            width="10rem"
                                        value={filter.country_id} onChange={(e)=>setFilter({...filter,country_id : e.target.value,state : {}, lcda : {}})}>
                                        {
                                            countries?.data?.data && Array.isArray(countries?.data?.data) ? countries.data.data.map((item,i)=>{
                                                return <MenuItem key={i} value={item.id}>{item?.name ? Capitalize(item.name) : ""}</MenuItem>
                                            }) : <MenuItem value="">Select Country</MenuItem>
                                        }
                                    </CustomSelect>
                                    <SearchableSelect 
                                        width={"10rem"}
                                        value={filter?.state}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.title === value.title
                                        }}
                                        onChange={(e,value)=>{
                                            setFilter({...filter,state : value})
                                        }}
                                        inputValue={filterValue?.state  ? filterValue?.state : ""}
                                        onInputChange={(event, newInputValue) => {
                                            setFilterValue({...filterValue,state : newInputValue });
                                        }}
                                        getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                        options={states?.data?.data || []}
                                        loading={loadingStates}
                                    />
                                    <SearchableSelect 
                                        width={"10rem"}
                                        value={filter?.lcda}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.title === value.title
                                        }}
                                        onChange={(e,value)=>{
                                            setFilter({...filter,lcda : value})
                                        }}
                                        inputValue={filterValue?.lcda  ? filterValue?.lcda : ""}
                                        onInputChange={(event, newInputValue) => {
                                            setFilterValue({...filterValue,lcda : newInputValue });
                                        }}
                                        getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                        options={lcdas?.data?.data || []}
                                        loading={loadingLCDAs}
                                    />
                                </Container>
                                <Input placeholder='Search for rider' />
                            </Container> : <Container>
                                    <IconButton onClick={()=>navigate(-1)} 
                                        sx={styles.backButton}
                                    >
                                        <ArrowBack />
                                    </IconButton>
                                    <P fontSize={"1.018rem"}>Riders in {name ? Capitalize(name) : null}</P>
                                </Container>
                        }
                    </Container>}
                />
            </Container>
            {
                !location.pathname.includes('/area-riders') ? <Container display={"flex"} alignItems={"center"} marginBottom={"1rem"}>
                    <Container 
                        // display={"flex"}
                        // alignItems={"center"}
                        // justifyContent={"space-between"}
                    >
                        <P color={AppColors.grey}
                                fontSize={"0.938rem"}
                            >Assign Selected Riders to new area of operations</P>
                        <Container display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            marginTop={"2rem"}
                        >
                            <CustomSelect width={"12rem"}
                                value={data.country_id} onChange={(e)=>setData({...filter,country_id : e.target.value,state : {}, lcda : {},area : {}})}
                            >
                                {
                                    country_list?.data?.data && Array.isArray(country_list?.data?.data) ? country_list.data.data.map((item,i)=>{
                                        return <MenuItem key={i} value={item.id}>{item?.name ? Capitalize(item.name) : ""}</MenuItem>
                                    }) : <MenuItem value="">Select Country</MenuItem>
                                }
                            </CustomSelect>
                            <SearchableSelect 
                                width={"12rem"}
                                value={data?.state}
                                isOptionEqualToValue={(option, value) => {
                                    return option.title === value.title
                                }}
                                onChange={(e,value)=>{
                                    setData({...data,state : value,lcda : {},area : {}})

                                }}
                                inputValue={inputValue?.state  ? inputValue?.state : ""}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue({...inputValue,state : newInputValue });
                                }}
                                getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                options={state_list?.data?.data || []}
                                loading={loadingStateList}
                            />
                            <SearchableSelect 
                                width={"12rem"}
                                value={data?.lcda}
                                isOptionEqualToValue={(option, value) => {
                                    return option.title === value.title
                                }}
                                onChange={(e,value)=>{
                                    setData({...data,lcda : value,area : {}})
                                }}
                                inputValue={inputValue?.lcda  ? inputValue?.lcda : ""}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue({...inputValue,lcda : newInputValue });
                                }}
                                getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                options={lcda_list?.data?.data || []}
                                loading={loadingLCDAList}
                            />
                            <SearchableSelect 
                                width={"12rem"}
                                value={data?.area}
                                isOptionEqualToValue={(option, value) => {
                                    return option.title === value.title
                                }}
                                onChange={(e,value)=>{
                                    setData({...data,area : value})
                                }}
                                inputValue={inputValue?.area  ? inputValue?.area : ""}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue({...inputValue,area : newInputValue });
                                }}
                                getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                options={areas?.data?.data || []}
                                loading={loading}
                            />
                        </Container>
                        <Container display={"flex"} justifyContent={"flex-end"} marginTop={"2rem"}>
                            <CustomButton variant='contained' mode="danger" width={"10rem"}
                                onClick={handleSubmit}
                                loading={assigning}
                            >
                                <H1>Save</H1>
                            </CustomButton>
                        </Container>
                    </Container>
                </Container> : null
            }
             <WarningModal 
                    open={show} handleDelete={handleDelete} closeWarning={closeWarning}
                    loading={isDeleting}
                    selection={picked?.rider?.first_name}
                />
        </PaperBox>
    )
}

export default Riders;