import {createSlice} from '@reduxjs/toolkit'

const drawerSlice = createSlice({
    name : "DrawerHandler",
    initialState : {
        open : false,
        name : ""
    },
    reducers : {
        drawerHandler : (state,action) => {
            return {...state,name : action.payload,open : !state.open}
        }
    }
})

export const {drawerHandler} = drawerSlice.actions;
export default drawerSlice.reducer;