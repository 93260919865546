import React from "react"
import { FormGroup,IconButton, InputAdornment} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { LinkedButton,H1, Container, CustomButton, P, Input, Label } from "../../components"
import { AppColors } from "../../components/AppColors"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import {useMutation} from 'react-query'
import { apiFunctions } from "../../utils/api"
import { ToastError, ToastSuccess, validateEmail } from "../../utils/functions"

const ResetPwd = () => {
    const navigate = useNavigate()
    const [show,setShow] = React.useState(false)
    const [password,setPassword] = React.useState("")
    const {otp} = useParams()
    const { 
        mutateAsync, isLoading
    } = useMutation(apiFunctions.resetPassword)
    const handleShow = () => {
        setShow(!show)
    }
    const handleSubmit = async () => {
        try{
            if(!password.toString().trim() === ""){
                return ToastError("Please provide your new password")
            }
            let fd = {
                "token": otp,
                "password": password,
                "password_confirmation": password
            }
            await mutateAsync(fd)
            ToastSuccess("Password reset was successful")
            navigate("/login")
        }catch(err){
            ToastError(err.msg)
        }
    }

    return(
        <Container item md={7}>
            <Container
                justifyContent={"right"}
                display={"flex"}
                alignItems={"center"}
                width="90%"
                marginTop={"2rem"}
                marginBottom={"5rem"}
            >
                <LinkedButton to="/login" color={AppColors.grey}>Login</LinkedButton>
            </Container>
            <Container margin="2rem auto" width={"60%"}>
                <H1 variant="h5" fontWeight={'bold'} textAlign={'center'} 
                    marginBottom={"1rem"}
                    fontSize={"1.5rem"}
                    marginTop={"2rem"}
                >Create New Password</H1>
                <P textAlign={"center"} fontSize={"0.948rem"}>Please create a new password.</P>
                <form>
                    <Container container justifyContent={"space-around"}
                            marginTop={"2rem"}
                        >
                            <Container item md={12} marginBottom={"2rem"}>
                                <FormGroup>
                                    <Label>Create a new password *</Label>
                                    <Input placeholder="*******" type={!show ? "password" : "text"} 
                                        value={password} onChange={e=>setPassword(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleShow}
                                                      >
                                                        {show ? <VisibilityOff /> : <Visibility />}
                                                      </IconButton>
                                                    </InputAdornment>
                                                  }

                                    />
                                </FormGroup>
                            </Container>
                        </Container>
                        <Container container display={"flex"} justifyContent={"center"} alignItems={"center"}
                            direction={"column"}
                            marginTop={"3rem"}
                        >
                            <CustomButton mode="danger" variant="contained" width="20rem" marginbottom={"2rem"}
                                disabled={isLoading}
                                loading={isLoading}
                                onClick={handleSubmit}
                            >
                                Continue
                            </CustomButton>
                        </Container>
                </form>
            </Container>
    </Container>
    )
}

export default ResetPwd