import { ArrowBack } from '@mui/icons-material';
import { Avatar, IconButton, Skeleton} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, H1, P, PaperBox} from '../../../components'
import { AppColors } from '../../../components/AppColors';
import BackButton from '../../../components/BackButton';
import { PageLoader } from '../../../components/ProfileSkeleton';
import { useFetchUser } from '../../../utils/api';
import { Capitalize } from '../../../utils/functions';
import styles from './styles';

const AdminPage = () => {
    const navigate = useNavigate()
    const {admin_id} = useParams()
    const {
        data : admin,
        isLoading
    } = useFetchUser(admin_id)
    
    return(
        <PaperBox padding={"2rem"} margin={"2rem 0"}>
            <BackButton />
            {
                isLoading ? <Container>
                    <PageLoader />
                </Container> : (
                    <React.Fragment>
                        <Container>
                            <Avatar 
                                sx={styles.avatar}
                                src={admin?.data?.image_url ? admin?.data?.image_url : null}
                            />
                            <H1 fontSize={"1.349rem"}>{admin?.data?.first_name ? Capitalize(admin?.data?.first_name) : null} {admin?.data?.last_name ? Capitalize(admin?.data?.last_name) : null}</H1>
                            {/* <P fontSize={"1.068rem"} color={AppColors.grey}>Admin User 123</P> */}
                        </Container>
                        <Container marginTop={"2rem"}>
                            <H1 fontSize={"0.977rem"} color={AppColors.red}
                            >Admin details</H1>
    
                            {
                                [
                                    {
                                        title : "Email address",
                                        value : admin?.data?.email
                                    },
                                    {
                                        title : "Phone number",
                                        value : admin?.data?.phone
                                    },
                                    {
                                        title : "Address",
                                        value : admin?.data?.address
                                    }
                                ].map((item,index)=><Container key={index} flexDirection={"row"}
                                    width={"40%"}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    marginTop={"1rem"}
                                >
                                    <P fontSize={"0.814rem"} color={AppColors.grey}>{item.title}</P>
                                    <Container width={"60%"}>
                                        <H1 fontSize={"0.814rem"}>{item.value}</H1>
                                    </Container>
                                </Container>)
                            }
                        </Container>
                        <Container marginTop={"2rem"} borderRadius={"0.53rem"}
                            border={`1px solid ${AppColors.grayBorder}`}
                            padding={'1rem'}
                            width={"40%"}
                            background={AppColors.lightestRed}
                        >
                            <H1 color={AppColors.red}
                                fontSize={"0.927rem"}
                            >Identity Verification API Result</H1>
    
                            {
                                [
                                    {
                                        title : "DOB",
                                        value : admin?.data?.dob ? moment(admin?.data?.dob).format("DD/MM/YYYY") : null
                                    },
                                    {
                                        title : "Gender",
                                        value : admin?.data?.gender ? Capitalize(admin?.data?.gender) : null
                                    },
                                    {
                                        title : "Email Address",
                                        value : admin?.data?.email
                                    },
                                    {
                                        title : "Address",
                                        value : admin?.data?.address
                                    },
                                    {
                                        title : "Phone",
                                        value : admin?.data?.phone
                                    },
                                    {
                                        title : "Bank name",
                                        value : admin?.data?.bank ? Capitalize(admin?.data?.bank.toString()) : null
                                    },
                                    {
                                        title : "Account number",
                                        value : admin?.data?.bank
                                    },
                                    {
                                        title : "BVN",
                                        value : ""
                                    }
                                ].map((item,index)=><Container key={index}
                                    flexDirection={"row"}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    marginTop={"1rem"}
                                >
                                    <P fontSize={"0.814rem"} color={AppColors.grey}>{item.title}</P>
                                    <Container width={"60%"}>
                                        <H1 fontSize={"0.814rem"}>{item.value}</H1>
                                    </Container>
                                </Container>)
                            }
                        </Container>
                    </React.Fragment>
                )
            }
        </PaperBox>
    )
}

export default AdminPage;