const styles = {
    circular : {
        width : "6rem",
        height : "6rem",
        margin : "1rem auto"
    },
    rectangular : {
        height : "10rem",
        marginTop : "2rem"
    }
}

export default styles;