import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, CustomButton, H1,Image,P } from '.';
import { AppColors } from './AppColors';
import { PAYMENT_METHOD_LIST } from './Constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack } from '@mui/material';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius : '2rem',
    boxShadow: 24,
    p: 4,
  };
  

export const FUND_WALLET_MODAL = (props) => {
    return(
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={()=>props.setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <Box sx={style}>
                    <Container 
                        background={AppColors.white}
                        
                    >
                        <H1 fontWeight={"bold"} fontSize={"1.316rem"}>Select payment method </H1>
                        <Stack spacing={2} marginTop={"1rem"} marginBottom={"1rem"}>
                            {
                                PAYMENT_METHOD_LIST.map((item,i)=><CustomButton key={i}
                                    height={"6rem"}
                                    width={"85%"}
                                >
                                <Container 
                                    display="flex"
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    container
                                >
                                    <Container width={"60%"} 
                                        flexDirection={"row"}
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <Image backgroundimage={item.icon}
                                            width={"4rem"}
                                            height={"4rem"}
                                            marginRight={"1rem"}
                                        />
                                        <Container>
                                            <H1 fontWeight={"bold"} textAlign={"left"} color={AppColors.black}>{item.title}</H1>
                                            <P color={AppColors.textGrey}
                                                textAlign={"left"}
                                            >{item.description}</P>
                                        </Container>
                                    </Container>
                                    <ArrowForwardIosIcon fontSize='small'
                                        sx={{
                                            color : AppColors.black
                                        }}
                                    />
                                </Container>
                            </CustomButton>)
                            }
                        </Stack>
                    </Container>
                </Box>
            </Fade>
        </Modal>
    )
}