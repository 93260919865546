import { AppColors } from "../../../components/AppColors";

const styles = {
    arrowUP : {
        color : AppColors.green,
        fontSize : "1rem"
    },
    verifiedLabel : {
        color : AppColors.greenBackground,
        fontSize : "1rem"
    },
    unverifiedLabel : {
        color : AppColors.lightRed,
        fontSize : "1rem"
    }
}

export default styles;