import { MenuItem } from "@mui/material";
import React from "react";
import { Container, CustomButton, CustomSelect, H1, Image, P,PaperBox, WalletCard } from "../../../../components";
import { AppColors } from "../../../../components/AppColors";
import { TRANSACTION_LIST, WALLET_CARD_LIST, WALLET_MENU_LIST } from "../../../../components/Constants";
import { FUND_WALLET_MODAL } from "../../../../components/Modals";
import DataGridDemo from "../../../../components/Tables";
import { TRANSACTION_LIST_TOOLBAR } from "../../../../components/Toolbars";


const ClientsWallet = () => {
    const [open,setOpen] = React.useState(false)
    const columns = [
        {
            field : "orderID",
            header : "Order ID",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "transaction_type",
            header : "Transaction Type",
            renderCell : (value) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "delivery_fee",
            header : "Delivery Fee",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "collected_by",
            header : "Collected By",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "status",
            header : "Status",
            renderCell : (value) => value === "Paid" ? <CustomButton background={AppColors.lightLime} borderradius={"0.5rem"}>
                <P color={AppColors.lime}>Paid</P>
            </CustomButton> : <CustomButton background={AppColors.lightRed} borderradius={"0.5rem"}>
                <P color={AppColors.red}>Unpaid</P>
            </CustomButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row[item.field]),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"} margin={"0 0 2rem 0"}>
            <Container marginTop={"2rem"} container>
                        <Container item md={3}
                            display={"flex"} 
                            alignItems={"center"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                        >
                            <Container>
                                <H1 variant="h6" fontWeight={"bold"} fontSize={"1.173rem"} marginBottom={"1.5rem"}
                                    textAlign={"left"}
                                >Search Client</H1>
                            </Container>
                            <CustomSelect>
                                <MenuItem>Chicken Republic</MenuItem>
                                <MenuItem>Bukkahut Ogudu</MenuItem>
                            </CustomSelect>
                        </Container>
                        <Container 
                            item md={4} display={"flex"} alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <Container width={"80%"}>
                                <H1 variant="h6" fontWeight={"bold"}
                                    color={AppColors.red}
                                    fontSize={"1.291rem"}
                                    marginBottom={"1.5rem"}
                                    singleline="true"
                                >Bukkahut Ogudu</H1>
                                <P fontSize={"1.25rem"} color={AppColors.grayBorder}>Wallet balance</P>
                                <H1 fontSize={"2.411rem"} fontWeight={"bold"}>N20,150</H1>
                            </Container>
                        </Container>
                        <Container item md={3.5}>
                            <WalletCard 
                                serial={"5339    7642    8674    7245"}
                                name="BUKKAHUT RESTAURANTS - OGUDU OUTLETS"
                                expiry="02/30"
                            />
                        </Container>
                    </Container>
                    <Container container marginTop={"2rem"}>
                        <Container display={"flex"} md={11}
                            justifyContent={"space-between"}
                            item
                        >
                            {
                                WALLET_CARD_LIST.map((item,i)=>(
                                    <Container key={i} background={item.background}
                                        width={"19rem"}
                                        padding={"1rem"}
                                        borderRadius={"0.6rem"}
                                    >
                                        <H1 variant="h6" fontWeight={"bold"} color={AppColors.black} fontSize={"1.322rem"}
                                            textAlign={"center"}
                                        >{item.balance}</H1>
                                        <P fontSize={"0.841rem"} textAlign={"center"}>{item.title}</P>
                                        <P fontSize={"0.721rem"} color={AppColors.grey} textAlign={"center"}>{item.description}</P>
                                    </Container>
                                ))
                            }
                        </Container>
                    </Container>

                    <Container marginTop={"2rem"}>
                        <DataGridDemo columns={columns} rows={TRANSACTION_LIST}
                            hideFooter={true}
                            Toolbar={TRANSACTION_LIST_TOOLBAR}
                        />
                    </Container>
                    <FUND_WALLET_MODAL open={open} setOpen={setOpen} />
        </PaperBox>
    )
}

export default ClientsWallet;