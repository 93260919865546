import { AppColors } from "../../../components/AppColors";

const styles = {
    avatar : {
        height : "8rem",
        width : "8rem"
    },
    backButton : {
        backgroundColor : AppColors.offWhite,
        marginRight : "1rem",
        marginBottom : "2rem"
    },
}

export default styles;