import { FormGroup, IconButton, MenuItem } from '@mui/material';
import React,{useEffect,useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomButton, H1, P, PaperBox,Image, Label, Input, CustomSelect, SearchableSelect } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import { AREA_LIST } from '../../../../components/Constants';
import DataGridDemo, { CustomPagination } from '../../../../components/Tables';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useFetchAreasByLCDA, useFetchCountries, useFetchLCDAsByState, useFetchStatesByCountry } from '../../../../utils/api';
import { Capitalize } from '../../../../utils/functions';
import moment from 'moment';


const ListArea = () => {
    const navigate = useNavigate()
    const [page,setPage] = React.useState(1)
    const [show,setShow] = React.useState(false)
    const [selected,setSelected] = React.useState({})
    const [pageCount,setPageCount] = React.useState(1)

    const [inputValue,setInputValue] = React.useState({
        state : "",
        lcda : ""
    })
    const [filter,setFilter] = React.useState({
        country_id : "",
        state : {},
        lcda : {}
    })

    const [fPage] = React.useState(1)

    const {
        data : areas,
        isLoading : loading
    } = useFetchAreasByLCDA(page,filter?.lcda?.id)

    const {
        data : countries
    } = useFetchCountries(fPage)

    const {
        data : states,
        isLoading : loadingStates
    } = useFetchStatesByCountry(fPage,filter.country_id)

    const {
        data : lcdas,
        isLoading : loadingLCDAs
    } = useFetchLCDAsByState(fPage,filter?.state?.id)

    useEffect(()=>{
        if(!areas) return
        setPage(areas?.data?.current_page)
        setPageCount(areas?.data?.last_page)
    },[areas])

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        // {
        //     field : "sn",
        //     header : "SN",
        //     renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        // },
        // {
        //     field : "state",
        //     header : "State",
        //     renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{value}</H1>
        // },
        {
            field : "area",
            header : "Area",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.name ? Capitalize(row.name) : null}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.created_at ? moment(row?.created_at).format("DD/MM/YYYY") : null}</H1>
        },
        {
            field : "delete",
            header : "View Rider",
            renderCell : (row) => <IconButton onClick={()=>navigate(`/user/area-riders/${row.id}/${row.name}, ${filter?.state?.name}`)}>
                <RemoveRedEyeIcon />
            </IconButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"}>
            <Container>
                <P fontSize={"1.018rem"} marginBottom={"2rem"}>View created area & riders in each state & LCDA  </P>
                <DataGridDemo columns={columns} 
                    rows={areas?.data && Array.isArray(areas?.data?.data) ? areas?.data?.data : []}
                    hideFooter={false}
                    loading={loading}
                    CustomPagination={RenderPagination}
                    hideFooter={false}
                    
                    Toolbar={()=><Container container spacing={2} marginBottom={"2rem"}>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Country</Label>
                                <CustomSelect value={filter.country_id} onChange={(e)=>setFilter({...filter,country_id : e.target.value,state : {}, lcda : {}})}>
                                    {
                                        countries?.data?.data && Array.isArray(countries?.data?.data) ? countries.data.data.map((item,i)=>{
                                            return <MenuItem key={i} value={item.id}>{item?.name ? Capitalize(item.name) : ""}</MenuItem>
                                        }) : <MenuItem value="">Select Country</MenuItem>
                                    }
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>State</Label>
                                <SearchableSelect 
                                    value={filter?.state}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.title === value.title
                                    }}
                                    onChange={(e,value)=>{
                                        setFilter({...filter,state : value})
                                    }}
                                    inputValue={inputValue?.state  ? inputValue?.state : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,state : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={states?.data?.data || []}
                                    loading={loadingStates}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>LCDAs</Label>
                                <SearchableSelect 
                                    value={filter?.lcda}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.title === value.title
                                    }}
                                    onChange={(e,value)=>{
                                        setFilter({...filter,lcda : value})
                                    }}
                                    inputValue={inputValue?.lcda  ? inputValue?.lcda : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,lcda : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={lcdas?.data?.data || []}
                                    loading={loadingLCDAs}
                                
                                />
                            </FormGroup>
                        </Container>
                    </Container>}
                />
            </Container>
        </PaperBox>
    )
}

export default ListArea;