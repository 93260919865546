import { Avatar, MenuItem, Stack} from '@mui/material';
import React, { useEffect } from 'react';
import { Container, CustomButton, CustomSelect, H1, Image, P, PaperBox} from '../../../components'
import { AppColors } from '../../../components/AppColors';
import Images from '../../../components/images'
import CircleIcon from '@mui/icons-material/Circle';
import styles from './styles';
import DataGridDemo, { CustomPagination } from '../../../components/Tables';
// import { RIDE_HISTORY } from '../../../components/Constants';
import BackButton from '../../../components/BackButton';
import { useFetchRiderHistory, useFetchUser } from '../../../utils/api';
import { useParams } from 'react-router-dom';
import { PageLoader } from '../../../components/ProfileSkeleton';
import numeral from 'numeral';
import { Capitalize } from '../../../utils/functions';
// import moment from 'moment';


const RiderPage = () => {
    const {rider_id} = useParams()
    const [pageCount,setPageCount] = React.useState(1)
    const [page,setPage] = React.useState(1)
    const {
        data : rider,
        isLoading
    } = useFetchUser(rider_id)

    const {
        data : orders,
        isFetching
    } = useFetchRiderHistory(rider_id,page)

    const downloadNow = () => {
        if(!rider?.data?.document_url) return
        window.open(rider?.data?.document_url)
    }

    useEffect(()=>{
        if(!orders?.data?.current_page) return
        setPage(orders?.data?.current_page)
        setPageCount(orders?.data?.last_page)
    },[rider])

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        {
            field : "orderID",
            header : "Order ID",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.order_ref}</H1>
        },
        {
            field : "area",
            header : "Area of operation",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.area?.name ? Capitalize(row?.area?.name) : null}</H1>
        },
        {
            field : "pickup",
            header : "Pickup area",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.pickup_address ? Capitalize(row?.pickup_address) : null}</H1>
        },
        {
            field : "dropoff",
            header : "Delivery address",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.delivery_address ? Capitalize(row?.delivery_address) : null}</H1>
        },
        {
            field : "lcda",
            header : "LCDA",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.lcda?.name ? Capitalize(row?.lcda?.name) : null}</H1>
        },
        {
            field : "imei",
            header : "IMEI",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.imei}</H1>
        },
        {
            field : "timespent",
            header : "Time spent",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.delivery_time || null}</H1>
        }
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));
    return(
        <Container>
            <BackButton />
            {
                isLoading ? <PageLoader /> : (
                    <React.Fragment>
                        <Container 
                            marginTop={"2rem"}
                            marginBottom={"2rem"}
                            display={"grid"}
                            gridTemplateColumns={"auto auto auto auto auto"}
                            gap={2}
                        >
                            {
                                [
                                    {
                                        title : "Wallet Balance",
                                        value : rider?.metrics?.wallet ? numeral(rider?.metrics?.wallet).format("0,0") : 0
                                    },
                                    {
                                        title : "Total rides",
                                        value : rider?.metrics?.total_rides ? numeral(rider?.metrics?.total_rides).format("0,0") : 0
                                    },
                                    {
                                        title : "Total distance covered",
                                        value : `${rider?.metrics?.total_distance ? numeral(rider?.metrics?.total_distance).format("0,0") : 0}Km`
                                    },
                                    {
                                        title : "Total calories burnt",
                                        value : `${rider?.metrics?.total_calories ? numeral(rider?.metrics?.total_calories).format("0,0") : 0}Cal`
                                    },
                                    {
                                        title : "Total co2 covered",
                                        value : `${rider?.metrics?.total_co2 ? numeral(rider?.metrics?.total_co2).format("0,0") : 0}Kg`
                                    }
                                ].map((item,index)=><Container 
                                    key={index} padding={"1rem"}
                                    borderRadius={"0.5rem"}
                                    border={item.title !== "Wallet Balance" ? `1px solid ${AppColors.grayBorder}` : null}
                                    backgroundColor={item.title !== "Wallet Balance" ? AppColors.white : AppColors.lightRed}
                                    width={"13rem"}
                                >
                                    <P fontSize={"0.938rem"}>{item.title}</P>
                                    <H1 fontSize={"1.75rem"} fontWeight={"bold"} marginTop={"1rem"}>{item.value}</H1>
                                </Container>
                            )}
                        </Container>
                        <Container 
                            flexDirection={"row"}
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <PaperBox 
                                padding={"1rem"}
                                width={"30%"}
                            >
                                <H1 fontSize={"0.977rem"} color={AppColors.red}
                                    >Rider details</H1>

                                    {
                                        [
                                            {
                                                title : "Email address",
                                                value : rider?.data?.email
                                            },
                                            {
                                                title : "Phone number",
                                                value : rider?.data?.phone
                                            },
                                            {
                                                title : "Address",
                                                value : rider?.data?.address
                                            },
                                            {
                                                title : "Weight",
                                                value : rider?.data?.weight?.toString()
                                            },
                                            {
                                                title : "Height",
                                                value : rider?.data?.height?.toString()

                                            },
                                            {
                                                title : "Age",
                                                value : `${rider?.data?.age ? numeral(rider?.data?.age).format("0,0") : 0} years`
                                            },
                                        ].map((item,index)=><Container key={index} flexDirection={"row"}
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            marginTop={"1rem"}
                                        >
                                            <P fontSize={"0.814rem"} color={AppColors.grey}>{item.title}</P>
                                            <Container width={"60%"}>
                                                <H1 fontSize={"0.814rem"}>{item.value}</H1>
                                            </Container>
                                        </Container>)
                                    }
                                    <Container marginTop={"2rem"}>
                                        <P color={AppColors.red} fontSize={"0.814rem"}>Assigned Area of Operation</P>
                                        <Container 
                                            padding={"1.2rem"}
                                            borderRadius={"0.977rem"}
                                            background={AppColors.greyBackground}
                                            marginTop={"0.7rem"}
                                        >
                                            {
                                                [
                                                    {
                                                        title : "Country",
                                                        value : rider?.data?.country?.name ? Capitalize(rider?.data?.country?.name) : null
                                                    },
                                                    {
                                                        title : "State",
                                                        value : rider?.data?.state?.name ? Capitalize(rider?.data?.state?.name) : null
                                                    },
                                                    {
                                                        title : "LCDA",
                                                        value : rider?.data?.lcda?.name ? Capitalize(rider?.data?.lcda?.name) : null
                                                    }
                                                ].map((item,index)=><Container key={index}
                                                    flexDirection={"row"}
                                                    display={"flex"}
                                                    marginTop={index !== 0 ? "0.5rem" : null}
                                                    alignItems={"center"}
                                                >
                                                    <P color={AppColors.grey} marginRight={"0.3rem"}
                                                        fontSize={"0.814rem"}
                                                    >{item.title} : </P>
                                                    <P fontSize={"0.814rem"}>{item.value}</P>
                                                </Container>)
                                            }
                                        </Container>
                                    </Container>
                            </PaperBox>
                            <Container borderRadius={"0.53rem"}
                                border={`1px solid ${AppColors.grayBorder}`}
                                padding={'1rem'}
                                width={"35%"}
                                background={AppColors.lightestRed}
                            >
                                <H1 color={AppColors.red}
                                    fontSize={"0.927rem"}
                                >Identity Verification API Result</H1>

                                {
                                    [
                                        {
                                            title : "DOB",
                                            value : rider?.data?.dob
                                        },
                                        {
                                            title : "Gender",
                                            value : rider?.data?.gender ? Capitalize(rider?.data?.gender ) : null
                                        },
                                        {
                                            title : "Email Address",
                                            value : rider?.data?.email ? Capitalize(rider?.data?.email) : null
                                        },
                                        {
                                            title : "Address",
                                            value : rider?.data?.address ? Capitalize(rider?.data?.address) : null
                                        },
                                        {
                                            title : "Phone",
                                            value : rider?.data?.phone
                                        },
                                        {
                                            title : "Bank name",
                                            value : rider?.data?.bank?.toString()
                                        },
                                        {
                                            title : "Account number",
                                            value : ""
                                        },
                                        {
                                            title : "BVN",
                                            value : ""
                                        }
                                    ].map((item,index)=><Container key={index}
                                        flexDirection={"row"}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                        marginTop={"1rem"}
                                    >
                                        <P fontSize={"0.814rem"} color={AppColors.grey}>{item.title}</P>
                                        <Container width={"60%"}>
                                            <H1 fontSize={"0.814rem"}>{item.value}</H1>
                                        </Container>
                                    </Container>)
                                }
                            </Container>
                            <Container width={"25%"}>
                                <PaperBox padding="1rem">
                                    <Container
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        display={"flex"}
                                    >
                                        <Container width={"60%"}>
                                            <H1 marginBottom={"1rem"}>{rider?.data?.first_name ? `${Capitalize(rider?.data?.first_name)} ` : ""} {rider?.data?.last_name ? `${Capitalize(rider?.data?.last_name)} ` : ""}</H1>
                                            <CustomButton background={AppColors.lightRed} height={"auto"}>
                                                <H1 color={AppColors.red}>Bikee Rider</H1>
                                            </CustomButton>
                                        </Container>
                                        <Avatar 
                                            src={rider?.data?.image_url}
                                            sx={styles.avatar}
                                        />
                                    </Container>
                                </PaperBox>

                                <PaperBox padding="1rem" margin={"1.5rem 0"}>
                                    <H1 marginBottom={"1rem"} fontSize={"0.825rem"}>Identification card</H1>
                                    <Container
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        display={"flex"}
                                    >
                                        <Image 
                                            backgroundimage={rider?.data?.document_url || Images.Redbg}
                                            width={"9rem"}
                                            height={"5rem"}
                                            backgroundsize={"cover"}
                                        />
                                        <CustomButton width={"4rem"} background={AppColors.darkGrey}
                                            height={"1.8rem"}
                                            onClick={downloadNow}
                                        >
                                            <H1 color={AppColors.grey} fontSize={"0.541rem"}>Download</H1>
                                        </CustomButton>
                                    </Container>
                                </PaperBox>

                                <PaperBox padding="1rem" margin={"1.5rem 0"}>
                                    <H1 marginBottom={"1rem"} fontSize={"0.825rem"}>KPI Performance till date</H1>
                                    <Container
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        display={"flex"}
                                    >
                                        <Container>
                                            {
                                                [
                                                    {
                                                        color : AppColors.yellow,
                                                        title : "Pick-up",
                                                        value : rider?.kpi?.pickup ? numeral(rider?.kpi?.pickup).format("0,0") : 0
                                                    },
                                                    {
                                                        color : AppColors.green,
                                                        title : 'Delivered',
                                                        value : rider?.kpi?.delivered ? numeral(rider?.kpi?.delivered).format("0,0") : 0
                                                    },
                                                    {
                                                        color : AppColors.red,
                                                        title : 'Canceled',
                                                        value : rider?.kpi?.cancelled ? numeral(rider?.kpi?.cancelled).format("0,0") : 0
                                                    }
                                                ].map((item,index)=><Container key={index} marginBottom={"1.5rem"}>
                                                    <Container container
                                                        flexDirection={"row"}
                                                        alignItems={"center"}
                                                    >
                                                        <CircleIcon sx={{
                                                            color : item.color,
                                                            marginRight : "1rem",
                                                            fontSize : "0.8rem"
                                                        }} />
                                                        <P fontSize={"0.591rem"}>{item.title}</P>
                                                    </Container>
                                                    <H1 fontSize={"0.76rem"} marginTop={"0.3rem"}>{item.value ? numeral(item.value).format("0,0") : 0}</H1>
                                                </Container>)
                                            }
                                        </Container>
                                        <Image 
                                            backgroundimage={Images.Performance}
                                            width={"13rem"}
                                            height={"10rem"}
                                            backgroundsize={"contain"}
                                        />
                                    </Container>
                                </PaperBox>
                            </Container>
                        </Container>
                        <PaperBox padding={"2rem"} margin={"2rem 0"}>
                            <Container>
                                <DataGridDemo columns={columns} 
                                    loading={isFetching}
                                    hideFooter={false}
                                    rows={orders?.data?.data && Array.isArray(orders?.data?.data) ? orders?.data?.data : []}
                                    hideFooter={false}
                                    CustomPagination={RenderPagination}

                                    Toolbar={()=><Container display={"flex"} alignItems={"center"} marginBottom={"1rem"}>
                                        <Container 
                                            width={"60%"}
                                                display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <H1 variant='h6' fontWeight={"bold"} fontSize={"1.011rem"}
                                                marginRight={"1rem"}
                                            >Ride History</H1>
                                            <CustomSelect width={"10rem"}>
                                                <MenuItem>Last 7 days</MenuItem>
                                            </CustomSelect>
                                        </Container>
                                    </Container>}
                                />
                            </Container>
                        </PaperBox>
                    </React.Fragment>
                )
            }
        </Container>
    )
}

export default RiderPage;