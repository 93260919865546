import { FormGroup, IconButton } from '@mui/material';
import React, { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Container, CustomButton, H1, P, PaperBox,Image, Label, Input, WarningModal } from '../../../components'
import { AppColors } from '../../../components/AppColors';
import Images from '../../../components/images';
import DataGridDemo, { CustomPagination } from '../../../components/Tables';
import { apiFunctions, useFetchCountries } from '../../../utils/api';
import { ToastError, ToastSuccess } from '../../../utils/functions';
import moment from "moment"


const CreateCountry = () => {
    const navigate = useNavigate()
    const [page,setPage] = React.useState(1)
    const [show,setShow] = React.useState(false)
    const queryClient = useQueryClient()
    const [selected,setSelected] = React.useState({})
    const [pageCount,setPageCount] = React.useState(1)


    const {
        mutateAsync : deleteCountry,
        isLoading : isDeleting
    } = useMutation(apiFunctions.deleteCountry)
    const {
        mutateAsync : updateCountry,
        isLoading : isUpdating
    } = useMutation(apiFunctions.updateCountry)

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.createCountry)
    const [data,setData] = React.useState({
        name : ""
    })
    const {
        data : countries,
        isLoading : loading
    } = useFetchCountries(page)

    const handleSubmit = async () => {
        try{
            if(
                data.name.toString().trim() === ""
            ){
                return ToastError("Please provide country name")
            }
            if(!data.id){
                await mutateAsync(data)
                ToastSuccess("New record has been added")
                setData({
                    name : ""
                })
                return queryClient.invalidateQueries("countries")
            }
            let fd = {
                name : data.name,
                id : data.id
            }
            await updateCountry(fd)
            ToastSuccess("Record has been updated")
            setData({
                name : ""
            })
            queryClient.invalidateQueries("countries")
        }catch(err){
            ToastError(err.msg)
        }
    }

    const handleDeleteWarning = (item) => {
        setSelected(item)
        setShow(true)
    }

    const handleDelete = async () => {
        try{
            await deleteCountry(selected.id)
            ToastSuccess(`${selected.name} has been deleted`)
            queryClient.invalidateQueries("countries")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
    }

    const handleEdit = (item) => {
        setData(item)
        window.scrollTo(0,0)
    }


    const columns = [
        // {
        //     field : "sn",
        //     header : "SN",
        //     renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        // },
        {
            field : "country_name",
            header : "Country name",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.name}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{moment(row?.created_at).format("DD/MM/YYYY")}</H1>
        },
        {
            field : "view",
            header : "States",
            renderCell : (row) => <CustomButton background={AppColors.greenBackground}
                height="auto"
                onClick={()=>navigate(`/user/create-state/${row.id}/${row.name}`)}
            >
                <H1 color={AppColors.green}>View</H1>
            </CustomButton>
        },
        {
            field : "edit",
            header : "Edit",
            renderCell : (row) => <IconButton onClick={()=>handleEdit(row)}>
                    <Image backgroundimage={Images.edit} width={"1.2rem"} height={"1.2rem"} />
                </IconButton>
        },
        {
            field : "delete",
            header : "Delete",
            renderCell : (row) => <IconButton onClick={()=>handleDeleteWarning(row)}>
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        sortable: false,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    useEffect(()=>{
        if(!countries) return
        setPage(countries?.data?.current_page)
        setPageCount(countries?.data?.last_page)
    },[countries])

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    return(
        <PaperBox padding={"2rem"}>
            <Container marginBottom={"2rem"}>
                <form>
                    <P fontSize={"1.018rem"} marginBottom={"2rem"}>List of all countries</P>
                    <Container
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                    width={"60%"}
                    >
                        <FormGroup>
                            <Label>Country name</Label>
                            <Input placeholder="Enter country name" type="text"
                                value={data?.name}
                                onChange={(e)=>setData({...data,name : e.target.value})}
                            />
                        </FormGroup>
                        <CustomButton variant='outlined'
                            width={"6rem"}
                            padding={"0.4rem"}
                            border={`1.5px solid ${AppColors.red}`}
                            borderradius={"0.452rem"}
                            onClick={handleSubmit}
                            loading={isLoading || isUpdating}
                        >
                            <H1 color={AppColors.red} fontWeight={"bold"}>Save</H1>
                        </CustomButton>
                    </Container>
                </form>
            </Container>
            
            <Container>
                <DataGridDemo columns={columns} 
                    rows={countries?.data?.data && Array.isArray(countries?.data?.data) ? countries?.data?.data : []}
                    hideFooter={false}
                    loading={loading}
                    CustomPagination={RenderPagination}
                />
            </Container>
            <WarningModal 
                open={show} handleDelete={handleDelete} closeWarning={closeWarning}
                loading={isDeleting}
                selection={selected?.name}
            />
        </PaperBox>
    )
}

export default CreateCountry;