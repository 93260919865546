import React from 'react'
import Box from '@mui/material/Box';
import { AppColors } from '../AppColors';
import {Container,H1,CustomButton, Image,P, PaperBox} from '../index';
import Images from '../images';

export const ProductDetails = () => {
    return(
        <Box
            sx={{ width: 600}}
            role="presentation"
        >
            <PaperBox padding={"2rem"}>
                <Container
                    flexDirection={"row"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginBottom={"2rem"}
                >
                    <H1 fontWeight={"bold"} fontSize={"1.066rem"}>#87gTF301</H1>
                    <CustomButton background={AppColors.yellow} width={"5rem"}>
                        <H1 color={AppColors.darkYellow} fontSize={"0.707rem"}>In-transit</H1>
                    </CustomButton>
                </Container>
                <H1 color={AppColors.purple}
                    fontSize={"0.767rem"}
                    fontWeight={"bold"}
                >Admin - Bukkahut place</H1>
                <Container padding={"0.5rem"} borderTop={`1px solid ${AppColors.line}`}
                    marginTop={"1rem"}
                >
                    {
                        [
                            {
                                icon : Images.AtIcon,
                                title : "bukkahut@bukkahut.com"
                            },
                            {
                                icon : Images.Phone,
                                title : "09078651628"
                            },
                            {
                                icon : Images.mappin,
                                title : "18b Sunday Adigun Street, Yaba,Lagos, Nigeria."
                            }
                        ].map((item,index)=><Container key={index} display={"flex"}
                            alignItems={"center"}
                            marginTop={"0.8rem"}
                        >
                            <Image 
                                backgroundimage={item.icon}
                                width={"1rem"}
                                height={"1rem"}
                            />
                            <P fontSize={"0.802rem"} marginLeft={"1rem"}>{item.title}</P>
                        </Container>)
                    }
                </Container>
                <Container marginTop={"1rem"}
                    padding={'1rem'}
                    backgroundColor={AppColors.lightestRed}
                    borderRadius={"0.767rem"}
                >
                    <H1 fontSize={'0.851rem'} color={AppColors.red} marginBottom={"1rem"}>Delivery details:</H1>
                    {
                        [
                            {
                                title : "Assigned BIKEE Rider",
                                value : "Ahmed Bala"
                            },
                            {
                                title : "Destination LCDA",
                                value : "Eti-Osa"
                            },
                            {
                                title : "ETA",
                                value : "10 mins"
                            },
                            {
                                title : "Payment Type",
                                value : "COD"
                            }
                        ].map((item,index)=><Container  
                            key={index}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            display={"flex"}
                            width="80%"
                            marginBottom={"1rem"}
                        >
                            <P fontSize={'0.851rem'} color={AppColors.grey}>{item?.title}</P>
                            <Container width={"50%"}>
                                <H1 fontSize={'0.851rem'} color={item.title === "Assigned BIKEE Rider" ? AppColors.purple : AppColors.black}>{item.value}</H1>
                            </Container>
                        </Container>)
                    }
                </Container>
                <Container marginTop={"1rem"}
                    padding={'1rem'}
                    backgroundColor={AppColors.lightestRed}
                    borderRadius={"0.767rem"}
                >
                    <H1 fontSize={'0.851rem'} color={AppColors.red}>Product description</H1>
                    <Container
                        marginTop={"1rem"}
                    >
                        {
                            [
                                {
                                    title : "Order Items",
                                    value : "Qty"
                                },
                                {
                                    title : "GUCCI SHOES",
                                    value : "1 Piece"
                                },
                                {
                                    title : "TURBAN",
                                    value : "2 Piece"
                                },
                                {
                                    title : "BALENCIAGA BAG",
                                    value : "1 Piece"
                                }
                            ].map((item,i)=><Container key={i}
                                flexDirection={"row"}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <P marginBottom={"0.5rem"}
                                    color={item.title === "Order Items" ? AppColors.grey : AppColors.black}
                                >{item.title}</P>
                                <Container width={"20%"}>
                                    {
                                        item.value === "Qty" ? <P color={AppColors.grey} singleline="true">
                                            {item.value}
                                        </P> : <H1 color={
                                            item.title === "Delivery fee" ? AppColors.red : 
                                            item.title === "Payment method" ? AppColors.lime : null
                                        } singleline="true">{item.value}</H1>
                                    }
                                </Container>
                            </Container>)
                        }
                    </Container>
                </Container>
                <Container 
                    marginTop={"1rem"}
                >
                    <H1 color={AppColors.grey} fontSize={"0.915rem"} marginBottom={"0.5rem"}>Amount to be collected</H1>
                    <P fontSize={"0.61rem"} color={AppColors.textGrey}>CASH ON DELIVERY</P>
                    <H1 color={AppColors.lime} fontSize={"0.813rem"} fontWeight={"bold"}>₦ 4O,390</H1>
                </Container>
            </PaperBox>
        </Box>
    )
}