import { FormGroup, IconButton, MenuItem, Skeleton } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Container, CustomButton, H1, Input, Label, P, PaperBox,Image, CustomSelect, SearchableSelect } from '../../../components'
import { AppColors } from '../../../components/AppColors';
import DataGridDemo, { CustomPagination } from '../../../components/Tables';
import Images from '../../../components/images'
import { ArrowUpward, Circle, Search } from '@mui/icons-material';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import { apiFunctions, useFetchAreasByLCDA, useFetchClients, useFetchCountries, useFetchIndustries, useFetchLCDAsByState, useFetchStatesByCountry, useFetchTopIndustries } from '../../../utils/api';
import { Capitalize, dataURLtoFile, Preview, ToastError, ToastSuccess } from '../../../utils/functions';
import { useMutation, useQueryClient } from 'react-query';
import ReactCropper from '../../../components/Cropper';
import numeral from 'numeral';
import { useDebounce } from 'use-debounce';


const Clients = () => {
    const navigate = useNavigate()
    const [page,setPage] = React.useState(1)
    const queryClient = useQueryClient()
    const [pageCount,setPageCount] = React.useState(1)
    const upload = useRef(null)
    const [open,setOpen] = React.useState(false)
    const [image,setImage] = React.useState(null)
    const [profile_img,setProfileImg] = React.useState(null)
    const [fPage] = React.useState(1)
    const [search,setSearch] = React.useState("")
    const [searchTerm] = useDebounce(search,200)
    const [data,setData] = React.useState({
        "name":"",
        "first_name" : "",
        "last_name" : "",
        "address":"",
        "country_id":"",
        "state":"",
        "email":"",
        "phone":"",
        "category":"",
        "industry": "",
        "lcda" : "",
        "area" : "",
        "description" : ""
    })
    const [inputValue,setInputValue] = React.useState({
        state : "",
        industry : "",
        lcda : "",
        area : ""
    })

    const openFilePicker = () => {
        upload.current.click()
    }

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.createClient)

    const {
        mutateAsync : uploadImage,
        isLoading : uploading
    } = useMutation(apiFunctions.uploadImage)

    const {
        data : clients,
        isLoading : loading,
        isFetching
    } = useFetchClients(page,searchTerm)

    const {
        data : topIndustries,
        isLoading : loadingTopIndustries
    } = useFetchTopIndustries()

    const {
        data : countries
    } = useFetchCountries(fPage)

    const {
        data : states,
        isLoading : loadingStates
    } = useFetchStatesByCountry(fPage,data?.country_id)

    const {
        data : lcdas,
        isLoading : loadingLCDAs
    } = useFetchLCDAsByState(fPage,data?.state?.id)

    const {
        data : areas,
        isLoading : loadingAreas
    } = useFetchAreasByLCDA(fPage,data?.lcda?.id)

    const {
        data : industries,
        isLoading : loadingIndustries
    } = useFetchIndustries(fPage)

    const handleSubmit = async () => {
        try{
            const required = ["first_name",
                "name",
                "last_name",
                "address",
                "country_id",
                "state",
                "email",
                "phone",
                "category",
                "industry"
            ]
            let msg = ""
            for(const req of required){
                if(data[req].toString().trim() === ""){
                    msg = `Please provide your ${req.replace("_"," ")}`
                    break;
                }
            }
            if(msg.trim() !== "") return ToastError(msg)
            let image_url = null
            if(profile_img){
                let file = dataURLtoFile(profile_img) 
                let fd = new FormData()
                fd.append("file",file)
                let res = await uploadImage(fd)
                image_url = res?.data?.path;
            }
            await mutateAsync({...data,
                state_id : data?.state?.id,
                industry_id : data?.industry?.id,
                area_id : data?.area?.id,
                lcda_id : data?.lcda?.id,
                image_url
            })
            ToastSuccess("New record has been added")
            setData({
                "name":"",
                "first_name" : "",
                "last_name" : "",
                "address":"",
                "country_id":"",
                "state":"",
                "email":"",
                "phone":"",
                "category":"",
                "lcda" : "",
                "area" : "",
                "description" : "",
                "industry": ""
            })
            setInputValue({
                state : "",
                industry : "",
                area : "",
                lcda : ""
            })
            return queryClient.invalidateQueries("clients")
        }catch(err){
            ToastError(err.msg)
        }
    }


    const handleFilePicker = (e) => {
        setImage(Preview(e.target.files[0]))
        setOpen(true)
    }

    useEffect(()=>{
        if(!clients) return
        setPage(clients?.data?.current_page)
        setPageCount(clients?.data?.last_page)
    },[clients])

    const onPageChange = (value) => {
        setPage(value)
    }

    const updateProfileImage = (file) => {
        setProfileImg(file)
        setOpen(false)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        {
            field : "business_name",
            header : "Business name",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.name ? Capitalize(row.name) : null}</H1>
        },
        {
            field : "email",
            header : "Email",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.email ? Capitalize(row.email) : null}</H1>
        },
        {
            field : "phone",
            header : "Phone",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.phone ? Capitalize(row.phone) : null}</H1>
        },
        {
            field : "",
            header : "",
            renderCell : (row) => <CustomButton background={row?.status === "ACTIVE" ? AppColors.greenBackground : AppColors.lightRed} borderradius={"0.5rem"} width={"6rem"}
                onClick={()=>navigate(`/user/client-details/${row.id}/${row.name}`)}
            >
            <P color={AppColors.green}>View</P>
        </CustomButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <Container>
            <H1 fontSize={"1.234rem"}>List of Clients that have registered</H1>
            {
                loadingTopIndustries ? <Container 
                    marginTop={"2rem"}
                    marginBottom={"2rem"}
                    display={"grid"}
                    gridTemplateColumns={"auto auto auto auto auto"}
                    gap={2}
                >
                    {
                        [...Array(5).keys()].map((item,i)=><Skeleton variant='rectangle' 
                            width={"13rem"}
                            height={"7rem"}
                            key={i}
                        />)
                    }
                </Container> : 
                    topIndustries && Array.isArray(topIndustries) ?  <Container 
                    marginTop={"2rem"}
                    marginBottom={"2rem"}
                    display={"grid"}
                    gridTemplateColumns={"auto auto auto auto auto"}
                    gap={2}
                >
                    {
                        topIndustries.map((item,index)=><Container 
                            key={index} padding={"1rem 2rem"}
                            borderRadius={"0.5rem"}
                            border={`1px solid ${AppColors.grayBorder}`}
                            backgroundColor={AppColors.white}
                            width={"13rem"}
                        >
                            <P fontSize={"0.661rem"}>{item?.name ? Capitalize(item?.name) : null}</P>
                            <H1 fontSize={"1.256rem"} fontWeight={"bold"}>{item?.industry_count ? numeral(item?.industry_count).format("0,0") : null}</H1>
                            <Container display={"flex"} alignItems={"center"} marginTop={"1rem"}>
                                <ArrowUpward sx={styles.arrowUP}/>
                                <P fontSize={"0.661rem"}>1.10% Since yesterday</P>
                            </Container>
                        </Container>)
                    }
                    
                </Container> : null
            }
            <Container display={"flex"}
                justifyContent={"space-between"}
                marginBottom={"2rem"}
            >
                <PaperBox width={"50%"} padding={"2rem"}>
                    <Container>
                        <Container flexDirection={"row"} display={"flex"} marginBottom={"2rem"}>
                            <Container flexDirection={"row"} display={"flex"} alignItems={"center"}>
                                <Circle sx={styles.verifiedLabel} /> <H1>Verified</H1>
                            </Container>
                            <Container flexDirection={"row"} display={"flex"} alignItems={"center"}>
                                <Circle sx={styles.unverifiedLabel} /> <H1>Unverified</H1>
                            </Container>
                        </Container>
                        <Container display={"flex"} alignItems={"center"} marginBottom={"1rem"}>
                            <H1 variant='h6' fontWeight={"bold"} fontSize={"1.011rem"}
                                marginRight={"1rem"}
                            >Client List</H1>
                            <Input type="text" placeholder='Search'
                                onChange={(e)=>setSearch(e.target.value)}
                                value={search}
                                startAdornment={<Search sx={styles.searchIcon} />}
                            />
                        </Container>
                        <DataGridDemo columns={columns}
                            rows={clients?.data?.data && Array.isArray(clients?.data?.data) ? clients?.data?.data : []}
                            hideFooter={false}
                            loading={loading || isFetching}
                            CustomPagination={RenderPagination}
                            //Toolbar={()=>}
                        />
                    </Container>
                </PaperBox>
                <PaperBox width="35%" padding={"2rem"}>
                    <Container>
                        <form>
                            <H1 fontSize={"1.018rem"} marginBottom={"2rem"} fontWeight={"bold"}>Add New Client</H1>
                            <Container display={"flex"} justifyContent={"center"}>
                                <IconButton onClick={openFilePicker}>
                                    <Image 
                                        backgroundimage={profile_img || Images.upload}
                                        width={"6rem"}
                                        height={"6rem"}
                                        radius={"50%"}
                                    />
                                </IconButton>
                                <input type='file' accept="image/*" ref={upload} style={{display: 'none'}} 
                                    onChange={handleFilePicker}
                                />
                            </Container>
                            <Container container spacing={2} marginTop={"1rem"}>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>First name</Label>
                                        <Input type="text" placeholder='First name'
                                            value={data?.first_name}
                                            onChange={(e)=>setData({...data,first_name : e.target.value})}
                                        />
                                    </FormGroup>
                                </Container>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>Last name</Label>
                                        <Input type="text" placeholder='Last name'
                                            value={data?.last_name}
                                            onChange={(e)=>setData({...data,last_name : e.target.value})}
                                        />
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container container marginTop={"1rem"}>
                                <Container item md={12}>
                                    <FormGroup>
                                        <Label>Business name</Label>
                                        <Input type="text" placeholder='Business name'
                                            value={data?.name}
                                            onChange={(e)=>setData({...data,name : e.target.value})}
                                        />
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container container spacing={2} marginTop={"1rem"}>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>Country</Label>
                                        <CustomSelect value={data?.country_id} onChange={(e)=>setData({...data,country_id : e.target.value,state : {}})}
                                        >
                                            {
                                                countries?.data?.data && Array.isArray(countries?.data?.data) ? countries.data.data.map((item,i)=>{
                                                    return <MenuItem key={i} value={item?.id}>{item?.name ? Capitalize(item.name) : ""}</MenuItem>
                                                }) : <MenuItem value="">Select Country</MenuItem>
                                            }
                                        </CustomSelect>
                                    </FormGroup>
                                </Container>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>State</Label>
                                        <SearchableSelect 
                                            value={data?.state}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.title === value.title
                                            }}
                                            onChange={(e,value)=>{
                                                setData({...data,state : value})
                                            }}
                                            inputValue={inputValue?.state  ? inputValue?.state : ""}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue({...inputValue,state : newInputValue });
                                            }}
                                            getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                            options={states?.data?.data || []}
                                            loading={loadingStates}
                                        />
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container container spacing={2} marginTop={"1rem"}>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>LCDA</Label>
                                        <SearchableSelect 
                                            value={data?.lcda}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.title === value.title
                                            }}
                                            onChange={(e,value)=>{
                                                setData({...data,lcda : value})
                                            }}
                                            inputValue={inputValue?.lcda  ? inputValue?.lcda : ""}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue({...inputValue,lcda : newInputValue });
                                            }}
                                            getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                            options={lcdas?.data?.data || []}
                                            loading={loadingLCDAs}
                                        
                                        />
                                    </FormGroup>
                                </Container>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>Area</Label>
                                        <SearchableSelect 
                                            value={data?.area}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.title === value.title
                                            }}
                                            onChange={(e,value)=>{
                                                setData({...data,area : value})
                                            }}
                                            inputValue={inputValue?.area  ? inputValue?.area : ""}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue({...inputValue,area : newInputValue })
                                            }}
                                            getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                            options={areas?.data?.data || []}
                                            loading={loadingAreas}
                                        />
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container container spacing={2} marginTop={"1rem"}>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>Business address</Label>
                                        <Input type="text" placeholder='Business address'
                                            value={data?.address}
                                            onChange={(e)=>setData({...data,address : e.target.value})}
                                        />
                                    </FormGroup>
                                </Container>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>User category</Label>
                                        <CustomSelect value={data?.category} onChange={e=>setData({...data,category : e.target.value})}>
                                            <MenuItem value={"INDIVIDUAL"}>Individual</MenuItem>
                                            <MenuItem value={"BUSINESS"}>Business</MenuItem>
                                        </CustomSelect>
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container container spacing={2} marginTop={"1rem"}>
                                <Container item md={12}>
                                    <FormGroup>
                                        <Label>Email address</Label>
                                        <Input type="email" placeholder='Email address'
                                            value={data?.email}
                                            onChange={(e)=>setData({...data,email : e.target.value})}
                                        />
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container container spacing={2} marginTop={"1rem"}>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>Phone number</Label>
                                        <Input type="number" placeholder='Phone number'
                                            value={data?.phone}
                                            onChange={(e)=>setData({...data,phone : e.target.value})}
                                        />
                                    </FormGroup>
                                </Container>
                                <Container item md={6}>
                                    <FormGroup>
                                        <Label>Industry type</Label>
                                        <SearchableSelect 
                                            value={data?.industry}
                                            isOptionEqualToValue={(option, value) => {
                                                return option?.title === value?.title
                                            }}
                                            onChange={(e,value)=>{
                                                setData({...data,industry : value})
                                            }}
                                            inputValue={inputValue?.industry  ? inputValue?.industry : ""}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue({...inputValue,industry : newInputValue });
                                            }}
                                            getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                            options={industries?.data?.data || []}
                                            loading={loadingIndustries}
                                        />
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container container spacing={2} marginTop={"1rem"}>
                                <Container item md={12}>
                                    <FormGroup>
                                        <Label>Business description</Label>
                                        <Input multiline={true} placeholder='description'
                                            value={data?.description}
                                            onChange={(e)=>setData({...data,description : e.target.value})}
                                            rows={5}
                                        />
                                    </FormGroup>
                                </Container>
                            </Container>
                            <Container display={"flex"} justifyContent={"center"}>
                                <CustomButton variant='contained' mode="danger" width="10rem" margintop={"2rem"}
                                    loading={isLoading || uploading}
                                    disabled={isLoading || uploading}
                                    onClick={handleSubmit}
                                >
                                    <H1 fontWeight={"bold"} variant="h6">Add Client</H1>
                                </CustomButton>
                            </Container>
                        </form>
                    </Container>
                </PaperBox>
            </Container>
            <ReactCropper open={open} setOpen={()=>setOpen(false)} image={image} updateProfileImage={updateProfileImage} />
        </Container>
    )
}

export default Clients;