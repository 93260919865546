import { FormGroup, IconButton, MenuItem } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { Container, CustomButton, H1, P, PaperBox,Image, Label, Input, WarningModal, CustomSelect } from '../../../components'
import { AppColors } from '../../../components/AppColors';
import Images from '../../../components/images';
import DataGridDemo, { CustomPagination } from '../../../components/Tables';
import { apiFunctions, useFetchIndustries } from '../../../utils/api';
import { ToastError, ToastSuccess } from '../../../utils/functions';


const CreateIndustry = () => {
    const [show,setShow] = React.useState(false)
    const queryClient = useQueryClient()
    const [selected,setSelected] = React.useState({})
    const [page,setPage] = React.useState(1);
    const [pageCount,setPageCount] = React.useState(1)

    const {
        mutateAsync : deleteIndustry,
        isLoading : isDeleting
    } = useMutation(apiFunctions.deleteIndustry)
    const {
        mutateAsync : updateIndustry,
        isLoading : isUpdating
    } = useMutation(apiFunctions.updateIndustry)

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.addIndustry)
    const [industry,setIndustry] = React.useState({
        name : "",
        category : ""
    })
    const {
        data : industries,
        isLoading : loading
    } = useFetchIndustries(page)

    useEffect(()=>{
        if(!industries) return
        setPage(industries?.data?.current_page)
        setPageCount(industries?.data?.last_page)
    },[industries])

    const handleSubmit = async () => {
        try{
            if(
                industry.name.toString().trim() === "" ||
                industry.category.toString().trim() === ""
            ){
                return ToastError("Please provide both category and industry name")
            }
            if(!industry.id){
                await mutateAsync(industry)
                ToastSuccess("New record has been added")
                setIndustry({
                    name : "",
                    category : ""
                })
                return queryClient.invalidateQueries("industries")
            }
            let fd = {
                name : industry.name,
                category : industry.category,
                id : industry.id
            }
            await updateIndustry(fd)
            ToastSuccess("Record has been updated")
            setIndustry({
                name : "",
                category : ""
            })
            queryClient.invalidateQueries("industries")
        }catch(err){
            ToastError(err.msg)
        }
    }

    const handleDeleteWarning = (item) => {
        setSelected(item)
        setShow(true)
    }

    const handleDelete = async () => {
        try{
            await deleteIndustry(selected.id)
            ToastSuccess(`${selected.name} has been deleted`)
            queryClient.invalidateQueries("industries")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
    }

    const handleEdit = (item) => {
        setIndustry(item)
        window.scrollTo(0,0)
    }

    const onPageChange = (value) => {
        setPage(value)
    }

    const columns = [,
        {
            field : "category",
            header : "Business Category",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.category}</H1>
        },
        {
            field : "name",
            header : "Industry",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.name}</H1>
        },
        {
            field : "created_at",
            header : "Date",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{moment(row?.created_at).format("DD/MM/YYYY")}</H1>
        },
        {
            field : "edit",
            header : "Edit",
            renderCell : (row) => <IconButton onClick={()=>handleEdit(row)}>
                    <Image backgroundimage={Images.edit} width={"1.2rem"} height={"1.2rem"} />
                </IconButton>
        },
        {
            field : "delete",
            header : "Delete",
            renderCell : (row) => <IconButton onClick={()=>handleDeleteWarning(row)}>
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton>
        },
    ].map((item,i)=>{
        return{
            id : i.toString(),
            field: item.field,
            sortable: false,
            flex :1,
            renderCell : (param) => item.renderCell(param.row),
            renderHeader: () => (
                <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
              ),
        }
    });

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    return(
        <PaperBox padding={"2rem"}>
            <Container marginBottom={"2rem"}>
                <form>
                    <P fontSize={"1.018rem"} marginBottom={"2rem"}>Create New Business & Industry Type</P>
                    <Container
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        width={"60%"}
                        container
                    >
                        <Container item md={4}>
                            <FormGroup>
                                <Label>Category</Label>
                                <CustomSelect
                                    value={industry.category}
                                    onChange={e=>setIndustry({...industry,category : e.target.value})}
                                >
                                    <MenuItem value={"Individual"}>Individual</MenuItem>
                                    <MenuItem value="Business">Business</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <FormGroup>
                            <Label>Industry</Label>
                            <Input placeholder="Enter industry" type="text" value={industry.name} onChange={e=>setIndustry({...industry,name : e.target.value})} />
                        </FormGroup>
                        <CustomButton variant='outlined'
                            width={"6rem"}
                            padding={"0.4rem"}
                            border={`1.5px solid ${AppColors.red}`}
                            borderradius={"0.452rem"}
                            onClick={handleSubmit}
                            loading={isLoading || isUpdating}
                        >
                            <H1 color={AppColors.red} fontWeight={"bold"}>Save</H1>
                        </CustomButton>
                    </Container>
                </form>
            </Container>
            <Container>
                <DataGridDemo columns={columns} 
                    rows={industries?.data?.data && Array.isArray(industries?.data?.data) ? industries?.data?.data : []}
                    hideFooter={false}
                    loading={loading}
                    CustomPagination={RenderPagination}
                />
            </Container>
            <WarningModal 
                open={show} handleDelete={handleDelete} closeWarning={closeWarning}
                loading={isDeleting}
                selection={selected?.name}
            />
        </PaperBox>
    )
}

export default CreateIndustry;