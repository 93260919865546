import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Container, CustomButton, CustomSelect, H1,Input,Label,P,Image, SearchableSelect } from '.';
import { AppColors } from './AppColors';
import {useSelector,useDispatch} from 'react-redux';
import { CircularProgress, FormGroup, IconButton, InputAdornment, MenuItem } from '@mui/material';
import Images from './images';
import { ProductDetails } from './ProductDetails';
import { RiderDetails } from './RiderDetails';
import { ADD_MEMBER, CLIENTS_ORDER, EDIT_MEMBER, RIDER_DETAILS } from '../actions/actionTypes';
import ClientOrder from './ClientOrder';
import { drawerHandler } from '../redux/drawerSlice';
import { useMutation, useQueryClient } from 'react-query';
import { apiFunctions, useFetchCountries, useFetchLCDAsByState, useFetchStatesByCountry, useFetchUser } from '../utils/api';
import ReactCropper from './Cropper';
import { Capitalize, dataURLtoFile, Preview, ToastError, ToastSuccess } from '../utils/functions';
import { BANK_LIST } from './Constants';




const AddMember = (props) => {
    const upload = React.useRef(null)
    const queryClient = useQueryClient()
    const docUpload = React.useRef(null)
    const [open,setOpen] = React.useState(false)
    const [image,setImage] = React.useState(null)
    const [document,setDocument] = React.useState(null)
    const [type,setType] = React.useState(null)
    const [profile_img,setProfileImg] = React.useState(null)
    const [page] = React.useState(1)
    const [docPreview,setDocPreview] = React.useState(null)


    const {
        data : userData,
        isFetching
    } = useFetchUser(props?.user?.id)
    const [data,setData] = React.useState({
        "first_name" : "",
        "last_name" : "",
        "address":"",
        "country_id":"",
        "state":"",
        "lcda" : "",
        "email":"",
        "alt_phone" : "",
        "phone":"",
        "gender" : "",
        "type" : "",
        "bank_code" : "",
        "account_number" : "",
        "image_url" : "",
        "document_url" : "",
        "age" : ""
    })
    const [inputValue,setInputValue] = React.useState({
        state : "",
        lcda : ""
    })

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.createUser)

    const {
        mutateAsync : updateUser,
        isLoading : isUpdating
    } = useMutation(apiFunctions.updateUser)

    const {
        mutateAsync : updateBank,
        isLoading : isUpdatingBank
    } = useMutation(apiFunctions.updateBank)

    const {
        mutateAsync : uploadImage,
        isLoading : uploading
    } = useMutation(apiFunctions.uploadImage)
    const {
        data : countries
    } = useFetchCountries(page)

    const {
        data : states,
        isLoading : loadingStates
    } = useFetchStatesByCountry(page,data?.country_id)

    const {
        data : lcdas,
        isLoading : loadingLCDAs
    } = useFetchLCDAsByState(page,data?.state?.id)

    const {
        mutateAsync : createBank,
        isLoading : creatingBank
    } = useMutation(apiFunctions.createBank)

    // const {
    //     mutateAsync : deleteImage,
    //     isLoading : isDeleting
    // } = useMutation(apiFunctions.deleteImage)

    const openFilePicker = (type) => {
        setType(type)
        if(type === "profileImage") return upload.current.click()
        docUpload.current.click()
    }

    const updateProfileImage = (file) => {
        setProfileImg(file)
        setOpen(false)
    }
    const handleFilePicker = (e) => {
        if(type === "document"){
            setDocPreview(Preview(e.target.files[0]))
            return setDocument(e.target.files[0])
        }
        setImage(Preview(e.target.files[0]))
        setOpen(true)
    }

    const removeImageHandler = () => {
        setProfileImg(null)

    }

    React.useEffect(()=>{
        if(!userData?.data) return 
        setData({
            ...data,...props.user,...userData?.data,
            country_id : props?.user?.country?.id,
            bank_code : userData?.data?.bank?.bank_code,
            account_number : userData?.data?.bank?.account_number
        })
    },[props,userData])

    const handleSubmit = async () => {
        try{
            const required = ["first_name",
                "last_name",
                "address",
                "country_id",
                "state",
                "email",
                "phone",
                "lcda",
                "type",
                "bank_code"
            ]
            let msg = ""
            for(const req of required){
                if(!data[req] || data[req].toString().trim() === ""){
                    msg = `Please provide ${req.replace("_"," ")}`
                    break;
                }
            }
            if(msg.trim() !== "") return ToastError(msg)
            let image_url = userData?.data?.image_url
            let document_url = userData?.data?.document_url
            if(profile_img){
                let file = dataURLtoFile(profile_img) 
                let fd = new FormData()
                fd.append("file",file)
                let res = await uploadImage(fd)
                image_url = res?.data?.path;
            }
            if(document){
                let fd = new FormData()
                fd.append("file",document)
                let res = await uploadImage(fd)
                document_url = res?.data?.path;
            }
            let bank_id = userData?.data?.bank?.id
            if(data.bank_code !== userData?.data?.bank?.bank_code && data.account_number !== userData?.data?.account_number && !userData?.data?.bank?.id){
                let res = await createBank({bank_code : data.bank_code, account_number : data.account_number})
                bank_id = res?.data?.id
            }
            if(data.bank_code !== userData?.data?.bank?.bank_code && data.account_number !== userData?.data?.account_number && userData?.data?.bank?.id){
                let res = await updateBank({id: userData?.data?.bank?.id, bank_code : data.bank_code, account_number : data.account_number})
                bank_id = res?.data?.id
            }
            if(data.id){
                let load = {...data,
                    state_id : data?.state?.id,
                    industry_id : data?.industry?.id,
                    area_id : data?.area?.id,
                    lcda_id : data?.lcda?.id,
                    bank_id,
                    image_url,
                    document_url
                }
                delete load['email']
                delete load['state']
                delete load['lcda']
                delete load["device_id"]

                await updateUser(load)
                ToastSuccess("Record has been updated")
                queryClient.invalidateQueries("user")
                return queryClient.invalidateQueries("users")
            }
            await mutateAsync({...data,
                state_id : data?.state?.id,
                industry_id : data?.industry?.id,
                area_id : data?.area?.id,
                lcda_id : data?.lcda?.id,
                image_url,
                document_url
            })
            ToastSuccess("New record has been added")
            setData({
                "first_name" : "",
                "last_name" : "",
                "address":"",
                "country_id":"",
                "state":"",
                "lcda" : "",
                "email":"",
                "alt_phone" : "",
                "phone":"",
                "gender" : "",
                "type" : "",
                "bank_code" : "",
                "account_number" : "",
                "document_url" : "" 
            })
            setInputValue({
                state : "",
                area : "",
                lcda : ""
            })
            queryClient.invalidateQueries("user")
            return queryClient.invalidateQueries("users")
        }catch(err){
            ToastError(err.msg)
        }
    }

    return(
        <Box
            sx={{ width: 600}}
            role="presentation"
        >
            <Container padding={"5rem 2rem"}>
               {
                   props?.user ?  <Container>
                        <Container display={"flex"}
                            alignItems={"center"}
                        >
                            <Container width={"30%"}> 
                                    {
                                        profile_img ? <Image 
                                        backgroundimage={profile_img}
                                        width={"7rem"}
                                        height={"7rem"}
                                        marginRight={"1rem"}
                                        borderRadius={"50%"}
                                    /> : <Image 
                                            backgroundimage={data?.image_url ? data?.image_url : Images.upload}
                                            width={"7rem"}
                                            height={"7rem"}
                                            marginRight={"1rem"}
                                            borderRadius={"50%"}
                                            backgroundColor={AppColors.red}
                                        />
                                    }
                            </Container>
                           <Container 
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <CustomButton background={AppColors.lightRed} width="10rem"
                                    borderradius={"0.5rem"}
                                    onClick={()=>openFilePicker("profileImage")}
                                >
                                    <P color={AppColors.black}>Upload new image</P>
                                </CustomButton>
                                <input type='file' accept="image/*" ref={upload} style={{display: 'none'}} 
                                    onChange={handleFilePicker}
                                />
                                <CustomButton background={AppColors.greenBackground} width="7rem"
                                    margin={".5rem 0"}
                                    borderradius={"0.5rem"}
                                    onClick={removeImageHandler}
                                >
                                    <P color={AppColors.black}>Remove image</P>
                                </CustomButton>
                            </Container>
                        </Container>
                        <H1 
                            textTransform={"capitalize"}
                            marginTop={"2rem"}
                            fontWeight={"bold"}
                            fontSize={"1.125rem"}
                        >{props?.user?.first_name} {props?.user?.last_name}</H1>
                        {
                            props?.user?.id ? <P fontSize={"0.89rem"}>#{props?.user?.id.substring(0,8)}</P> : null
                        }
                        {
                            isFetching ? <CircularProgress size={16} 
                                thickness={5}
                                sx={{color : AppColors.red}}
                            /> : null
                        }
                    </Container> :  <Container display={"flex"}
                    alignItems={"flex-end"}
                >
                    <Image 
                        backgroundimage={profile_img || Images.upload}
                        width={"7rem"}
                        height={"7rem"}
                        marginRight={"1rem"}
                        radius={"50%"}
                    />
                    <input type='file' accept="image/*" ref={upload} style={{display: 'none'}} 
                                    onChange={handleFilePicker}
                                />
                    <CustomButton background={AppColors.lightRed} width="10rem" onClick={()=>openFilePicker("profileImage")}>
                        <P color={AppColors.black}>Upload Image</P>
                    </CustomButton>
                </Container>
               }
                <Container container 
                    marginTop={"2rem"}
                >
                    <Container 
                        display={"flex"} justifyContent={"space-between"}
                        marginTop={"1rem"}
                    >
                        <Container item md={5.8}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>First name *</Label>
                                <Input placeholder="Enter first name" 
                                    value={data?.first_name || ""} 
                                    onChange={(e)=>setData({...data,first_name : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={5.8} >
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Last name</Label>
                                <Input placeholder="Enter last name" type='text' 
                                    value={data?.last_name} 
                                    onChange={(e)=>setData({...data,last_name : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container 
                        display={"flex"} justifyContent={"space-between"}
                        marginTop={"1rem"}
                    >
                        <Container item md={12}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Home address</Label>
                                <Input placeholder="Enter home address" 
                                    value={data?.address || ""} 
                                    onChange={(e)=>setData({...data,address : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container 
                        marginTop={"1rem"}
                        spacing={2}
                        container
                    >
                        <Container item md={4}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Country</Label>
                                <CustomSelect value={data?.country_id} onChange={(e)=>setData({...data,country_id : e.target.value,state : {}})}>
                                    {
                                        countries?.data?.data && Array.isArray(countries?.data?.data) ? countries.data.data.map((item,i)=>{
                                            return <MenuItem key={i} value={item?.id}>{item?.name ? Capitalize(item.name) : ""}</MenuItem>
                                        }) : <MenuItem value="">Select Country</MenuItem>
                                    }
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={4}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>State</Label>
                                <SearchableSelect 
                                    value={data?.state}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.title === value.title
                                    }}
                                    onChange={(e,value)=>{
                                        setData({...data,state : value})
                                    }}
                                    inputValue={inputValue?.state  ? inputValue?.state : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,state : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={states?.data?.data || []}
                                    loading={loadingStates}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={4}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>LCDA</Label>
                                <SearchableSelect 
                                    value={data?.lcda}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.title === value.title
                                    }}
                                    onChange={(e,value)=>{
                                        setData({...data,lcda : value})
                                    }}
                                    inputValue={inputValue?.lcda  ? inputValue?.lcda : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,lcda : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={lcdas?.data?.data || []}
                                    loading={loadingLCDAs}
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container 
                        marginTop={"1rem"}
                        container
                        spacing={2}
                    >
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Phone number</Label>
                                <Input placeholder="Enter phone number" 
                                    value={data?.phone || ""} 
                                    onChange={(e)=>setData({...data,phone : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Alternative phone number</Label>
                                <Input placeholder="Enter alternative number" 
                                    value={data?.alt_phone || ""} 
                                    onChange={(e)=>setData({...data,alt_phone : e.target.value})}
                                    type='number'
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    
                    <Container 
                        marginTop={"1rem"}
                        container
                        spacing={2}
                    >
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Email address</Label>
                                <Input placeholder="Enter email address"
                                    value={data?.email || ""} 
                                    disabled={data.id ? true : false}
                                    onChange={(e)=>setData({...data,email : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Gender</Label>
                                <CustomSelect 
                                    value={data?.gender || ""} 
                                    onChange={(e)=>setData({...data,gender : e.target.value})}>
                                    <MenuItem value="">Select gender</MenuItem>
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container 
                        marginTop={"1rem"}
                        container
                        spacing={2}
                    >
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Age</Label>
                                <Input placeholder="Enter age" type="number"
                                    value={data?.age || ""} 
                                    onChange={(e)=>setData({...data,age : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Team type</Label>
                                <CustomSelect
                                    value={data?.type} 
                                    onChange={(e)=>setData({...data,type : e.target.value})}
                                >
                                    <MenuItem value="">Select type</MenuItem>
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="rider">Rider</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                    </Container>
    
                    <Container 
                        marginTop={"1rem"}
                        container
                        spacing={2}
                    >
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Bank name</Label>
                                <CustomSelect value={data?.bank_code || ""} onChange={(e)=>setData({...data,bank_code : e.target.value})}>
                                    <MenuItem value="">Select bank</MenuItem>
                                    {
                                        BANK_LIST.map((item,i)=><MenuItem key={i} value={item.code}>{item.name}</MenuItem>)
                                    }
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={6}>
                            <FormGroup>
                                <Label fontSize={"0.938rem"}>Account number</Label>
                                <Input placeholder="Account number" type="text"
                                    value={data?.account_number} 
                                    onChange={(e)=>setData({...data,account_number : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container marginTop={"1rem"}>
                        <H1 fontWeight={"bold"} 
                            fontSize={"1.063rem"}
                            marginBottom={"0.5rem"}
                        >Means of Identification</H1>
                        <Container display={"flex"}
                            alignItems={"center"}
                        >
                            <CustomButton 
                                display="flex"
                                flexdirection="column"
                                height="auto"
                                width={"30rem"}
                                border={`1.5px dotted ${AppColors.grayBorder}`}
                                borderradius={"0.5rem"}
                                padding={"1rem"}
                                onClick={()=>openFilePicker("document")}
                            >
                                <P color={AppColors.grey} fontSize={"0.943rem"}
                                    marginBottom={"1rem"}
                                >Upload valid Id</P>
                                <Image 
                                    backgroundimage={data?.document_url && !docPreview ? data?.document_url : docPreview  || Images.File}
                                    height={"2rem"}
                                    width={"2rem"}
                                />
                            </CustomButton>
                            <input type='file' accept="image/*" ref={docUpload} style={{display: 'none'}} 
                                    onChange={handleFilePicker}
                                />
                            <P marginLeft={"1rem"} fontSize={"0.875rem"} color={AppColors.grey}>International passport, Voters card, Utility bill, drivers licence</P>
                        </Container>
                    </Container>
                    
                    <Container item 
                        marginTop={"4rem"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <CustomButton variant="contained"
                            mode="danger" variant="contained" fullWidth marginbottom={"2rem"}
                            onClick={handleSubmit}
                            loading={isLoading || uploading || isUpdating || creatingBank || isUpdatingBank}
                        >
                            <H1 variant="h6" fontWeight={"bold"}>Save</H1>
                        </CustomButton>
                    </Container>
                </Container>
            </Container>
            <ReactCropper open={open} setOpen={()=>setOpen(false)} image={image} updateProfileImage={updateProfileImage} />
        </Box>
    )
}

export default function SwipeableTemporaryDrawer() {
    const show = useSelector(state=>state.drawerReducer)
    const user = useSelector(state=>state.user)
    const dispatch = useDispatch()
    return (
        <div>
            <React.Fragment>
                <SwipeableDrawer
                    anchor={"right"}
                    open={show?.open}
                    onClose={()=>dispatch(drawerHandler(show.name))}
                    onOpen={()=>dispatch(drawerHandler(show.name))}
                >
                    {
                        show.name === ADD_MEMBER ? <AddMember /> : null
                    }
                    {
                        show.name === EDIT_MEMBER ? <AddMember user={user}/> : null
                    }
                    {
                        show.name === "PRODUCT_DETAILS" ? <ProductDetails user={user}/> : null
                    }
                    {
                        show.name === RIDER_DETAILS ? <RiderDetails /> : null
                    }
                    {
                        show.name === CLIENTS_ORDER ? <ClientOrder /> : null
                    }
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
