import React from 'react';
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
import { Box, Checkbox,CircularProgress, Dialog,
    DialogActions,DialogContent,DialogContentText,DialogTitle,Slide,
     FormGroup, FormLabel, Grid, IconButton, MenuItem, OutlinedInput, Paper, Select, StepLabel, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { AppColors } from "./AppColors";
import CardBgGPNG from '../images/card-bg.png'
import WhiteLogoPNG from '../images/white-logo.png' 
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';
import ErrorIcon from '@mui/icons-material/Error';
import Autocomplete from '@mui/material/Autocomplete';
import { Capitalize } from '../utils/functions';

const Transition = React.forwardRef(function Transition(props,ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const AuthWrapper = styled(Box)`
    height: 110vh;
    width : 100%
`
export const AuthIllustration = styled(Grid)`
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 108vh;
    background-color: #ff0100;
    background-image : url(${props=>props.backgroundimg})
`

export const Container = styled(Grid)`
    display : ${props => props.display ||  "block"}
    flex-direction : ${props=>props.flexDirection};
    justify-content : ${props=>props.justifyContent};
    align-items : ${props=>props.justifyContent};
    align-self : ${props=>props.alignSelf};
    width : ${props=>props.width || '100%'};
    margin : ${props => props.margin};
    background-color : ${props => props.background};
    cursor : ${props => props.cursor};
`
export const P = styled(Typography)`
    color : ${props=>props.color};
    font-family: 'Mulish', sans-serif !important;
    font-size : ${props => props.fontSize || "0.813rem"}!important;
    text-transform : ${props => props.texttransform || "none"};
`

export const H1 = styled(Typography)`
    color : ${props=>props.color};
    font-family: 'Mulish', sans-serif !important;
    font-weight : ${props => props.fontWeight || "bold"}!important;
    text-align : ${props => props.textAlign};
    variant : ${props => props.variant || "h6"}!important;
    font-size : ${props => props.fontSize || props.fontsize || "0.813rem"}!important;
    text-transform : ${props => props.texttransform || "none"};
    text-decoration : ${props=>props.textDecoration};
    ${
        (props) => props.singleline ? {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        } : null
    }
`

export const LinkedButton = styled(Link)`{
    ${
        (props) => {
            switch(props.mode){
                case "danger" : 
                 return css`
                    color : red;
                    text-align : ${props.textAlign};
                    text-decoration: none;
                    font-family: 'Mulish', sans-serif !important;
                 `
                default : 
                 return  css`
                    color : ${props.color};
                    text-align : ${props.textAlign};
                    text-decoration: ${props.textDecoration || "none"};
                    font-weight : ${props=>props.fontWeight};
                    width : ${props.width};
                    font-family: 'Mulish', sans-serif !important;
                 `
            }
 
             
        }
    }
}`

export const CustomStepLabel = styled(StepLabel)`
    color : ${props=>props.color || "#3FB48D"} !important;
    font-family: 'Mulish', sans-serif !important;
`

const Button = (props) => <LoadingButton {...props} loadingIndicator={<CircularProgress size={16} 
    thickness={5}
    sx={
        {color : props?.variant === "outlined" ? AppColors.red : AppColors.white}
    }
/>}  />


export const WarningModal = (props) => {
    return(
        <Dialog
            open={props?.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.closeWarning}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"xl"}
        >
            <DialogTitle textAlign={"center"}>{"Are you sure?"}</DialogTitle>
            {
                props.action_type === "Approval" ? <React.Fragment>
                    <DialogContent>
                        <Container flexDirection={"row"} display={"flex"} justifyContent={"center"}>
                            <ErrorIcon
                                sx={{
                                    fontSize : "6rem",
                                    color : AppColors.red
                                }}
                            />
                        </Container>
                        <H1 textAlign={"center"} marginTop={"1rem"}>'{props.selection}' will be get an email of this action.</H1>
                    </DialogContent>
                    <DialogActions sx={{
                        marginBottom : "1rem"
                    }}>
                        {
                            !props?.loading ? <CustomButton onClick={props.closeWarning}><H1 color={AppColors.black}>Cancel</H1></CustomButton> : null
                        }
                        <CustomButton onClick={props.handleDelete} mode="danger" variant="contained"
                            loading={props?.loading}
                        disabled={props?.loading}
                        >{props?.action ? Capitalize(props?.action) : null}</CustomButton>
                    </DialogActions>
                </React.Fragment>  : <React.Fragment>
                    <DialogContent>
                        <Container flexDirection={"row"} display={"flex"} justifyContent={"center"}>
                            <ErrorIcon
                                sx={{
                                    fontSize : "6rem",
                                    color : AppColors.red
                                }}
                            />
                        </Container>
                        <H1 textAlign={"center"} marginTop={"1rem"}>'{props.selection}' will be permanently deleted from the table list.</H1>
                    </DialogContent>
                    <DialogActions sx={{
                        marginBottom : "1rem"
                    }}>
                        {
                            !props?.loading ? <CustomButton onClick={props.closeWarning}><H1 color={AppColors.black}>Cancel</H1></CustomButton> : null
                        }
                        <CustomButton onClick={props.handleDelete} mode="danger" variant="contained"
                            loading={props?.loading}
                        disabled={props?.loading}
                        >Delete Now</CustomButton>
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    )
}

export const WarningModalWithInput = (props) => {
    const [text,setText] = React.useState("")
    const submitHandler = () => {
        props.submitHandler(text)
    }
    return(
        <Dialog
            open={props?.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.closeWarning}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle textAlign={"center"}>{"Are you sure?"}</DialogTitle>
            <React.Fragment>
                <DialogContent>
                    <Input 
                        placeholder={props?.placeholder}
                        type='textarea'
                        rows={1}
                        fullWidth
                        onChange={(e)=>setText(e.target.value)}
                        multiline={true}
                    /> 
                </DialogContent>
                <DialogActions sx={{
                    marginBottom : "1rem"
                }}>
                    {
                        !props?.loading ? <CustomButton onClick={props.closeWarning}><H1 color={AppColors.black}>Cancel</H1></CustomButton> : null
                    }
                    <CustomButton onClick={submitHandler} mode="danger" variant="contained"
                        loading={props?.loading}
                    disabled={props?.loading}
                    >Decline</CustomButton>
                </DialogActions>
            </React.Fragment> 
        </Dialog>
    )
}

export const CustomButton = styled(Button)`
    ${
        (props) => {
            switch(props.mode){
                case "primary": 
                    return css`

                    `
                case "danger": 
                    return css`
                        font-family: 'Mulish', sans-serif !important;
                        background-color : #ff0100 !important;
                        width : ${props.width};
                        margin-bottom : ${props.marginbottom}!important;
                        margin-top : ${props.margintop}!important;
                        height: 2.5rem;
                        @media(max-width : 768px){
                            width : 13rem;
                        }
                    `
                default : 
                    return css`
                        font-family: 'Mulish', sans-serif !important;
                        display : ${props.display}!important;
                        flex-direction : ${props.flexdirection};
                        justify-content : ${props.justifycontent}!important;
                        align-items : ${props.alignitems};
                        height: ${props.height};
                        margin : ${props.margin}!important;
                        padding : ${props.padding} !important;
                        background-color : ${props.background} !important;
                        border-radius : ${props.borderradius} !important;
                        border :  ${props.border} !important;
                        width : ${props.width};
                    `
            }
        }
    }
`

export const Input = styled(OutlinedInput)`
    border-radius : 0.475rem;
    font-family: 'Mulish', sans-serif !important;
    &:focus{
        border-color : #ff0100;
    }
    @media (max-width: 768px) {
       
    }
`

export const Label = styled(FormLabel)`
    font-family: 'Mulish', sans-serif !important;
    font-weight : bold !important;
    font-size : ${props=>props.fonsize || "1rem"};
    color : black !important;
    margin-bottom : 0.5rem;
    @media(max-width:768px){
        margin-top : 0.8rem;
    }
`
export const CustomSelect = styled(Select)`
    font-family: 'Mulish', sans-serif !important;
    height : ${props=>props.height || '3rem'};
    width : ${props=>props.width || '100%'};
    border-radius : ${props=>props.borderRadius || '0.5rem'};
    margin-left : ${props=>props.marginleft};
    & fieldset.MuiOutlinedInput-notchedOutline{
        border : ${props=>props.border} !important;
        border-left : ${props=>props.borderleft} !important;
    }
`

export const SearchableSelect = styled((props)=>{
        return(
            <Autocomplete
                options={props?.options && Array.isArray(props?.options) ? props?.options : []}
                loading={props?.loading}
                renderOption={(props, option) => <CustomButton fullWidth
                    justifycontent={"flex-start"}
                    background={AppColors.white}
                    {...props}
                    key={option.id}
                >
                    <P color={AppColors.black} fontSize={"1.1rem"}>{option?.name ? Capitalize(option.name) : null}</P>
                </CustomButton>}
                renderInput={(params) => <TextField {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                            {props?.loading ? <CircularProgress color="error" size={20} /> : null}
                            {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />}
                {...props}
            />
        )
    })`
    font-family: 'Mulish', sans-serif !important;
    height : ${props=>props.height || '3.2rem'};
    width : ${props=>props.width || '100%'};
    border-radius : ${props=>props.borderRadius || '0.5rem'};
    margin-left : ${props=>props.marginleft};
    & fieldset.MuiOutlinedInput-notchedOutline{
        border : ${props=>props.border} !important;
        border-left : ${props=>props.borderleft} !important;
    }
`

export const Option = styled(MenuItem)`
    font-family: 'Mulish', sans-serif !important;
`
export const CheckedBox = styled(Checkbox)`
    border-radius : 2rem;
` 

export const PaperBox = styled(Paper)`
    display : ${props=>props.display};
    padding : ${props=>props.padding};
    margin : ${props=>props.margin};
    flex-direction : ${props=>props.direction};
    align-items : ${props=>props.align};
    justify-content : ${props=>props.justify};
    width : ${props=>props.width};
    border-radius: ${props=>props.borderradius || '4px'} !important;
    border : ${props=>props.border};
    background-color: ${props=>props.background || '#fff'} !important;
    box-shadow: none !important;
    margin-bottom : ${props=>props.marginbottom}
`

const DatePickerComp = (props) => (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
            //label="Helper text example"
            //value={value}
            onChange={(newValue) => {
            //setValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} 
                sx={{
                    width : props.width || "11rem",
                    margin : props.margin,
                    '&:hover':{
                        border : 'none',
                        color : 'red !important'
                    },
                    color : AppColors.grey
                }}
            />}
        />
    </LocalizationProvider>
)

export const CustomDatePicker = styled(DatePickerComp)`
    width : ${props=>props.height}
`

export const Image = styled(Container)`
    width : ${props=>props.width};
    height : ${props=>props.height};
    background-image : url(${props=>props.backgroundimage});
    background-position : center;
    background-repeat : no-repeat;
    background-size : ${props=>props.backgroundsize || 'contain'};
    border-radius : ${props=>props.radius}
`

export const ResetPassword = (props) => {
    return(
        <Container margin="1rem auto"
            width="95%"
            border={`0.1rem solid ${AppColors.offWhite}`}
            borderRadius={"0.5rem"}
            padding={"1rem"}
        >
            <Container flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
            >
                <IconButton onClick={props.goBack}>
                    <ArrowBack />
                </IconButton>
                <H1 variant="h6" fontWeight={"bold"} fontSize={"1rem"}>Reset password</H1>
            </Container>
            <Container marginTop={"0.8rem"}>
                <Container container 
                    marginTop={"2rem"}
                >
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>Old password*</Label>
                            <Input placeholder="Old password" />
                        </FormGroup>
                    </Container>
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>New password*</Label>
                            <Input placeholder="New password" />
                        </FormGroup>
                    </Container>
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>Reason for change*</Label>
                            <Input placeholder="Reason" 
                                multiline
                                rows={"4"}
                            />
                        </FormGroup>
                    </Container>
                    <Container item md={8}
                        marginTop={"1.5rem"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <CustomButton variant="contained"
                            mode="danger" variant="contained" width="10rem" marginbottom={"2rem"}
                        >
                            <H1 variant="h6" fontWeight={"bold"}>Reset Password</H1>
                        </CustomButton>
                    </Container>
                </Container>
            </Container>    
        </Container>
    )
}

export const ResetEmail = (props) => {
    return(
        <Container margin="1rem auto"
            width="95%"
            border={`0.1rem solid ${AppColors.offWhite}`}
            borderRadius={"0.5rem"}
            padding={"1rem"}
        >
             <Container flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
            >
                <IconButton onClick={props.goBack}>
                    <ArrowBack />
                </IconButton>
                <H1 variant="h6" fontWeight={"bold"} fontSize={"1rem"}>Reset email address</H1>
            </Container>
            <Container marginTop={"0.8rem"}>
                <Container container 
                    marginTop={"2rem"}
                >
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>Old email*</Label>
                            <Input placeholder="Enter old email" />
                        </FormGroup>
                    </Container>
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>New email*</Label>
                            <Input placeholder="Enter new email" />
                        </FormGroup>
                    </Container>
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>Reason for change*</Label>
                            <Input placeholder="Reason" 
                                multiline
                                rows={"4"}
                            />
                        </FormGroup>
                    </Container>
                    <Container item md={8}
                        marginTop={"1.5rem"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <CustomButton variant="contained"
                            mode="danger" variant="contained" width="10rem" marginbottom={"2rem"}
                        >
                            <H1 variant="h6" fontWeight={"bold"}>Change Email</H1>
                        </CustomButton>
                    </Container>
                </Container>
            </Container>    
        </Container>
    )
}

export const ResetPhone = (props) => {
    return(
        <Container margin="1rem auto"
            width="95%"
            border={`0.1rem solid ${AppColors.offWhite}`}
            borderRadius={"0.5rem"}
            padding={"1rem"}
        >
             <Container flexDirection={"row"}
                display={"flex"}
                alignItems={"center"}
            >
                <IconButton onClick={props.goBack}>
                    <ArrowBack />
                </IconButton>
                <H1 variant="h6" fontWeight={"bold"} fontSize={"1rem"}>Reset phone number</H1>
            </Container>
            <Container marginTop={"0.8rem"}>
                <Container container 
                    marginTop={"2rem"}
                >
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>Old phone number*</Label>
                            <Input placeholder="Enter old phone number" />
                        </FormGroup>
                    </Container>
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>New phone number*</Label>
                            <Input placeholder="Enter new phone number" />
                        </FormGroup>
                    </Container>
                    <Container item md={8} marginTop={"1.5rem"}>
                        <FormGroup>
                            <Label fontSize={"0.938rem"}>Reason for change*</Label>
                            <Input placeholder="Reason" 
                                multiline
                                rows={"4"}
                            />
                        </FormGroup>
                    </Container>
                    <Container item md={8}
                        marginTop={"1.5rem"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <CustomButton variant="contained"
                            mode="danger" variant="contained" width="15rem" marginbottom={"2rem"}
                        >
                            <H1 variant="h6" fontWeight={"bold"}>Reset Phone Number</H1>
                        </CustomButton>
                    </Container>
                </Container>
            </Container>    
        </Container>
    )
}


export const WalletCard = (props) => (
    <Image backgroundimage={CardBgGPNG} backgroundsize="cover" borderRadius={"0.5rem"}
        paddingLeft={props.padding || "2rem"}
        paddingRight={props.padding || "2rem"}
    >
        <Container height={props.height || "13rem"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
        >
            <Image backgroundimage={WhiteLogoPNG} 
                height={"2rem"}
                width={"2rem"}
            />
            <H1 fontSize={props.serialSize || "1.264rem"} fontWeight={"bold"}
                    color={AppColors.white}
                >{props.serial}</H1>
            <Container>
                <H1  fontSize={props.expirySize || "0.527rem"} fontWeight={"bold"}
                    color={AppColors.white}
                >{props.expiry}</H1>
                <H1  fontSize={props.nameSize || "0.632rem"} fontWeight={"bold"}
                    color={AppColors.white}
                >
                    {props.name}
                </H1>
            </Container>
        </Container>
    </Image>
)
