import {createSlice} from '@reduxjs/toolkit'

const paginationSlice = createSlice({
    name : "pagination",
    initialState : {
        page : 1,
        pageCount : 1
    },
    reducers : {
        pageHandler : (state,action) => {
            return {...state,...action.payload}
        }
    }
})

export const {pageHandler} = paginationSlice.actions;
export default paginationSlice.reducer;