import axios from "axios";
import { useQuery } from "react-query";
const endPoint = "https://api.hellobikee.com/api/v1";

export const apiFunctions = {
  login: (fd) => postNoToken("/login", fd),
  logout: () => postAPIs("/logout"),
  forgot: (fd) => postNoToken("/forgot/password", fd),
  verifyToken: (fd) => postNoToken("/verify-token", fd),
  resetPassword: (fd) => postNoToken("/password/reset", fd),
  getIndustries: (page) => getAPIs(`/industries?page=${page}`),
  addIndustry: (fd) => postAPIs("/industries", fd),
  deleteIndustry: (id) => deleteAPIs(`/industries/${id}`),
  updateIndustry: (fd) => putAPIs(`/industries/${fd.id}`, fd),
  createCountry: (fd) => postAPIs(`/countries`, fd),
  getCountries: (page) => getAPIs(`/countries?page=${page}`),
  deleteCountry: (id) => deleteAPIs(`/countries/${id}`),
  updateCountry: (fd) => putAPIs(`/countries/${fd.id}`, fd),
  createKMPrice: (fd) => postAPIs(`/price-per-kms`, fd),
  getKMPrice: (page) => getAPIs(`/price-per-kms?page=${page}`),
  deleteKMPrice: (id) => deleteAPIs(`/price-per-kms/${id}`),
  updateKMPrice: (fd) => putAPIs(`/price-per-kms/${fd.id}`, fd),
  getAllStates: (page) => getAPIs(`/states?page=${page}`),
  getAllStatesByCountry: (page, country) =>
    getAPIs(`/country-states/${country}?page=${page}`),
  createState: (fd) => postAPIs(`/states`, fd),
  deleteState: (id) => deleteAPIs(`/states/${id}`),
  updateState: (fd) => putAPIs(`/states/${fd.id}`, fd),
  getAllAreas: (page) => getAPIs(`/area-of-ops?page=${page}`),
  createArea: (fd) => postAPIs(`/area-of-ops`, fd),
  deleteArea: (id) => deleteAPIs(`/area-of-ops/${id}`),
  updateArea: (fd) => putAPIs(`/area-of-ops/${fd.id}`, fd),
  getAllLCDAs: (page) => getAPIs(`/lcda?page=${page}`),
  getAllLCDAsByState: (page, state) =>
    getAPIs(`/state-lcdas/${state}?page=${page}`),
  getAreasByLCDA: (page, lcda) => getAPIs(`/lcda-areas/${lcda}?page=${page}`),
  getStateLCDAs: (page, state_id) => getAPIs(`/lcda/${state_id}?page=${page}`),
  createLCDA: (fd) => postAPIs(`/lcda`, fd),
  deleteLCDA: (id) => deleteAPIs(`/lcda/${id}`),
  updateLCDA: (fd) => putAPIs(`/lcda/${fd.id}`, fd),
  uploadImage: (fd) => postAPIs(`/images`, fd),
  getClients: (page, search) =>
    getAPIs(
      search
        ? `/all-clients/${search}?page=${page}`
        : `/all-clients?page=${page}`
    ),
  getClientInfo: (id) => getAPIs(`/clients/${id}`),
  getClientOrders: (id, page) => getAPIs(`/client-orders/${id}?page=${page}`),
  createClient: (fd) => postAPIs(`/clients`, fd),
  deleteClient: (id) => deleteAPIs(`/clients/${id}`),
  updateClient: (fd) => putAPIs(`/clients/${fd.id}`, fd),
  getTopIndustries: () => getAPIs(`/client-industry`),
  createVolumePrice: (fd) => postAPIs(`/price-per-volumes`, fd),
  getVolumePrice: (page) => getAPIs(`/price-per-volumes?page=${page}`),
  deleteVolumePrice: (id) => deleteAPIs(`/price-per-volumes/${id}`),
  deleteUser: (id) => deleteAPIs(`/users/${id}`),
  updateVolumePrice: (fd) => putAPIs(`/price-per-volumes/${fd.id}`, fd),
  approveClient: (fd) => postAPIs(`/approve-client`, fd),
  declineClient: (fd) => postAPIs(`/decline-client`, fd),
  assignRider: (fd) => postAPIs(`/riders-to-area`, fd),
  createUser: (fd) => postAPIs(`/register`, fd),
  getUsers: (page) => getAPIs(`/riders&admin?page=${page}`),
  updateUser: (fd) => putAPIs(`/users/${fd.id}`, fd),
  getUser: (id) => getAPIs(`/users/${id}`),
  getRiders: (page) => getAPIs(`/riders?page=${page}`),
  getUnassignedRiders: (page) => getAPIs(`/unassigned-riders?page=${page}`),
  createBank: (fd) => postAPIs(`/banks`, fd),
  getBanks: (page) => getAPIs(`/bikee-banks?page=${page}`),
  updateBank: (fd) => putAPIs(`/banks/${fd.id}`, fd),
  getBank: (id) => getAPIs(`/users/${id}`),
  deleteBank: (id) => deleteAPIs(`/banks/${id}`),
  getAreaRiders: (id, page) => getAPIs(`/area-riders/${id}?page=${page}`),
  getRiderHistory: (id, page) => getAPIs(`/rider-history/${id}?page=${page}`),
};

export const useFetchUser = (id) => {
  return useQuery(["user", id], () => apiFunctions.getUser(id), {
    enabled: id !== undefined && id !== null,
  });
};

export const useFetchBank = (id) => {
  return useQuery(["bank", id], () => apiFunctions.getBank(id), {
    enabled: id !== undefined && id !== null,
  });
};

export const useFetchTopIndustries = () => {
  return useQuery(["top_industries"], apiFunctions.getTopIndustries);
};

export const useFetchRiders = (page) => {
  return useQuery(["riders", page], () => apiFunctions.getRiders(page), {
    keepPreviousData: true,
  });
};

export const useFetchRiderHistory = (id, page) => {
  return useQuery(
    ["rider_history", id, page],
    () => apiFunctions.getRiderHistory(id, page),
    {
      keepPreviousData: true,
    }
  );
};

export const useFetchAreaRiders = (id, page) => {
  return useQuery(
    ["area_riders", page],
    () => apiFunctions.getAreaRiders(id, page),
    {
      enabled: id !== undefined && id !== null,
      keepPreviousData: true,
    }
  );
};

export const useFetchUnassignedRiders = (id, page) => {
  return useQuery(
    ["unassigned_riders", page],
    () => apiFunctions.getUnassignedRiders(page),
    {
      enabled: id === undefined,
      keepPreviousData: true,
    }
  );
};

export const useFetchBanks = (page) => {
  return useQuery(["banks", page], () => apiFunctions.getBanks(page), {
    keepPreviousData: true,
  });
};

export const useFetchUsers = (page) => {
  return useQuery(["users", page], () => apiFunctions.getUsers(page));
};

export const useFetchClientOrders = (page, client_id) => {
  return useQuery(
    ["client_orders", page, client_id],
    () => apiFunctions.getClientOrders(page, client_id),
    {
      keepPreviousData: true,
      enabled: client_id !== null && client_id !== undefined,
    }
  );
};
export const useFetchClientInfo = (client_id) => {
  return useQuery(
    ["client_info", client_id],
    () => apiFunctions.getClientInfo(client_id),
    {
      enabled: client_id !== null && client_id !== undefined,
    }
  );
};
export const useFetchIndustries = (page) => {
  return useQuery(["industries", page], () => apiFunctions.getIndustries(page));
};
export const useFetchAreas = (page) => {
  return useQuery(["areas", page], () => apiFunctions.getAllAreas(page), {
    keepPreviousData: true,
  });
};
export const useFetchAllLCDAs = (page) => {
  return useQuery(["lcdas", page], () => apiFunctions.getAllLCDAs(page), {
    keepPreviousData: true,
  });
};

export const useFetchClients = (page, search) => {
  return useQuery(
    ["clients", page, search],
    () => apiFunctions.getClients(page, search),
    { keepPreviousData: true }
  );
};

export const useFetchStateLCDAs = (page, state_id) => {
  return useQuery(
    ["state_lcdas", page, state_id],
    () => apiFunctions.getStateLCDAs(page, state_id),
    {
      keepPreviousData: true,
      enabled: state_id !== undefined || state_id === null,
    }
  );
};

export const useFetchStates = (page) => {
  return useQuery(["states", page], () => apiFunctions.getAllStates(page));
};

export const useFetchStatesByCountry = (page, country) => {
  return useQuery(
    ["states_by_country", page, country],
    () => apiFunctions.getAllStatesByCountry(page, country),
    {
      enabled:
        country !== null &&
        country !== undefined &&
        country.toString().trim() !== "",
    }
  );
};

export const useFetchLCDAsByState = (page, state) => {
  return useQuery(
    ["lcda_by_state", page, state],
    () => apiFunctions.getAllLCDAsByState(page, state),
    {
      enabled:
        state !== null && state !== undefined && state.toString().trim() !== "",
    }
  );
};

export const useFetchAreasByLCDA = (page, lcda) => {
  return useQuery(
    ["area_by_lcda", page, lcda],
    () => apiFunctions.getAreasByLCDA(page, lcda),
    {
      enabled:
        lcda !== null && lcda !== undefined && lcda.toString().trim() !== "",
    }
  );
};

export const useFetchCountries = (page) => {
  return useQuery(["countries", page], () => apiFunctions.getCountries(page));
};

export const useFetchKMPrice = (page) => {
  return useQuery(["km_prices", page], () => apiFunctions.getKMPrice(page));
};
export const useFetchVolumePrice = (page) => {
  return useQuery(
    ["volume_prices", page],
    () => apiFunctions.getVolumePrice(page),
    {
      keepPreviousData: true,
    }
  );
};

export const getAPIs = async (path) => {
  let _token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .get(`${endPoint}${path}`, {
        headers: {
          Authorization: `Bearer ${_token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: 0,
        },
        timeout: 5000,
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
          return;
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          typeof error.response.data.detail === "string"
        ) {
          reject({ status: 400, msg: error.response.data.detail });
        } else {
          reject({
            status: 500,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};

export const postAPIs = async (path, fd) => {
  let _token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      url: `${endPoint}${path}`,
      method: "post",
      data: fd,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${_token}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
          return;
        }
        if (
          Array.isArray(error?.response?.data?.message) &&
          typeof error?.response?.data?.message?.[0] === "string"
        ) {
          reject({ status: 400, msg: error?.response?.data?.message?.[0] });
        } else if (typeof error?.response?.data?.message === "string") {
          reject({ status: 400, msg: error?.response?.data?.message });
        } else {
          reject({
            status: 400,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};

export const deleteAPIs = async (path) => {
  let _token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .delete(`${endPoint}${path}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
          return;
        }
        if (
          Array.isArray(error?.response?.data?.message) &&
          typeof error?.response?.data?.message?.[0] === "string"
        ) {
          reject({ status: 400, msg: error?.response?.data?.message?.[0] });
        } else if (typeof error?.response?.data?.message === "string") {
          reject({ status: 400, msg: error?.response?.data?.message });
        } else {
          reject({
            status: 400,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};

export const putAPIs = async (path, fd) => {
  let _token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      url: `${endPoint}${path}`,
      method: "put",
      data: fd,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${_token}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
          return;
        }
        if (
          Array.isArray(error?.response?.data?.message) &&
          typeof error?.response?.data?.message?.[0] === "string"
        ) {
          reject({ status: 400, msg: error?.response?.data?.message?.[0] });
        } else if (typeof error?.response?.data?.message === "string") {
          reject({ status: 400, msg: error?.response?.data?.message });
        } else {
          reject({
            status: 400,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};

export const patchAPIs = async (path, fd) => {
  let _token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      url: `${endPoint}${path}`,
      method: "patch",
      data: fd,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${_token}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
          return;
        }
        if (
          error?.response?.data?.detail &&
          typeof error.response.data.detail === "string"
        ) {
          reject({ status: 500, msg: error.response.data.detail });
        } else {
          reject({
            status: 500,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};

export const postNoToken = (path, fd) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${endPoint}${path}`, fd, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        if (
          Array.isArray(error?.response?.data?.message) &&
          typeof error?.response?.data?.message?.[0] === "string"
        ) {
          reject({ status: 400, msg: error?.response?.data?.message?.[0] });
        } else if (typeof error?.response?.data?.message === "string") {
          reject({ status: 400, msg: error?.response?.data?.message });
        } else {
          reject({
            status: 400,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};

export const storeFile = async (path, fd, config) => {
  let _token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      url: `${endPoint}${path}`,
      method: "POST",
      data: fd,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${_token}`,
      },
      onUploadProgress: config,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (error.response) {
          reject({ status: 500, msg: error.response.data });
        } else {
          reject({
            status: 500,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};

export const storeFilePut = async (path, token, fd) => {
  let _token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      url: `${endPoint}${path}`,
      method: "put",
      data: fd,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${_token}`,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (error.response) {
          reject({ status: 500, msg: error.response.data });
        } else {
          reject({
            status: 500,
            msg: "Something went wrong. Please try again later",
          });
        }
      });
  });
};
