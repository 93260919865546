import { Skeleton } from "@mui/material"
import React from "react"
import { Container } from ".."
import styles from "./styles"

export default function ProfileSkeleton() {
    return(
        <Container>
            <Skeleton variant='text'/>
            <Skeleton variant='text'/>
            <Skeleton variant='circular'
                sx={styles.circular}
            />
            <Skeleton variant='text'/>
            <Skeleton variant='text'/>
            <Skeleton variant='rectangle'
                sx={styles.rectangular}
            />
        </Container> 
    )
}

export const PageLoader = () => (
    <Skeleton variant='rectangle' 
        height={"30rem"}
    />
)