import { FormGroup, IconButton, MenuItem } from '@mui/material';
import React, { useEffect } from 'react'
import { Container, CustomButton, H1, Input, Label, P, PaperBox,Image, CustomSelect, SearchableSelect, WarningModal } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import DataGridDemo, { CustomPagination } from '../../../../components/Tables';
import Images from '../../../../components/images'
import { apiFunctions, useFetchAreas, useFetchCountries, useFetchStatesByCountry,useFetchLCDAsByState } from '../../../../utils/api';
import { useMutation, useQueryClient } from 'react-query';
import { Capitalize, ToastError, ToastSuccess } from '../../../../utils/functions';
import moment from 'moment';


const CreateArea = () => {

    const [page,setPage] = React.useState(1)
    const [show,setShow] = React.useState(false)
    const queryClient = useQueryClient()
    const [selected,setSelected] = React.useState({})
    const [pageCount,setPageCount] = React.useState(1)

    const [inputValue,setInputValue] = React.useState({
        state : "",
        lcda : ""
    })
    const [filter,setFilter] = React.useState({
        country_id : "",
        state : {},
        lcda : {}
    })
    const [fPage] = React.useState(1)

    const {
        mutateAsync : deleteArea,
        isLoading : isDeleting
    } = useMutation(apiFunctions.deleteArea)
    const {
        mutateAsync : updateArea,
        isLoading : isUpdating
    } = useMutation(apiFunctions.updateArea)

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.createArea)
    const [data,setData] = React.useState({
        name : ""
    })
    const {
        data : areas,
        isLoading : loading
    } = useFetchAreas(page)

    const {
        data : countries
    } = useFetchCountries(fPage)

    const {
        data : states,
        isLoading : loadingStates
    } = useFetchStatesByCountry(fPage,filter.country_id)

    const {
        data : lcdas,
        isLoading : loadingLCDAs
    } = useFetchLCDAsByState(fPage,filter?.state?.id)

    const handleSubmit = async () => {
        try{
            if(
                data.name.toString().trim() === "" || filter?.lcda?.id === ""
            ){
                return ToastError("Please all fields are required")
            }

            if(!data.id){
                await mutateAsync({...data,country_id : filter?.country_id,state_id : filter?.state?.id,lcda_id : filter?.lcda?.id})
                ToastSuccess("New record has been added")
                setData({
                    name : ""
                })
                setFilter({
                    country_id : "",
                    state : {},
                    lcda : {}
                })
                return queryClient.invalidateQueries("areas")
            }
            let fd = {
                name : data.name,
                state_id : filter?.state?.id,
                lcda_id : filter?.lcda?.id,
                country_id : filter?.country_id,
                id : data.id
            }
            await updateArea(fd)
            ToastSuccess("Record has been updated")
            setData({
                name : ""
            })
            setFilter({
                country_id : "",
                state : {},
                lcda : {}
            })
            queryClient.invalidateQueries("areas")
        }catch(err){
            ToastError(err.msg)
        }
    }

    const handleDeleteWarning = (item) => {
        setSelected(item)
        setShow(true)
    }

    const handleDelete = async () => {
        try{
            await deleteArea(selected.id)
            ToastSuccess(`${selected.name} has been deleted`)
            queryClient.invalidateQueries("areas")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
    }

    const handleEdit = (item) => {
        setData(item)
        setFilter({
            country_id : item?.country_id,
            state : {name : item?.state?.name,id : item.state_id},
            lcda : {name : item?.lcda?.name,id : item.lcda_id}
        })
        window.scrollTo(0,0)
    }

    
    useEffect(()=>{
        if(!areas) return
        setPage(areas?.data?.current_page)
        setPageCount(areas?.data?.last_page)
    },[areas])

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        {
            field : "state",
            header : "State",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.state?.name ? Capitalize(row?.state?.name) : null}</H1>
        },
        {
            field : "lcda",
            header : "LCDA",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.lcda?.name ? Capitalize(row?.lcda?.name) : null}</H1>
        },
        {
            field : "area",
            header : "Area",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.name ? Capitalize(row?.name) : null}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.created_at ? moment(row.created_at).format("DD/MM/YYYY") : null}</H1>
        },
        {
            field : "edit",
            header : "Edit",
            renderCell : (row) => <IconButton onClick={()=>handleEdit(row)}>
                    <Image backgroundimage={Images.edit} width={"1.2rem"} height={"1.2rem"} />
                </IconButton>
        },
        {
            field : "delete",
            header : "Delete",
            renderCell : (row) => <IconButton onClick={()=>handleDeleteWarning(row)}>
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));
    return(
        <PaperBox padding={"2rem"}>
            <Container marginBottom={"2rem"}>
                <form>
                    <P fontSize={"1.018rem"} marginBottom={"2rem"}>Create new area of operation</P>
                    <Container container spacing={2}>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Country</Label>
                                <CustomSelect value={filter.country_id} onChange={(e)=>setFilter({...filter,country_id : e.target.value,state : {}, lcda : {}})}>
                                    {
                                        countries?.data?.data && Array.isArray(countries?.data?.data) ? countries.data.data.map((item,i)=>{
                                            return <MenuItem key={i} value={item.id}>{item?.name ? Capitalize(item.name) : ""}</MenuItem>
                                        }) : <MenuItem value="">Select Country</MenuItem>
                                    }
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>State</Label>
                                <SearchableSelect 
                                    value={filter?.state}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.title === value.title
                                    }}
                                    onChange={(e,value)=>{
                                        setFilter({...filter,state : value})
                                    }}
                                    inputValue={inputValue?.state  ? inputValue?.state : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,state : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={states?.data?.data || []}
                                    loading={loadingStates}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>LCDAs</Label>
                                <SearchableSelect 
                                    value={filter?.lcda}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.title === value.title
                                    }}
                                    onChange={(e,value)=>{
                                        setFilter({...filter,lcda : value})
                                    }}
                                    inputValue={inputValue?.lcda  ? inputValue?.lcda : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,lcda : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={lcdas?.data?.data || []}
                                    loading={loadingLCDAs}
                                
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Area</Label>
                                <Input placeholder='Enter area'
                                    value={data.name} 
                                    onChange={e=>setData({...data,name : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container marginTop={"2rem"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    >
                        <CustomButton variant='outlined'
                            width={"6rem"}
                            padding={"0.4rem"}
                            border={`1.5px solid ${AppColors.red}`}
                            borderradius={"0.452rem"}
                            onClick={handleSubmit}
                            loading={isLoading || isUpdating}
                        >
                            <H1 fontWeight={"bold"} color={AppColors.red}>Save</H1>
                        </CustomButton>
                    </Container>
                </form>
            </Container>
            <Container>
                <DataGridDemo columns={columns} 
                     rows={areas?.data && Array.isArray(areas?.data?.data) ? areas?.data?.data : []}
                     hideFooter={false}
                     loading={loading}
                     CustomPagination={RenderPagination}
                    hideFooter={false}
                />
                <WarningModal 
                    open={show} handleDelete={handleDelete} closeWarning={closeWarning}
                    loading={isDeleting}
                    selection={selected?.name}
                />
            </Container>
        </PaperBox>
    )
}

export default CreateArea;