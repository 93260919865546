import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box } from '@mui/material';
import numeral from 'numeral';
import React from 'react';
import { useSelector } from 'react-redux';
import { H1,P,Image,Container, PaperBox } from '..';
import { Capitalize } from '../../utils/functions';
import { AppColors } from '../AppColors';
import Images from '../images';
import styles from './styles'

const ClientOrder = () => {
    const order = useSelector(state=>state.order)
    return(
        <Box
            sx={{ width: 600}}
            role="presentation"
        >
            <PaperBox padding={"2rem"}>
                <Container>
                    <H1 fontWeight={"bold"} fontSize={"0.869rem"} marginTop={"1rem"}
                        marginBottom={"1rem"}
                    >Recipient details</H1>
                    <P fontSize={"0.802rem"}>Full name: {order?.customer?.first_name ? `${Capitalize(order?.customer?.first_name)} ` : null}{order?.customer?.last_name ? `${Capitalize(order?.customer?.last_name)}` : null}</P>
                    {
                        [
                            {
                                icon : Images.AtIcon,
                                title : order?.customer?.email ? Capitalize(order?.customer?.email) : null
                            },
                            {
                                icon : Images.Phone,
                                title : order?.customer?.phone

                            },
                            {
                                icon : Images.mappin,
                                title : order?.customer?.address?.formatted_address ? Capitalize(order?.customer?.address?.formatted_address) : null
                            }
                        ].map((item,index)=><Container key={index} display={"flex"}
                            alignItems={"center"}
                            marginTop={"0.8rem"}
                            
                        >
                            <Image 
                                backgroundimage={item.icon}
                                width={"1rem"}
                                height={"1rem"}
                            />
                            <P fontSize={"0.802rem"} marginLeft={"1rem"}>{item.title}</P>
                        </Container>)
                    }
                </Container>
                <Container>
                    <Container marginTop={"2rem"} marginBottom={"1rem"}>
                        <H1 fontSize={"0.869rem"} fontWeight={"bold"} marginBottom={"0.5rem"}>Rider: {order?.rider?.first_name ? `${Capitalize(order?.rider?.first_name)} ` : null}{order?.rider?.last_name ? `${Capitalize(order?.rider?.last_name)}` : null}</H1>
                        <H1 fontSize={"0.869rem"} fontWeight={"bold"} marginBottom={"0.5rem"}>ID: {order?.order_ref}</H1>
                        <P fontSize={"0.927rem"}>DELIVERY FEE: N{order?.total_amount ? numeral(order?.total_amount).format("0,0.00") : "0.00"}</P>
                    </Container>
                    <Timeline sx={styles.timelinecontainer}>
                        {
                            [
                                "Order Delivered","Order-in-transit",
                                "Order Assigned","Order Created"
                            ].map((item,i)=><TimelineItem key={i}>
                            <TimelineSeparator>
                                <TimelineDot sx={{backgroundColor : 
                                    item === "Order Delivered" ? AppColors.lime : 
                                    item === "Order Assigned" ? AppColors.red : AppColors.darkYellow
                                }}/>
                                <TimelineConnector sx={styles.timelineconnector} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <H1 variant="h6" fontWeight={"bold"} color={AppColors.grey}
                                    fontSize={"1.043rem"}
                                >{item}</H1>
                                <P>NIL</P>
                            </TimelineContent>
                        </TimelineItem>)
                        }
                    </Timeline>
                </Container>
            </PaperBox>
        </Box>
    )
}

export default ClientOrder;