import DeleteIcon from '../images/Delete.png'
import EditIcon from '../images/Edit-Square.png'
import UploadIcon from '../images/uploader.png'
import UserIcon from '../images/user.png'
import MapPIN from '../images/mappin.png'
import Phone from  '../images/phoneicon.png'
import AtIcon from  '../images/aticon.png'
import FileIcon from  '../images/file.png'
import Document from '../images/document.png'
import Performance from '../images/performance.png'
import Redbg from '../images/redbg.png'
import Map from '../images/map.png'


const Images = {
    delete : DeleteIcon,
    edit : EditIcon,
    upload : UploadIcon,
    user : UserIcon,
    mappin : MapPIN,
    AtIcon,
    Phone,
    File : FileIcon,
    document : Document,
    Performance,
    Redbg,
    Map
}

export default Images;