import { AppColors } from "../../../components/AppColors";

const styles = {
    timelinecontainer : {
        padding : "6px 0px",
        '.MuiTimelineItem-root:before' : {
            display : 'none !important'
        }
    },
    timelineconnector : {backgroundColor : AppColors.line},
    backButton : {
        backgroundColor : AppColors.offWhite,
        marginRight : "1rem",
        marginBottom : "2rem"
    },
    avatar : {
        width : "6rem",
        height : "6rem",
        backgroundColor : AppColors.lightGrey
    }
}

export default styles;