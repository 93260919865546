export const AppColors = {
    grey : "#555B6A",
    lightGrey : "#D9DDE2",
    lightestGrey : "#A0AAC2",
    offWhite : "#F5F5F5",
    red : "#FC3400",
    lightRed : 'rgba(252, 52, 0, 0.1)',
    black : "#030919",
    white : "#FFFFFF",
    lightestRed : "#FAFAFA",
    buttonYellow : "#FFC727",
    yellow : "#FFF0E6",
    blue : "#ECEAFE",
    lightGreen : "#E5F7FF",
    textGrey : "#718096",
    lime : "#52C41A",
    lightLime : "rgba(82, 196, 26, 0.1)",
    line : '#EDF2F7',
    green : '#06AA8D',
    purple : '#6956E5',
    darkYellow : '#FAAD14',
    greyBackground : "#F4F4F6",
    grayBorder : "rgba(0, 0, 0, 0.23)",
    greenBackground : "rgba(43, 153, 67, 0.1)",
    purpleBackground : "#605CFF",
    lightPurple : "#deddff",
    darkGrey : "#A0AAC2"

}