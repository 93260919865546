import { Avatar, FormGroup, IconButton, MenuItem, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { Container, CustomButton, H1, Input, Label, P, PaperBox,Image, CustomSelect, CustomDatePicker, WarningModal, WarningModalWithInput } from '../../../components'
import { AppColors } from '../../../components/AppColors';
import { ORDER_HISTORY } from '../../../components/Constants';
import DataGridDemo, { CustomPagination } from '../../../components/Tables';
import Images from '../../../components/images'
import { ArrowBack, Search } from '@mui/icons-material';
import styles from './styles';
import { useDispatch } from 'react-redux';
import { CLIENTS_ORDER } from '../../../actions/actionTypes';
import { drawerHandler } from '../../../redux/drawerSlice';
import ProfileSkeleton from '../../../components/ProfileSkeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { apiFunctions, useFetchClientInfo, useFetchClientOrders } from '../../../utils/api';
import numeral from 'numeral';
import { Capitalize, ToastError, ToastSuccess } from '../../../utils/functions';
import moment from 'moment';
import { changeOrderHandler } from '../../../redux/orderSlice';
import { useMutation, useQueryClient } from 'react-query';


const ClientDetails = () => {
    const dispatch = useDispatch()
    const {client_id,client_name} = useParams()
    const [page,setPage] = React.useState(1)
    const [pageCount,setPageCount] = React.useState(1)
    const navigate = useNavigate()
    const [show,setShow] = React.useState(false)
    const [display,setDisplay] = React.useState(false)
    const [action,setAction] = React.useState("")
    const queryClient = useQueryClient()

    const {
        mutateAsync : approveClient,
        isLoading : approving
    } = useMutation(apiFunctions.approveClient)
    
    const {
        mutateAsync : declineClient,
        isLoading : declining
    } = useMutation(apiFunctions.declineClient)

    const orderDetailsHandler = (item) => {
        dispatch(changeOrderHandler(item))
        dispatch(drawerHandler(CLIENTS_ORDER))
    }

    const {
        data : load,
        isLoading : loading
    } = useFetchClientInfo(client_id)

    const {
        data : orders,
        isFetching
    } = useFetchClientOrders(client_id,page)

    useEffect(()=>{
        if(!orders) return
        setPage(orders?.current_page)
        setPageCount(orders?.data?.last_page)
    },[orders])

    const onPageChange = (value) => {
        setPage(value)
    }

    const handleWarning = (param) => {
        setAction(param)
        if(param === "verify") setShow(true)
        if(param === "decline") setDisplay(true)
    }

    const handleApproval = async () => {
        try{
            let fd = {
                "client_id": client_id
            }
            await approveClient(fd)
            ToastSuccess(`${client_name || 'Account'} has been approved successfully`)
            queryClient.invalidateQueries("clients")
            queryClient.invalidateQueries("client_info")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const handleDecline = async (text) => {
        try{
            if(text.toString().trim() === "") return ToastError("Please provide reason for declining this account")

            let fd = {
                "client_id": client_id,
                notes : text
            }
            await declineClient(fd)
            ToastSuccess(`${client_name || 'Account'} has been declined successfully`)
            queryClient.invalidateQueries("clients")
            queryClient.invalidateQueries("client_info")
            setDisplay(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
        setDisplay(false)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        {
            field : "orderID",
            header : "Order ID",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.order_ref}</H1>
        },
        {
            field : "recipient",
            header : "Recipient",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.customer?.first_name ? `${row?.customer?.first_name} ` : null}{row?.customer?.last_name ? `${row?.customer?.last_name}` : null}</H1>
        },
        {
            field : "destination",
            header : "Destination",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.delivery_address ? Capitalize(row?.delivery_address) : null}</H1>
        },
        {
            field : "rider",
            header : "Rider",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.rider?.first_name ? `${row?.rider?.first_name} ` : null}{row?.rider?.last_name ? `${row?.rider?.last_name}` : null}</H1>
        },
        {
            field : "payment_type",
            header : "Payment type",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.payment_method}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.created_at ? moment().format("DD/MM/YYYY") : null}</H1>
        },
        {
            field : "status",
            header : "",
            renderCell : (row) => <CustomButton background={AppColors.lightLime} borderradius={"0.5rem"}
                    onClick={()=>orderDetailsHandler(row)}
            >
                <P color={AppColors.lime}>View</P>
            </CustomButton> 
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <Container display={"flex"}
            justifyContent={"space-between"}
            marginBottom={"2rem"}
        >
            <PaperBox width="25%" padding={"2rem"}>
                <IconButton onClick={()=>navigate(-1)} 
                    sx={styles.backButton}
                >
                    <ArrowBack />
                </IconButton>
                {
                    loading ? <ProfileSkeleton /> : <Container>
                    <Container>
                        <P color={AppColors.grey} fontSize={"0.682rem"}>CATEGORY: {load?.data?.category ? load?.data?.category.toString().toUpperCase() : null}</P>
                        <P color={AppColors.grey} fontSize={"0.682rem"}>INDUSTRY: {load?.data?.industry?.name ? load?.data?.industry?.name.toString().toUpperCase() : null}</P>
                    </Container>
                    <Container display={"flex"} flexDirection={"column"}
                        alignItems={"center"}
                        marginTop={"1rem"}
                    >
                        {
                            load?.data?.image_url ? <Image 
                                backgroundimage={load?.data?.image_url}
                                width={"6rem"}
                                height={"6rem"}
                                background={AppColors.red}
                                radius={"50%"}
                            /> : <Avatar sx={styles.avatar} />
                        }
                        <H1 fontSize={"1.043rem"} fontWeight={"bold"} textAlign={"center"}
                            marginTop={"1rem"}
                            marginBottom={"0.3rem"}
                        >{load?.data?.name ? Capitalize(load?.data?.name) : null}</H1>
                        <P color={AppColors.grey} textAlign={"center"} fontSize={"0.825rem"}>{load?.data?.address ? Capitalize(load?.data?.address) : null}</P>
                    </Container>
                   {
                       load?.data?.description ?  <Container marginTop={"2rem"} border={`1px solid ${AppColors.grayBorder}`} padding={"1rem"}
                       borderRadius={"0.5rem"}
                    >
                       <H1 fontSize={"0.949rem"} fontWeight={"bold"} marginBottom={"0.5rem"}>Business description</H1>
                       <P color={AppColors.grey} fontSize={"0.75rem"}>{load?.data?.description}</P>
                    </Container> : null
                   }
                    {
                        load?.data?.status === "INACTIVE" ? <Container display={"flex"} justifyContent={"space-between"}
                            alignItems={"center"}
                            marginTop={"2rem"}
                        >
                        <CustomButton variant="outlined" width="8rem"
                            border={`1px solid ${AppColors.grayBorder}`}
                            onClick={()=>handleWarning("decline")}
                        >
                            <H1 fontWeight={"bold"} color={AppColors.grey}>Decline</H1>
                        </CustomButton>
                        <CustomButton mode="danger" variant="contained" width="8rem"
                            onClick={()=>handleWarning("verify")}
                        >
                            <H1 fontWeight={"bold"}>Verify</H1>
                        </CustomButton>
                    </Container> : <Container display={"flex"} justifyContent={"center"}
                            alignItems={"center"}
                            marginTop={"2rem"}
                        >
                            <CustomButton variant="outlined" width="8rem"
                                border={`1px solid ${AppColors.grayBorder}`}
                                onClick={()=>handleWarning("decline")}
                            >
                                <H1 fontWeight={"bold"} color={AppColors.grey}>Decline</H1>
                            </CustomButton>
                        </Container>
                    }
                </Container>
                }
            </PaperBox>
            <PaperBox width={"60%"} padding={"2rem"}>
                <Container>
                    <DataGridDemo columns={columns}
                        rows={orders?.data?.data && Array.isArray(orders?.data?.data) ? orders?.data?.data : []}
                        hideFooter={false}
                        loading={isFetching}
                        CustomPagination={RenderPagination}
                        hideFooter={false}
                        Toolbar={()=><Container>
                            <H1 variant='h6' fontWeight={"bold"} fontSize={"1.011rem"}
                            >{client_name ? `${client_name.toUpperCase()}'S` : null} Order History - {orders?.data?.data && Array.isArray(orders?.data?.data) ? numeral(orders?.data?.data.length).format("0,0") : 0} Orders Till Date</H1>
                            <Container display={"flex"} alignItems={"center"} marginTop={"1rem"}
                                marginBottom={"1rem"}
                                justifyContent={"space-between"}
                            >
                                <CustomSelect width="13rem">
                                    <MenuItem>LCDA</MenuItem>
                                </CustomSelect>
                                <CustomDatePicker />
                                <Input type="text" placeholder='Search'
                                    startAdornment={<Search sx={styles.searchIcon} />}
                                />
                            </Container>
                        </Container>}
                    />
                </Container>
            </PaperBox>
            <WarningModal 
                open={show} 
                handleDelete={handleApproval} 
                closeWarning={closeWarning}
                loading={approving}
                selection={client_name}
                action_type={"Approval"}
                action={action}
            />
            <WarningModalWithInput 
                open={display} 
                submitHandler={handleDecline} 
                closeWarning={closeWarning}
                loading={declining}
                selection={client_name}
                placeholder={`Enter reason for declining ${client_name ? Capitalize(client_name) : ""}`}
            />
        </Container>
    )
}

export default ClientDetails;