import { FormGroup } from "@mui/material"
import React, { useEffect } from "react"
import { useMutation } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { LinkedButton,H1, Container, CustomButton, P, Label, Input} from "../../components"
import { AppColors } from "../../components/AppColors"
import { apiFunctions } from "../../utils/api"
import { ToastError } from "../../utils/functions"


const VerifyToken = () => {
    const navigate = useNavigate()
    const [otp,setOTP] = React.useState("")
    const {email} = useParams()
    const {mutateAsync,isLoading} = useMutation(apiFunctions.verifyToken)

    const handleSubmit = async () => {
        try{
            if(otp.toString().trim() === ""){
                return ToastError("Please provide a valid OTP")
            }
            let fd = {
                token : otp
            }
            await mutateAsync(fd)
            navigate(`/reset-password/${otp}`)
        }catch(err){
            ToastError(err.msg)
        }
    }
    
    return(
        <Container item md={7}>
            <Container
                justifyContent={"right"}
                display={"flex"}
                alignItems={"center"}
                width="90%"
                marginTop={"2rem"}
                marginBottom={"5rem"}
            >
                <LinkedButton to="/login" color={AppColors.grey}>Login</LinkedButton>
            </Container>
            <Container display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <H1 variant="h5" fontWeight={'bold'} textAlign={'center'} 
                    marginBottom={"1rem"}
                    marginTop={"2rem"}
                    fontSize={"1.5rem"}
                >Verify Your OTP</H1>
                    <P textAlign={"center"} color={"#555B6A"} fontSize={"0.948rem"} color={AppColors.grey}>
                    We've sent an OTP to your email address:</P>
                    <P color={"#FF0000"} textAlign={"center"} fontSize={"0.948rem"}>{email}</P>

                    <Container item md={6} marginBottom={"2rem"} marginTop={"2rem"}>
                        <FormGroup>
                            <Label>Enter OTP</Label>
                            <Input placeholder="Enter OTP" type="email" value={otp} onChange={(e)=>setOTP(e.target.value)}/>
                        </FormGroup>
                    </Container>
                    <Container 
                        container 
                        display={"flex"} 
                        justifyContent={"center"} 
                        alignItems={"center"}
                        direction={"column"}
                    >
                            <CustomButton mode="danger" variant="contained" width="20rem" marginbottom={"2rem"}
                                onClick={handleSubmit}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Verify
                            </CustomButton>
                            <P fontSize={"0.948rem"}>Didn't receive an email? <LinkedButton to="#" mode="danger">Resend</LinkedButton></P>
                        </Container>
            </Container>
    </Container>
    )
}

export default VerifyToken;