import { Box, Grid } from "@mui/material"
import React, { useEffect } from "react"
import { Outlet,Link, useNavigate } from "react-router-dom";
import { AuthIllustration, AuthWrapper, P } from "../../components";
import Img from "../../images/bg.png"


const Authentication = () => {
    const navigate = useNavigate()
    const checkLoggedInUser = () => {
        const token = localStorage.getItem("token")
        if(!token){
            return navigate("/login")
        }
        navigate("/user/dashboard")
    }
    useEffect(()=> {
        checkLoggedInUser()
    },[])
    return(
        <Box>
            
            <Grid container>
                <AuthIllustration 
                    md={5} item
                    backgroundimg={Img}
                />
                <Outlet />
            </Grid>
        </Box>
    )
}

export default Authentication;