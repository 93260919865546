import { AppColors } from "./AppColors";

const styles = {
    blackDashboardBtn : {
        backgroundColor : AppColors.black,
        color : AppColors.textGrey,
        borderRadius : '0.272rem',
        fontWeight : "bold",
        fontSize : "0.773rem",
        marginRight : "0.5rem",
        boxShadow : "0.3rem 0.3rem 0.8rem 0.3rem  #e9e0e0",
    },
    whiteDashboardBtn : {
        backgroundColor : AppColors.white,
        color : AppColors.grey,
        borderRadius : '0.272rem',
        fontWeight : "bold",
        fontSize : "0.773rem",
        marginRight : "0.5rem",
        boxShadow : "0.3rem 0.3rem 0.8rem 0.3rem  #e9e0e0",
    }
}

export default styles;