import { ArrowBack } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import styles from "./styles"

export default function BackButton() {
    const navigate = useNavigate()
    return(
        <IconButton onClick={()=>navigate(-1)} 
            sx={styles.backButton}
        >
            <ArrowBack />
        </IconButton>
    )
}