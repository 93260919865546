import React from 'react'
import { FormGroup, MenuItem} from '@mui/material'
import {Container,H1,CustomSelect,Input, CustomDatePicker,LinkedButton, CustomButton, P, Label} from './index'
import { ArrowForward } from '@mui/icons-material';
import { AppColors } from './AppColors';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers';
import styles from './styles'

export const TRANSACTION_LIST_TOOLBAR = () => (
    <Container marginTop={"4rem"} container
        marginBottom={"1rem"}
        direction={"row"} 
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Container 
            direction={"row"} 
            container
            display={"flex"}
            alignItems={"center"}
            width={"75%"}
            justifyContent={"space-between"}
        >
                <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"}>Transaction</H1>
                <CustomDatePicker width={"9rem"}/>
                <ArrowForward 
                    fontSize='small'
                    sx={{
                        color : AppColors.grey
                    }} 
                />
                <CustomDatePicker width={"9rem"} />
                <CustomSelect sx={{
                    width : "9rem",
                    height : "3rem"
                }}>
                    <MenuItem>Already Paid</MenuItem>
                    <MenuItem>COD</MenuItem>
                    <MenuItem>Transfer</MenuItem>
                </CustomSelect>
                <CustomSelect sx={{
                    width : "9rem",
                    height : "3rem"
                }}>
                    <MenuItem>Paid</MenuItem>
                </CustomSelect>

        </Container>
        {/* <LinkedButton to="/user/transaction-history">
            <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"} color={AppColors.textGrey}>View all</H1>
        </LinkedButton> */}
    </Container>
)

export const TRANSACTION_HISTORY_TOOLBAR = (props) => (
    <Container
        container
        marginBottom={"1rem"}
        direction={"row"} 
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Container 
            direction={"row"} 
            container
            display={"flex"}
            alignItems={"center"}
            width={"65%"}
            justifyContent={"space-between"}
        >
                <Container width={"13rem"} marginRight={"0.5rem"}>
                    <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"} singleline="true">{props.title}</H1>
                </Container>
                <CustomDatePicker />
                <ArrowForward 
                    fontSize='small'
                    sx={{
                        color : AppColors.grey
                    }} 
                />
                <CustomDatePicker />
                <CustomSelect sx={{
                    width : "8rem",
                    height : "3rem"
                }}>
                    <MenuItem>Already Paid</MenuItem>
                    <MenuItem>COD</MenuItem>
                    <MenuItem>Transfer</MenuItem>
                </CustomSelect>

        </Container>
        <CustomButton
            variant="outlined" width="10rem" border={`2px solid ${AppColors.line}`}
            padding={"0.5rem 0"} 
        >
            <H1 variant='h6' fontWeight={"bold"} fontSize={"0.901rem"} color={AppColors.textGrey}>
                EXPORT - CSV
            </H1>
        </CustomButton>
    </Container>
)


export const INVOICE_TOOLBAR = (props) => (
    <Container
        container
        marginBottom={"1rem"}
        direction={"row"} 
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Container 
            direction={"row"} 
            container
            display={"flex"}
            alignItems={"center"}
            width={"80%"}
        >
            <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"} singleline="true" 
                marginRight={"1rem"}
            >{props.title}</H1>
            <CustomDatePicker margin={"0px 0.5rem 0px 0px"} />
            <ArrowForward 
                fontSize='small'
                sx={{
                    color : AppColors.grey
                }} 
            />
            <CustomDatePicker margin={"0px 1rem 0px 0.5rem"} />
            <CustomSelect sx={{
                width : "8rem",
                height : "3rem",
                marginRight : "1rem"
            }}>
                <MenuItem>Already Paid</MenuItem>
                <MenuItem>COD</MenuItem>
                <MenuItem>Transfer</MenuItem>
            </CustomSelect>
            <CustomSelect sx={{
                width : "8rem",
                height : "3rem"
            }}>
                <MenuItem>Chicken Republic</MenuItem>
                <MenuItem>Bukkahut</MenuItem>
                <MenuItem>Yokoto</MenuItem>
            </CustomSelect>
        </Container>
        <CustomButton
            variant="outlined" width="10rem" border={`2px solid ${AppColors.line}`}
            padding={"0.5rem 0"} 
        >
            <H1 variant='h6' fontWeight={"bold"} fontSize={"0.901rem"} color={AppColors.textGrey}>
                EXPORT - CSV
            </H1>
        </CustomButton>
    </Container>
)

export const DASHBOARD_TOOLBAR = (props) => {
    return(
        <Container 
            flexDirection={"row"} 
            display={"flex"} 
            marginBottom={"2rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
           <Container 
                width={"60%"} 
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
           >
                <FormGroup>
                    <Label>Calendar</Label>
                    <CustomDatePicker />
                </FormGroup>
                {/* <FormGroup>
                    <Label>Time</Label>
                    <CustomDatePicker />
                </FormGroup> */}
                <FormGroup>
                    <Label>Delivery status</Label>
                    <CustomSelect 
                        sx={{
                            width : "10rem",
                            height : "3rem"
                        }}
                    >
                        <MenuItem>In-transit</MenuItem>
                        <MenuItem>Delivered</MenuItem>
                    </CustomSelect>
                </FormGroup>
                <FormGroup>
                    <Label>Clients</Label>
                    <CustomSelect 
                        sx={{
                            width : "10rem",
                            height : "3rem"
                        }}
                    >
                        <MenuItem>Chicken Republic</MenuItem>
                    </CustomSelect>
                </FormGroup>
           </Container>
           <Container 
                width={"30%"}
                flexDirection={"row"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
           >
               {
                   ["Table","Map"].map((item,i)=><CustomButton 
                    sx={props.current === item ? styles.blackDashboardBtn : styles.whiteDashboardBtn}
                    key={i}
                    onClick={()=>props.setCurrent(item)}
                   >{item}</CustomButton>)
               }
               <Input 
                    placeholder='Search'
                    startAdornment={
                        <SearchIcon 
                            fontSize='medium' sx={{color : AppColors.textGrey}}
                        />
                    }
                />
           </Container>
        </Container>
    )
}

export const CUSTOMER_TOOLBAR = (props) => (
    <Container
        container
        marginBottom={"1rem"}
        direction={"row"} 
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Container 
            direction={"row"} 
            container
            display={"flex"}
            alignItems={"center"}
            width={"50%"}
            justifyContent={"space-between"}
        >
                <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"}>{props.title}</H1>
                <Input 
                    placeholder='Search for anything'
                    startAdornment={
                        <SearchIcon 
                            fontSize='medium' sx={{color : AppColors.grey}}
                        />
                    }
                />

        </Container>
    </Container>
)

export const LASTEST_ORDERS_TOOLBAR = () => {
    return(
        <Container 
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <H1 variant='h6' fontWeight={"bold"}>Latest orders - <P variant="span" color={AppColors.textGrey}>08/06/2022</P></H1>
            <LinkedButton to="#">
                <H1 variant='h6' fontWeight={"bold"}>View All</H1>
            </LinkedButton>
        </Container>
    )
}
