import React from "react"
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput, Step, StepLabel, Stepper, TextField } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { LinkedButton,H1, Container, CustomStepLabel, CustomButton, P, Input, Label, CheckedBox } from "../../components"
import { AppColors } from "../../components/AppColors"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { ToastError, ToastSuccess, validateEmail } from "../../utils/functions"
import { apiFunctions } from "../../utils/api"
import { useMutation } from "react-query"


const Login = () => {
    const navigate = useNavigate()
    const [show,setShow] = React.useState(false)
    const [email,setEmail] = React.useState("")
    const [password,setPassword] = React.useState("")
    const {mutateAsync,isLoading} = useMutation(apiFunctions.login)

    const submitHandler = async () => {
        try{
            let fd = {
                email,
                password
            }
            if(email.toString().trim() === "" || password.toString().trim() === ""){
                return ToastError("Please provide an email address and password")
            }
            if(!validateEmail(email)){
                return ToastError("Please provide a valid email address")
            }
            let res = await mutateAsync(fd)
            localStorage.setItem("token",res.token)
            localStorage.setItem("user",JSON.stringify(res.data))
            navigate("/user/dashboard")
        }catch(err){
            ToastError(err.msg)
        }
    }
    const handleShow = () => {
        setShow(!show)
    }
    return(
        <Container item md={7} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Container width={"60%"}>
                <H1 variant="h5" fontWeight={'bold'} textAlign={'center'} 
                    fontSize={"1.5rem"}
                    marginBottom={"1rem"}
                    marginTop={"2rem"}
                >Welcome Back!</H1>
                <P textAlign={"center"} fontSize={"0.948rem"} color={AppColors.grey}>Access your BIKEE management dashboard </P>
                <form>
                    <Container container justifyContent={"space-around"}
                            marginTop={"2rem"}
                        >
                            <Container item md={12} marginBottom={"2rem"}>
                                <FormGroup>
                                    <Label>Email address</Label>
                                    <Input value={email} placeholder="Email address" type="email" onChange={(e)=>setEmail(e.target.value)}/>
                                </FormGroup>
                            </Container>
                            <Container item md={12}>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Input placeholder="*********" type={!show ? "password" : "text"} 
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShow}
                                              >
                                                {show ? <VisibilityOff /> : <Visibility />}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                    />
                                </FormGroup>
                            </Container>
                        </Container>
                    <Container marginTop={"2rem"} container direction={"row"} justifyContent={"space-between"}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <FormControlLabel
                            control={
                                <CheckedBox />
                            }
                            label={<P color={AppColors.grey}>Remember me</P>}
                        />
                        <LinkedButton to="/recover" mode="danger">Forgot Password?</LinkedButton>
                    </Container>
                    <Container container display={"flex"} justifyContent={"center"} alignItems={"center"}
                        direction={"column"}
                        marginTop={"3rem"}
                    >
                        <CustomButton mode="danger" variant="contained" width="20rem" marginbottom={"2rem"}
                            onClick={submitHandler}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Login
                        </CustomButton>
                    </Container>
                </form>
            </Container>
        </Container>
    )
}

export default Login