import React from "react"
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, OutlinedInput, Step, StepLabel, Stepper, TextField } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { LinkedButton,H1, Container, CustomStepLabel, CustomButton, P, Input, Label, CheckedBox } from "../../components"
import { AppColors } from "../../components/AppColors"
import { ToastError, validateEmail } from "../../utils/functions"
import { apiFunctions } from "../../utils/api"
import { useMutation } from "react-query"


const Recover = () => {
    const navigate = useNavigate()
    const [email,setEmail] = React.useState("")
    const { 
        mutateAsync, isLoading
    } = useMutation(apiFunctions.forgot)

    const handleSubmit = async () => {
        try{
            if(!validateEmail(email)){
                return ToastError("Please provide a valid email address")
            }
            let fd = {email}
            await mutateAsync(fd)
            navigate(`/verify-token/${email}`)
        }catch(err){
            ToastError(err.msg)
        }
    }
    return(
        <Container item md={7} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Container width={"60%"}>
                <H1 variant="h5" fontWeight={'bold'} textAlign={'center'} 
                    marginBottom={"1rem"}
                    marginTop={"2rem"}
                    fontSize={"1.5rem"}
                >Recover Password</H1>
                <P textAlign={"center"} color={"#555B6A"} fontSize={"0.948rem"}>Please put in your registered email address to recover your password</P>
                <form>
                        <Container container justifyContent={"space-around"}
                            marginTop={"2rem"}
                        >
                            <Container item md={12} marginBottom={"2rem"}>
                                <FormGroup>
                                    <Label>Email Address</Label>
                                    <Input placeholder="Email Address" type="email" onChange={(e)=>setEmail(e.target.value)}/>
                                </FormGroup>
                            </Container>
                        </Container>
                        <Container container display={"flex"} justifyContent={"center"} alignItems={"center"}
                            flexDirection={"column"}
                            marginTop={"3rem"}
                        >
                            <CustomButton mode="danger" variant="contained" width="20rem" marginbottom={"2rem"}
                                onClick={handleSubmit}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Recover
                            </CustomButton>
                            <P fontSize={"0.948rem"}>Remember Password? <LinkedButton to="/login" mode="danger">Login</LinkedButton></P>
                        </Container>
                </form>
            </Container>
    </Container>
    )
}

export default Recover