import React from 'react'
import Box from '@mui/material/Box';
import { AppColors } from '../AppColors';
import {Container,H1,CustomButton, Image,P, PaperBox} from '../index';
import CircleIcon from '@mui/icons-material/Circle';
import Images from '../images';
import { Avatar } from '@mui/material';
import styles from './styles'
import { RIDER_DETAILS } from '../../actions/actionTypes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const RiderDetails = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onClickHandler = () => {
        navigate('/user/rider-page');
    }

    return(
        <Box
            sx={{ width: 600}}
            role="presentation"
        >
            <PaperBox padding={"2rem"}>
                <Container width={"50%"}
                    margin={"1px auto"}
                >
                    <Avatar 
                        src={Images.user}
                        sx={styles.avatar}
                    />
                    <H1 textAlign={"center"} fontSize={"1.349rem"}>Ahmed Bola</H1>
                    <P textAlign={"center"} fontSize={"1.068rem"}>Bikee Rider 26</P>
                    <P textAlign={"center"} fontSize={"1.05rem"}>08176253625</P>
                </Container>
                <Container borderTop={`1px solid ${AppColors.line}`}
                    marginTop={"1rem"}
                    marginBottom={"2rem"}
                >
                    {
                        [
                            {
                                icon : Images.AtIcon,
                                title : "bukkahut@bukkahut.com"
                            },
                            {
                                icon : Images.Phone,
                                title : "09078651628"
                            },
                            {
                                icon : Images.mappin,
                                title : "18b Sunday Adigun Street, Yaba,Lagos, Nigeria."
                            }
                        ].map((item,index)=><Container key={index} display={"flex"}
                            alignItems={"center"}
                            marginTop={"0.8rem"}
                        >
                            <Image 
                                backgroundimage={item.icon}
                                width={"1rem"}
                                height={"1rem"}
                            />
                            <P fontSize={"0.802rem"} marginLeft={"1rem"}>{item.title}</P>
                        </Container>)
                    }
                </Container>
                <CustomButton background={AppColors.greenBackground} width={"7rem"} onClick={onClickHandler}>
                    <H1 color={AppColors.lime} fontSize={"0.707rem"}>View Profile</H1>
                </CustomButton>
                <Container padding="1rem" margin={"1.5rem 0"} border={`1px solid ${AppColors.line}`}
                    borderRadius={"0.539rem"}
                >
                        <H1 marginBottom={"1rem"} fontSize={"0.99rem"}>KPI Performance till date</H1>
                        <Container
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            display={"flex"}
                        >
                            <Container>
                                {
                                    [
                                        {
                                            color : AppColors.buttonYellow,
                                            title : "Pick-up",
                                            value : 3000
                                        },
                                        {
                                            color : AppColors.lime,
                                            title : 'Delivered',
                                            value : 26947
                                        },
                                        {
                                            color : AppColors.red,
                                            title : 'Canceled',
                                            value : 2
                                        }
                                    ].map((item,index)=><Container key={index} marginBottom={"1.5rem"}>
                                        <Container container
                                            flexDirection={"row"}
                                            alignItems={"center"}
                                        >
                                            <CircleIcon sx={{
                                                color : item.color,
                                                marginRight : "1rem",
                                                fontSize : "0.8rem"
                                            }} />
                                            <P fontSize={"0.77rem"} color={AppColors.textGrey}>{item.title}</P>
                                        </Container>
                                        <H1 fontSize={"0.99rem"} marginTop={"0.3rem"}>{item.value}</H1>
                                    </Container>)
                                }
                            </Container>
                            <Image 
                                backgroundimage={Images.Performance}
                                width={"25rem"}
                                height={"10rem"}
                                backgroundsize={"contain"}
                            />
                        </Container>
                    </Container>
            </PaperBox>
        </Box>
    )
}