import React, { useRef, useState } from  "react"
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Dialog, DialogActions, DialogContent, Slide } from "@mui/material";
import { CustomButton } from "..";

const Transition = React.forwardRef(function Transition(props,ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ReactCropper = (props)=>{
    const cropperRef = useRef(null);
    const [file,setFile] = useState(null)
    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setFile(cropper.getCroppedCanvas().toDataURL())
    };
    return (
        <Dialog
            open={props.open} //A piece of state that tells us if the modal is open or not
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>props.setOpen(false)} //A function that handles what happen when you open the modal
            aria-labelledby="alert-dialog-slide-title-form-modal"
            aria-describedby="alert-dialog-slide-description-another-description"
        >
            <div className="text-right p-3 close-modal" onClick={()=>{
                props.setOpen(false)
            }}>
                <span><i className="fa fa-close"></i></span>
            </div>
            <DialogContent>
                <Cropper
                    src={props.image}
                    style={{ height: 400, width: "auto",borderRadius : "50%" }}
                    // Cropper.js options
                    initialAspectRatio={16 / 16}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                    zoomable={false}
                    cropBoxResizable={false}
                />
            </DialogContent>
            <DialogActions>
                    <CustomButton onClick={()=>props.updateProfileImage(file)} variant="contained"
                        mode="danger">
                    Crop
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default ReactCropper