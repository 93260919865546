import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name : "user",
    initialState : {
        user : {}
    },
    reducers : {
        changeUserHandler : (state,action) => {
            return action.payload
        }
    }
})

export const {changeUserHandler} = userSlice.actions;
export default userSlice.reducer;