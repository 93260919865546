import * as React from 'react';
import { Container, CustomButton, H1, P } from './index';
import { AppColors } from './AppColors';
import Pagination from '@mui/material/Pagination';
import {
  DataGrid
} from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

export function CustomPagination(props) {
  return (
    <Pagination
      color="primary"
      sx={{
        '.Mui-selected' : {
          backgroundColor : `${AppColors.red} !important`,
          '&:hover,&:focus' : {
            backgroundColor : `${AppColors.red} !important`,
          }
        }
      }}
      count={props.pageCount}
      page={props.page}
      onChange={(event, value) => props.onPageChange(value)}
      shape="rounded"
    />
  );
}

export default function DataGridDemo(props) {
  return (
    <div style={{ 
    height: 850, 
    width: '100%' 
  }}>
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        pageSize={15}
        rowsPerPageOptions={[10]}
        disableColumnFilter
        disableColumnMenu
        rowHeight={80}
        showColumnRightBorder={false}
        columnThreshold={8}
        disableSelectionOnClick
        disableColumnSelector
        hideFooter={props.hideFooter}
        headerHeight={80}
        components={{
          Toolbar: props.Toolbar,
          Pagination: !props.loading ? props.CustomPagination : null,
          NoRowsOverlay : ()=><Container display={"flex"} height={"100%"}
            justifyContent={"center"}
          ><H1 fontSize={"2rem"} color={AppColors.grayBorder}>No record found</H1></Container>,
          LoadingOverlay: ()=> <LinearProgress color={"error"} />
        }}
        sx={{
          border : 'none',
          '.MuiDataGrid-cell--textLeft:focus' : {
            border : 'none'
          },
          '.MuiDataGrid-panelHeader' : {
            backgroundColor : "red"
          },
          '.MuiDataGrid-main' : {
            borderTop : `0.1rem solid ${AppColors.line}`
          },
          '.MuiDataGrid-columnHeaderTitleContainer' : {
            border : 'none',
            '&:focus' : {
              border : 'none'
            }
          },
          '.MuiDataGrid-columnHeader' : {
            outline : 'none',
            '&:focus' : {
              outline : 'none',
            }
          },
          '.MuiDataGrid-cell' : {
            outline : 'none',
            '&:focus' : {
              outline : 'none',
            }
          }
        }}
        {...props}
      />
    </div>
  );
}
