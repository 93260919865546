import { AppColors } from "../../../../components/AppColors";

const styles = {
    arrowUP : {
        color : AppColors.green,
        fontSize : "1rem"
    },
    backButton : {
        backgroundColor : AppColors.offWhite,
        marginRight : "1rem",
        marginBottom : "2rem"
    },
}

export default styles;