import { AppColors } from "../AppColors";

const styles = {
    timelinecontainer : {
        padding : "6px 0px",
        '.MuiTimelineItem-root:before' : {
            display : 'none !important'
        }
    },
    timelineconnector : {backgroundColor : AppColors.line}
}

export default styles;