import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name : "order",
    initialState : {
        order : {}
    },
    reducers : {
        changeOrderHandler : (state,action) => {
            return action.payload
        }
    }
})

export const {changeOrderHandler} = orderSlice.actions;
export default orderSlice.reducer;