import Activity from "../images/activity.png"
import RedActivity from "../images/red-activity.png";
import Wallet from "../images/wallet.png";
import Setting from "../images/setting.png";
import RedSetting from "../images/red-setting.png";
import RedWallet from "../images/red-wallet.png";
import Question from "../images/question-circle.png";
import Profile from "../images/profile.png";
import ActiveProfile from "../images/active-profile.png";
import Security from "../images/security.png";
import ActiveSecurity from "../images/active-security.png";
import Purse from "../images/purse.png";
import Card from "../images/card.png";
import Padlock from "../images/padlock.png";
import Phone from "../images/phone.png";
import Envelope from "../images/envelope.png";
import AddFund from "../images/add-fund.png";
import Transfer from "../images/transfer.png";
import Payment from "../images/payment.png";
import Paystack from "../images/paystack.png";
import Paypal from "../images/paypal.png";
import Quickteller from "../images/quickteller.png";
import Bike from "../images/bike.png";
import YellowLine from "../images/yellow-line.png";
import RedLine from "../images/red-line.png";
import Ellipse from "../images/ellipse.png";
import { AppColors } from "./AppColors";

export const TabList = [
    {
        icon : Activity,
        name : "Dashboard",
        route : "dashboard",
        active : RedActivity,
        comingsoon : false
    },
    {
        icon : Wallet,
        name : "Financials",
        route : "financial",
        active : RedWallet,
        comingsoon : false,
        has_menu : true,
        MENU_LIST : [
            {
                icon : Ellipse,
                name : "Bikee Wallet",
                route : "bikee-wallet",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Ellipse,
                name : "Client's Wallet",
                route : "client-wallet",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Ellipse,
                name : "Client's Invoice",
                route : "client-invoice",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Ellipse,
                name : "Cash on delivery",
                route : "cash-delivery",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            }
        ]
    },
    {
        icon : Wallet,
        name : "Areas of Ops",
        route : "operation-area",
        active : RedWallet,
        comingsoon : false,
        has_menu : true,
        MENU_LIST : [
            {
                icon : Ellipse,
                name : "Create Area",
                route : "create-area",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Ellipse,
                name : "List Areas",
                route : "list-areas",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
        ]
    },
    {
        icon : Activity,
        name : "Clients",
        route : "client-list",
        active : RedActivity
    },
    {
        icon : Activity,
        name : "Create Country",
        route : "create-country",
        active : RedActivity
    },
    {
        icon : Activity,
        name : "Teams",
        route : "teams",
        active : RedActivity,
        comingsoon : false,
        has_menu : true,
        MENU_LIST : [
            {
                icon : Ellipse,
                name : "Team members",
                route : "team-list",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Ellipse,
                name : "Riders",
                route : "rider-list",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
        ]
    },
    {
        icon : Activity,
        name : "Create Industry",
        route : "create-industry",
        active : RedActivity
    },
    {
        icon : Wallet,
        name : "Delivery prices",
        route : "delivery-prices",
        active : RedWallet,
        comingsoon : false,
        has_menu : true,
        MENU_LIST : [
            {
                icon : Ellipse,
                name : "Price Per KM",
                route : "km-price",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Ellipse,
                name : "Price Per Volume",
                route : "volume-price",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
        ]
    },
    {
        icon : Setting,
        name : "Settings",
        route : "settings",
        active : RedSetting,
        comingsoon : false,
        has_menu : true,
        MENU_LIST : [
            // {
            //     icon : Ellipse,
            //     name : "Create Subs Fees",
            //     route : "create-sub-fee",
            //     active : Ellipse,
            //     comingsoon : false,
            //     sub_menu : true
            // },
            {
                icon : Ellipse,
                name : "POS Details",
                route : "pos-details",
                active : Ellipse,
                comingsoon : false,
                sub_menu : true
            },
        ]
    },
    
]

export const SettingsTabList = [
    {
        title : "Business Info",
        description : "About your business data",
        icon : Profile,
        active : ActiveProfile,
        comingsoon : false
    },
    {
        title : "Security",
        description : "Manage your account security",
        icon : Security,
        active : ActiveSecurity,
        comingsoon : false
    },
    {
        title : "Financials",
        description : "Manage your wallet account",
        icon : Purse,
        active : Purse,
        comingsoon : true
    },
    {
        title : "Notification",
        description : "Information regarding payment method",
        icon : Card,
        active : Card,
        comingsoon : true
    }
]

export const SECURTY_TAB_LIST = [
    {
        icon : Padlock,
        title : "*********",
        description : "You can reset your password",
        menu : "RESET_PASSWORD"
    },
    {
        icon : Envelope,
        menu : "RESET_EMAIL",
        title : "marianade@chickenrepublic.com",
        description : "Keep your email up-to-date"
    },
    {
        icon : Phone,
        title : "xxxxxxxxx22",
        menu : "RESET_PHONE",
        description : "Change phone number to receive notification"
    }
]

export const WALLET_MENU_LIST = [
    {
        title : "Fund wallet",
        icon : AddFund,
        comingsoon : false
    },
    {
        title : "Transfer Balance",
        icon : Transfer,
        comingsoon : true
    },
    {
        title : "Make Payment",
        icon : Payment,
        comingsoon : true
    }
]

export const WALLET_CARD_LIST = [
    {
        balance : "N 20,150",
        title : "Wallet Balance",
        description : "20% Increase from Last Week",
        background : AppColors.yellow
    },
    {
        balance : "N 16,000",
        title : "Delivery fee to be paid",
        description : "15% Increase from Last Month",
        background : AppColors.blue
    },
    {
        balance : "100,000",
        title : "Cash on Delivery",
        description : "5% Increase from Last Week",
        background : AppColors.lightGreen
    }
]

export const DASHBOARD_TRANSACTION_LIST = [
    {
        sn : 1,
        id : 1,
        orderID : "#2E2C34",
        rider : "Festus Oyeleke Omotayo",
        client : "Bukahut Ajah",
        status : "In-transit",
        pickup_address : "70, Ojo Alaba, Egbede, Obutemeta Close",
        dropoff_address : "24B, Ojo Alaba, Egbede, Obutemeta Close",
        recipient : "Akin Olaoluwach Olawunmi",
        payment_type : "COD",
        amount : "N15,690,000" 
    },
    {
        sn : 2,
        id : 2,
        orderID : "#2E2C34",
        rider : "Festus Oyeleke Omotayo",
        client : "Bukahut Ajah",
        status : "In-transit",
        pickup_address : "70, Ojo Alaba, Egbede, Obutemeta Close",
        dropoff_address : "24B, Ojo Alaba, Egbede, Obutemeta Close",
        recipient : "Akin Olaoluwach Olawunmi",
        payment_type : "COD",
        amount : "N15,690,000" 
    },
    {
        sn : 3,
        id : 3,
        orderID : "#2E2C34",
        rider : "Festus Oyeleke Omotayo",
        client : "Chicken Republic Ogbeda",
        status : "Delivered",
        pickup_address : "70, Ojo Alaba, Egbede, Obutemeta Close",
        dropoff_address : "24B, Ojo Alaba, Egbede, Obutemeta Close",
        recipient : "Akin Olaoluwach Olawunmi",
        payment_type : "COD",
        amount : "N15,690,000" 
    },
    {
        sn : 4,
        id : 4,
        orderID : "#2E2C34",
        rider : "Festus Oyeleke Omotayo",
        client : "Bukahut Ajah",
        status : "In-transit",
        pickup_address : "70, Ojo Alaba, Egbede, Obutemeta Close",
        dropoff_address : "24B, Ojo Alaba, Egbede, Obutemeta Close",
        recipient : "Akin Olaoluwach Olawunmi",
        payment_type : "COD",
        amount : "N15,690,000" 
    },
    {
        sn : 5,
        id : 5,
        orderID : "#2E2C34",
        rider : "Kunle Femisayo",
        client : "Bukahut Ajah",
        status : "In-transit",
        pickup_address : "70, Ojo Alaba, Egbede, Obutemeta Close",
        dropoff_address : "24B, Ojo Alaba, Egbede, Obutemeta Close",
        recipient : "Akin Olaoluwach Olawunmi",
        payment_type : "COD",
        amount : "N15,690,000" 
    },
    {
        sn : 6,
        id : 6,
        orderID : "#2E2C34",
        rider : "Festus Oyeleke Omotayo",
        client : "Chicken Republic Ogbeda",
        status : "Delivered",
        pickup_address : "70, Ojo Alaba, Egbede, Obutemeta Close",
        dropoff_address : "24B, Ojo Alaba, Egbede, Obutemeta Close",
        recipient : "Akin Olaoluwach Olawunmi",
        payment_type : "COD",
        amount : "N15,690,000" 
    }
    
]

export const DASHBOARD_CARD_LIST = [
    {
        balance : "N 16,000",
        title : "Cash on delivery",
        description : "15% Increase from Last Month",
        background : AppColors.blue
    },
    {
        balance : "100,000",
        title : "Transfer on delivery",
        description : "5% Increase from Last Week",
        background : AppColors.lightGreen
    }
]

export const LATEST_ORDERS_LIST = [
    {
        id : 1,
        orderID : "#37300478",
        customer : "Akinwale Oladimeji",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'James Owolabi',
        fee : 'N900',
        status : 'Pending'
    },
    {
        id : 2,
        orderID : "#373037478",
        customer : "Femi Owowale",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'Mike Jackson',
        fee : 'N1000',
        status : 'Pending'
    },
    {
        id : 3,
        orderID : "#37300478",
        customer : "Akinwale Oladimeji",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'James Owolabi',
        fee : 'N900',
        status : 'Pending'
    },
    {
        id : 4,
        orderID : "#373037478",
        customer : "Femi Owowale",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'Mike Jackson',
        fee : 'N100',
        status : 'In-Transit'
    },
    {
        id : 5,
        orderID : "#37300478",
        customer : "Akinwale Oladimeji",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'James Owolabi',
        fee : 'N900',
        status : 'In-Transit'
    },
    {
        id : 6,
        orderID : "#373037478",
        customer : "Femi Owowale",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'Mike Jackson',
        fee : 'N1500',
        status : 'Delivered'
    }
]

export const TRANSACTION_LIST =  [
    {
        id : 1,
        orderID : "#49428895",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 2,
        orderID : "#49783495",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Wale Rabiu",
        status : "Paid"
    },
    {
        id : 3,
        orderID : "#494467",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Unpaid"
    },
    {
        id : 4,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    },
    {
        id : 5,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    },
    {
        id : 6,
        orderID : "#49428895",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 7,
        orderID : "#49783495",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Wale Rabiu",
        status : "Paid"
    },
    {
        id : 8,
        orderID : "#494467",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Unpaid"
    },
    {
        id : 9,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    },
    {
        id : 10,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    }
]

export const USER_CATEGORY_LIST = [
    "Individual",
    "Business"
]

export const INDUSTRY_LIST = [
    "Restaurant","Catering","Lounge/Bar","Flowers/Gifts"
]

export const PAYMENT_METHOD_LIST = [
    {
        icon : Paystack,
        title : 'Paystack',
        description : 'Pay with Paystack'
    },
    {
        icon : Paypal,
        title : 'Paypal',
        description : 'Pay with Paypal'
    },
    {
        icon : Quickteller,
        title : 'Quickteller',
        description : 'Pay with Quickteller'
    }
]

export const ALL_TRANSACTION_LIST =  [
    {
        id : 1,
        orderID : "#494288934",
        address : "8, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Femi Ogunjode",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 2,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 3,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },{
        id : 4,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Ikeja",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 5,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Alimosho",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 6,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Ogundele",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 8,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 9,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 10,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 11,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 12,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    }
]

// export const BANK_LIST = [
//     {
//         id : 1,
//         sn : 1,
//         bank_name : "Wema Bank",
//         acc_number : "743892782",
//         date : "10-01-2012",
//     },
//     {
//         id : 2,
//         sn : 2,
//         bank_name : "Wema Bank",
//         acc_number : "726893892782",
//         date : "10-01-2012",
//     },
//     {
//         id : 3,
//         sn : 3,
//         bank_name : "Guaranty Trust Bank",
//         acc_number : "743892738882",
//         date : "10-01-2012",
//     },
//     {
//         id : 4,
//         sn : 4,
//         bank_name : "First Bank",
//         acc_number : "743892787382",
//         date : "10-01-2012",
//     },
//     {
//         id : 5,
//         sn : 5,
//         bank_name : "Wema Bank",
//         acc_number : "743892782",
//         date : "10-01-2012",
//     },
//     {
//         id : 6,
//         sn : 6,
//         bank_name : "First City Monument Bank",
//         acc_number : "743892782",
//         date : "10-01-2012",
//     }
// ]
export const COUNTRY_LIST = [
    {
        id : 1,
        sn : 1,
        country_name : "Nigeria",
        date : "10-01-2012",
    },
    {
        id : 2,
        sn : 2,
        country_name : "Ghana",
        date : "10-01-2012",
    },
    {
        id : 3,
        sn : 3,
        country_name : "Togo",
        date : "10-01-2012",
    },
    {
        id : 4,
        sn : 4,
        country_name : "Australia",
        date : "10-01-2012",
    },
    {
        id : 5,
        sn : 5,
        country_name : "United States of America",
        date : "10-01-2012",
    },
]

export const STATE_LIST = [
    {
        id : 1,
        sn : 1,
        country_name : "Lagos",
        date : "10-01-2012",
    },
    {
        id : 2,
        sn : 2,
        country_name : "Ekiti",
        date : "10-01-2012",
    },
    {
        id : 3,
        sn : 3,
        country_name : "Togo",
        date : "10-01-2012",
    },
    {
        id : 4,
        sn : 4,
        country_name : "Australia",
        date : "10-01-2012",
    },
    {
        id : 5,
        sn : 5,
        country_name : "United States of America",
        date : "10-01-2012",
    },
]

export const AREA_LIST = [
    {
        id : 1,
        sn : 1,
        state : "Lagos",
        lcda : "Owo",
        area : "Agege",
        date : "10-01-2012",
    },
    {
        id : 2,
        sn : 2,
        state : "Ekiti",
        lcda : "Owo",
        area : "Agege",
        date : "10-01-2012",
    },
    {
        id : 3,
        sn : 3,
        state : "Ekiti",
        lcda : "Owo",
        area : "Agege",
        date : "10-01-2012",
    },
    {
        id : 4,
        sn : 4,
        state : "Ogun",
        lcda : "Owo",
        area : "Agege",
        date : "10-01-2012",
    },
    {
        id : 5,
        sn : 5,
        state : "Ekiti",
        lcda : "Owo",
        area : "Agege",
        date : "10-01-2012",
    },
]
export const SUB_FEE_LIST = [
    {
        id : 1,
        sn : 1,
        user_type : "Restaurant",
        business_name : "BukkaHut",
        gig_worker : "Felix Okoro",
        sub_type : "Monthly",
        sub_fee : "NGN25,000",
        date : "10/05/2022"
    },
    {
        id : 2,
        sn : 2,
        user_type : "Restaurant",
        business_name : "Chicken Republic",
        gig_worker : "Felix Okoro",
        sub_type : "Weekly",
        sub_fee : "NGN100,000",
        date : "10/05/2022"
    },
    {
        id : 3,
        sn : 3,
        user_type : "Restaurant",
        business_name : "Chicken Republic",
        gig_worker : "Felix Okoro",
        sub_type : "Weekly",
        sub_fee : "NGN100,000",
        date : "10/05/2022"
    },
    {
        id : 4,
        sn : 4,
        user_type : "Restaurant",
        business_name : "BukkaHut",
        gig_worker : "Felix Okoro",
        sub_type : "Monthly",
        sub_fee : "NGN25,000",
        date : "10/05/2022"
    },
    {
        id : 5,
        sn : 5,
        user_type : "Restaurant",
        business_name : "Chicken Republic",
        gig_worker : "Felix Okoro",
        sub_type : "Weekly",
        sub_fee : "NGN100,000",
        date : "10/05/2022"
    },
    {
        id : 6,
        sn : 6,
        user_type : "Restaurant",
        business_name : "Chicken Republic",
        gig_worker : "Felix Okoro",
        sub_type : "Weekly",
        sub_fee : "NGN100,000",
        date : "10/05/2022"
    }
]

export const ALL_CUSTOMERS_LIST =  [
    {
        id : 1,
        customerID : "#494288934",
        customerName : "Karimu Oluwatolumide",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Lekki South"
    },
    {
        id : 2,
        customerID : "#494288934",
        customerName : "Ojo Oladimeji",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Owo South"
    },
    {
        id : 3,
        customerID : "#4942808934",
        customerName : "Femi Olawale",
        address : "8, Owoniyi Adsina Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Owo South"
    },
    {
        id : 4,
        customerID : "#49674288934",
        customerName : "Ojo Oladimeji",
        address : "8, Owoniyi Mike Owo Street, Mike Owo",
        phone : "08165267876",
        status : "In-Transit",
        lcda : "Owo South"
    },
    {
        id : 5,
        customerID : "#49478282434",
        customerName : "Femi Oladimeji",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Pending",
        lcda : "Owo South"
    },
    {
        id : 6,
        customerID : "#49423288934",
        customerName : "Ojo Oladimeji",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Owo South"
    }
]

export const CUSTOMER_ORDER_LIST =  [
    {
        id : 1,
        orderID : "#494288934",
        date : "Jan 12, 2022",
        amount : "N3,250",
        type : "COD",
        status : "Delivered"
    }
]

export const DASHBOARD_STATS = [
    {
        title : 'TOTAL DISTANCE COVERED',
        value : '25 km',
        description : '1.10% Since yesterday',
        icon : RedLine,
        arrow : true
    },
    {
        title : 'CO2 AVOIDED',
        value : '800kg',
        description : '1.10% Since yesterday',
        icon : YellowLine,
        arrow : true
    },
    {
        title : 'TOTAL DELIVERIES',
        value : '25',
        description : 'Total deliveries till date',
        icon : Bike,
        arrow : false
    },
    {
        title : 'ASSIGNED DELIVERIES',
        value : '234',
        description : 'Since yesterday',
        icon : Bike,
        arrow : false
    }
]

export const INDUSTRY_CATEGORY_LIST = [
    {
        id : 1,
        sn : 1,
        category : "Business",
        industry : "Catering",
        date : "10/11/2022"
    },
    {
        id : 2,
        sn : 2,
        category : "Individual",
        industry : "Fashion",
        date : "10/11/2022"
    },
    {
        id : 3,
        sn : 3,
        category : "Individual",
        industry : "Fashion",
        date : "10/11/2022"
    },
    {
        id : 4,
        sn : 4,
        category : "Business",
        industry : "Catering",
        date : "10/11/2022"
    },
    {
        id : 5,
        sn : 5,
        category : "Individual",
        industry : "Fashion",
        date : "10/11/2022"
    },
    {
        id : 6,
        sn : 6,
        category : "Individual",
        industry : "Fashion",
        date : "10/11/2022"
    }
]

export const KM_PRICE_LIST = [
    {
        id : 1,
        business_name : "Chicken Republic",
        outlet : "Owolowo Way",
        currency : "NGN",
        off_peak : "500",
        peak : "700",
        volume : 30,
        date : "25/11/2022"
    },
    {
        id : 2,
        business_name : "Chicken Republic",
        outlet : "Ojodu Abule Egba",
        currency : "NGN",
        off_peak : "500",
        peak : "700",
        volume : 50,
        date : "25/11/2022"
    },
    {
        id : 3,
        business_name : "Bukkahut",
        outlet : "Owolowo Way",
        currency : "GBP",
        off_peak : "500",
        volume : 70,
        peak : "700",
        date : "25/11/2022"
    },
    {
        id : 4,
        business_name : "Chicken Republic",
        outlet : "Owolowo Way",
        currency : "NGN",
        off_peak : "500",
        peak : "700",
        volume : 3,
        date : "25/11/2022"
    },
    {
        id : 5,
        business_name : "Chicken Republic",
        outlet : "Ojodu Abule Egba",
        currency : "NGN",
        off_peak : "500",
        peak : "700",
        volume : 100,
        date : "25/11/2022"
    },
    {
        id : 6,
        business_name : "Bukkahut",
        outlet : "Owolowo Way",
        currency : "GBP",
        off_peak : "500",
        peak : "700",
        volume : 40,
        date : "25/11/2022"
    },
    {
        id : 7,
        business_name : "Chicken Republic",
        outlet : "Owolowo Way",
        currency : "NGN",
        off_peak : "500",
        peak : "700",
        volume : 20,
        date : "25/11/2022"
    },
    {
        id : 8,
        business_name : "Chicken Republic",
        outlet : "Ojodu Abule Egba",
        currency : "NGN",
        off_peak : "500",
        peak : "700",
        volume : 45,
        date : "25/11/2022"
    },
    {
        id : 9,
        business_name : "Bukkahut",
        outlet : "Owolowo Way",
        currency : "GBP",
        off_peak : "500",
        volume : 25,
        peak : "700",
        date : "25/11/2022"
    }
]

export const CLIENT_LIST = [
    {
        id : 1,
        business_name : "Bukkahat",
        email : "Bukkahat@email.com",
        phone : "+2344994783774"
    },
    {
        id : 2,
        business_name : "Joke Ayo",
        email : "ayoni@mailer.com",
        phone : "+2344994783774"
    },
    {
        id : 3,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        phone : "+2344994783774"
    },
    {
        id : 4,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        phone : "+2344994783774"
    },
    {
        id : 5,
        business_name : "Bukkahat",
        email : "Bukkahat@email.com",
        phone : "+2344994783774"
    },
    {
        id : 6,
        business_name : "Joke Ayo",
        email : "ayoni@mailer.com",
        phone : "+2344994783774"
    },
    {
        id : 7,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        phone : "+2344994783774"
    },
    {
        id : 8,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        phone : "+2344994783774"
    }
]

export const MEMBER_LIST = [
    {
        id : 1,
        business_name : "Bukkahat",
        email : "Bukkahat@email.com",
        type : "Admin",
        date : "11/01/2022",
        phone : "+2344994783774",
        date : "12/04/2022",
    },
    {
        id : 2,
        business_name : "Joke Ayo",
        email : "ayoni@mailer.com",
        date : "05/02/2022",
        type : "Admin",
        phone : "+2344994783774",
        date : "12/04/2022",
    },
    {
        id : 3,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        type : "Admin",
        date : "05/02/2022",
        phone : "+2344994783774",
        date : "12/04/2022"
    },
    {
        id : 4,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        type : "Rider",
        date : "05/02/2022",
        phone : "+2344994783774",
        date : "12/04/2022"
    },
    {
        id : 5,
        business_name : "Bukkahat",
        email : "Bukkahat@email.com",
        date : "05/02/2022",
        type : "Rider",
        phone : "+2344994783774",
        date : "11/02/2022"
    },
    {
        id : 6,
        business_name : "Joke Ayo",
        email : "ayoni@mailer.com",
        type : "Admin",
        phone : "+2344994783774",
        date : "01/02/2022"
    },
    {
        id : 7,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        type : "Rider",
        date : "01/02/2022",
        phone : "+2344994783774"
    },
    {
        id : 8,
        business_name : "Kemishola",
        email : "kemiola@mailer.com",
        phone : "+2344994783774",
        date : "01/02/2022",
        type : "Admin",
    }
]

export const RIDER_LIST = [
    {
        id : 1,
        name : "Bukkahat",
        email : "Bukkahat@email.com",
        state : "Lagos",
        lcda : "Agege",
        date : "11/01/2022",
        phone : "+2344994783774",
    },
    {
        id : 2,
        name : "Femi Otedola",
        email : "omoodede_told@email.com",
        state : "Ogun",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+234949994783774",
    },
    {
        id : 3,
        name : "Gbenga Daniels",
        email : "gbenda_daniels@email.com",
        state : "Oyo",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+2344994983774",
    },
    {
        id : 4,
        name : "Bukkahat",
        email : "Bukkahat@email.com",
        state : "Lagos",
        lcda : "Agege",
        date : "11/01/2022",
        phone : "+2344994783774",
    },
    {
        id : 5,
        name : "Femi Otedola",
        email : "omoodede_told@email.com",
        state : "Ogun",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+234949994783774",
    },
    {
        id : 6,
        name : "Gbenga Daniels",
        email : "gbenda_daniels@email.com",
        state : "Oyo",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+2344994983774",
    },
    {
        id : 7,
        name : "Bukkahat",
        email : "Bukkahat@email.com",
        state : "Lagos",
        lcda : "Agege",
        date : "11/01/2022",
        phone : "+2344994783774",
    },
    {
        id : 8,
        name : "Femi Otedola",
        email : "omoodede_told@email.com",
        state : "Ogun",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+234949994783774",
    },
    {
        id : 9,
        name : "Gbenga Daniels",
        email : "gbenda_daniels@email.com",
        state : "Oyo",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+2344994983774",
    },
    {
        id : 10,
        name : "Bukkahat",
        email : "Bukkahat@email.com",
        state : "Lagos",
        lcda : "Agege",
        date : "11/01/2022",
        phone : "+2344994783774",
    },
    {
        id : 11,
        name : "Femi Otedola",
        email : "omoodede_told@email.com",
        state : "Ogun",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+234949994783774",
    },
    {
        id : 12,
        name : "Gbenga Daniels",
        email : "gbenda_daniels@email.com",
        state : "Oyo",
        lcda : "Ade-odo",
        date : "11/01/2022",
        phone : "+2344994983774",
    },
]

export const ORDER_HISTORY = [
    {
        id : 1,
        orderID : "#38949034",
        recipient : "Mikel Obi",
        destination : "5, Ebute meta, Agbado, Lagos",
        rider : "Femi Olatunji",
        payment_type : "COD",
        date : "12/12/2022",
        status : "Paid",
    },
    {
        id : 2,
        orderID : "#38949034",
        recipient : "John Obi Mikel",
        destination : "83B, Ebute meta, Agbado, Lagos",
        rider : "Femi Olatunji",
        payment_type : "COD",
        date : "12/12/2022",
        status : "Paid",
    },
    {
        id : 3,
        orderID : "#38949034",
        recipient : "Femi Olatunji",
        destination : "83B, Ebute meta, Agbado, Lagos",
        rider : "Wale Adenle",
        payment_type : "COD",
        date : "12/12/2022",
        status : "Unpaid",
    },
    {
        id : 4,
        orderID : "#38949034",
        recipient : "Mikel Obi",
        destination : "5, Ebute meta, Agbado, Lagos",
        rider : "Femi Olatunji",
        payment_type : "COD",
        date : "12/12/2022",
        status : "Unpaid",
    },
    {
        id : 5,
        orderID : "#38949034",
        recipient : "John Obi Mikel",
        destination : "83B, Ebute meta, Agbado, Lagos",
        rider : "Femi Olatunji",
        payment_type : "COD",
        date : "12/12/2022",
        status : "Unpaid",
    },
    {
        id : 6,
        orderID : "#38949034",
        recipient : "Femi Olatunji",
        destination : "83B, Ebute meta, Agbado, Lagos",
        rider : "Wale Adenle",
        payment_type : "COD",
        date : "12/12/2022",
        status : "Paid",
    }
]

export const RIDE_HISTORY = [
    {
        id : 1,
        orderID : "#3783036",
        sn : 1,
        area : "Eti-Osa",
        pickup : "Bukkahut Ajah",
        dropoff : "18b Sunday Adigun Street, Yaba, Lagos, Nigeria.",
        lcda : "Eti-Osa",
        imei : "7565657687980",
        timespent : "1:09:04"
    },
    {
        id : 2,
        orderID : "#3783036",
        sn : 2,
        area : "Eti-Osa",
        pickup : "Bukkahut Ajah",
        dropoff : "18b Sunday Adigun Street, Yaba, Lagos, Nigeria.",
        lcda : "Eti-Osa",
        imei : "7565657687980",
        timespent : "1:09:04"
    },
    {
        id : 3,
        orderID : "#3783036",
        sn : 3,
        area : "Eti-Osa",
        pickup : "Bukkahut Ajah",
        dropoff : "18b Sunday Adigun Street, Yaba, Lagos, Nigeria.",
        lcda : "Eti-Osa",
        imei : "7565657687980",
        timespent : "1:09:04"
    },
    {
        id : 4,
        orderID : "#3783036",
        sn : 4,
        area : "Eti-Osa",
        pickup : "Bukkahut Ajah",
        dropoff : "18b Sunday Adigun Street, Yaba, Lagos, Nigeria.",
        lcda : "Eti-Osa",
        imei : "7565657687980",
        timespent : "1:09:04"
    },
    {
        id : 5,
        orderID : "#3783036",
        sn : 5,
        area : "Eti-Osa",
        pickup : "Bukkahut Ajah",
        dropoff : "18b Sunday Adigun Street, Yaba, Lagos, Nigeria.",
        lcda : "Eti-Osa",
        imei : "7565657687980",
        timespent : "1:09:04"
    },
    {
        id : 6,
        orderID : "#3783036",
        sn : 6,
        area : "Eti-Osa",
        pickup : "Bukkahut Ajah",
        dropoff : "18b Sunday Adigun Street, Yaba, Lagos, Nigeria.",
        lcda : "Eti-Osa",
        imei : "7565657687980",
        timespent : "1:09:04"
    }
]

export const BANK_LIST = [
    {
        "name": "Access Bank",
        "code": "044"
    },
    {
        "name": "Citi Bank",
        "code": "023"
    },
    {
        "name": "EcoBank PLC",
        "code": "050"
    },
    {
        "name": "First Bank PLC",
        "code": "011"
    },
    {
        "name": "First City Monument Bank",
        "code": "214"
    },
    {
        "name": "Fidelity Bank",
        "code": "070"
    },
    {
        "name": "Guaranty Trust Bank",
        "code": "058"
    },
    {
        "name": "Polaris bank",
        "code": "076"
    },
    {
        "name": "Stanbic IBTC Bank",
        "code": "221"
    },
    {
        "name": "Standard Chaterted bank PLC",
        "code": "068"
    },
    {
        "name": "Sterling Bank PLC",
        "code": "232"
    },
    {
        "name": "United Bank for Africa",
        "code": "033"
    },
    {
        "name": "Union Bank PLC",
        "code": "032"
    },
    {
        "name": "Wema Bank PLC",
        "code": "035"
    },
    {
        "name": "Zenith bank PLC",
        "code": "057"
    },
    {
        "name": "Unity Bank PLC",
        "code": "215"
    },
    {
        "name": "ProvidusBank PLC",
        "code": "101"
    },
    {
        "name": "Keystone Bank",
        "code": "082"
    },
    {
        "name": "Jaiz Bank",
        "code": "301"
    },
    {
        "name": "Heritage Bank",
        "code": "030"
    },
    {
        "name": "Suntrust Bank",
        "code": "100"
    },
    {
        "name": "FINATRUST MICROFINANCE BANK",
        "code": "608"
    },
    {
        "name": "Rubies Microfinance Bank",
        "code": "090175"
    },
    {
        "name": "Kuda",
        "code": "090267"
    },
    {
        "name": "TCF MFB",
        "code": "090115"
    },
    {
        "name": "FSDH Merchant Bank",
        "code": "400001"
    },
    {
        "name": "Rand merchant Bank",
        "code": "502"
    },
    {
        "name": "Globus Bank",
        "code": "103"
    },
    {
        "name": "Paga",
        "code": "327"
    },
    {
        "name": "Taj Bank Limited",
        "code": "000026"
    },
    {
        "name": "GoMoney",
        "code": "100022"
    },
    {
        "name": "AMJU Unique Microfinance Bank",
        "code": "090180"
    },
    {
        "name": "BRIDGEWAY MICROFINANCE BANK",
        "code": "090393"
    },
    {
        "name": "Eyowo MFB",
        "code": "090328"
    },
    {
        "name": "Mint-Finex MICROFINANCE BANK",
        "code": "090281"
    },
    {
        "name": "Covenant Microfinance Bank",
        "code": "070006"
    },
    {
        "name": "VFD Micro Finance Bank",
        "code": "090110"
    },
    {
        "name": "PatrickGold Microfinance Bank",
        "code": "090317"
    },
    {
        "name": "Sparkle",
        "code": "090325"
    },
    {
        "name": "Paycom",
        "code": "305"
    },
    {
        "name": "NPF MicroFinance Bank",
        "code": "070001"
    },
    {
        "name": "PayAttitude Online",
        "code": "110001"
    },
    {
        "name": "Intellifin",
        "code": "100027"
    },
    {
        "name": "Contec Global Infotech Limited (NowNow)",
        "code": "100032"
    },
    {
        "name": "FCMB Easy Account",
        "code": "100031"
    },
    {
        "name": "EcoMobile",
        "code": "100030"
    },
    {
        "name": "Innovectives Kesh",
        "code": "100029"
    },
    {
        "name": "One Finance",
        "code": "100026"
    },
    {
        "name": "Zinternet Nigera Limited",
        "code": "100025"
    },
    {
        "name": "TagPay",
        "code": "100023"
    },
    {
        "name": "Eartholeum",
        "code": "100021"
    },
    {
        "name": "MoneyBox",
        "code": "100020"
    },
    {
        "name": "Fidelity Mobile",
        "code": "100019"
    },
    {
        "name": "Enterprise Bank",
        "code": "000019"
    },
    {
        "name": "Coronation Merchant Bank",
        "code": "060001"
    },
    {
        "name": "FBNQUEST Merchant Bank",
        "code": "060002"
    },
    {
        "name": "Nova Merchant Bank",
        "code": "060003"
    },
    {
        "name": "Omoluabi savings and loans",
        "code": "070007"
    },
    {
        "name": "ASOSavings & Loans",
        "code": "090001"
    },
    {
        "name": "Trustbond Mortgage Bank",
        "code": "090005"
    },
    {
        "name": "SafeTrust ",
        "code": "090006"
    },
    {
        "name": "FBN Mortgages Limited",
        "code": "090107"
    },
    {
        "name": "Imperial Homes Mortgage Bank",
        "code": "100024"
    },
    {
        "name": "AG Mortgage Bank",
        "code": "100028"
    },
    {
        "name": "Gateway Mortgage Bank",
        "code": "070009"
    },
    {
        "name": "Abbey Mortgage Bank",
        "code": "070010"
    },
    {
        "name": "Refuge Mortgage Bank",
        "code": "070011"
    },
    {
        "name": "Lagos Building Investment Company",
        "code": "070012"
    },
    {
        "name": "Platinum Mortgage Bank",
        "code": "070013"
    },
    {
        "name": "First Generation Mortgage Bank",
        "code": "070014"
    },
    {
        "name": "Brent Mortgage Bank",
        "code": "070015"
    },
    {
        "name": "Infinity Trust Mortgage Bank",
        "code": "070016"
    },
    {
        "name": "Jubilee-Life Mortgage  Bank",
        "code": "090003"
    },
    {
        "name": "Haggai Mortgage Bank Limited",
        "code": "070017"
    },
    {
        "name": "New Prudential Bank",
        "code": "090108"
    },
    {
        "name": "Fortis Microfinance Bank",
        "code": "070002"
    },
    {
        "name": "Page Financials",
        "code": "070008"
    },
    {
        "name": "Parralex Microfinance bank",
        "code": "090004"
    },
    {
        "name": "Ekondo MFB",
        "code": "090097"
    },
    {
        "name": "Seed Capital Microfinance Bank",
        "code": "090112"
    },
    {
        "name": "Empire trust MFB",
        "code": "090114"
    },
    {
        "name": "AMML MFB",
        "code": "090116"
    },
    {
        "name": "Boctrust Microfinance Bank",
        "code": "090117"
    },
    {
        "name": "IBILE Microfinance Bank",
        "code": "090118"
    },
    {
        "name": "Ohafia Microfinance Bank",
        "code": "090119"
    },
    {
        "name": "Wetland Microfinance Bank",
        "code": "090120"
    },
    {
        "name": "Hasal Microfinance Bank",
        "code": "090121"
    },
    {
        "name": "Gowans Microfinance Bank",
        "code": "090122"
    },
    {
        "name": "Verite Microfinance Bank",
        "code": "090123"
    },
    {
        "name": "Xslnce Microfinance Bank",
        "code": "090124"
    },
    {
        "name": "Regent Microfinance Bank",
        "code": "090125"
    },
    {
        "name": "Fidfund Microfinance Bank",
        "code": "090126"
    },
    {
        "name": "BC Kash Microfinance Bank",
        "code": "090127"
    },
    {
        "name": "Ndiorah Microfinance Bank",
        "code": "090128"
    },
    {
        "name": "Money Trust Microfinance Bank",
        "code": "090129"
    },
    {
        "name": "Consumer Microfinance Bank",
        "code": "090130"
    },
    {
        "name": "Allworkers Microfinance Bank",
        "code": "090131"
    },
    {
        "name": "Richway Microfinance Bank",
        "code": "090132"
    },
    {
        "name": " AL-Barakah Microfinance Bank",
        "code": "090133"
    },
    {
        "name": "Accion Microfinance Bank",
        "code": "090134"
    },
    {
        "name": "Personal Trust Microfinance Bank",
        "code": "090135"
    },
    {
        "name": "Baobab Microfinance Bank",
        "code": "090136"
    },
    {
        "name": "PecanTrust Microfinance Bank",
        "code": "090137"
    },
    {
        "name": "Royal Exchange Microfinance Bank",
        "code": "090138"
    },
    {
        "name": "Visa Microfinance Bank",
        "code": "090139"
    },
    {
        "name": "Sagamu Microfinance Bank",
        "code": "090140"
    },
    {
        "name": "Chikum Microfinance Bank",
        "code": "090141"
    },
    {
        "name": "Yes Microfinance Bank",
        "code": "090142"
    },
    {
        "name": "Apeks Microfinance Bank",
        "code": "090143"
    },
    {
        "name": "CIT Microfinance Bank",
        "code": "090144"
    },
    {
        "name": "Fullrange Microfinance Bank",
        "code": "090145"
    },
    {
        "name": "Trident Microfinance Bank",
        "code": "090146"
    },
    {
        "name": "Hackman Microfinance Bank",
        "code": "090147"
    },
    {
        "name": "Bowen Microfinance Bank",
        "code": "090148"
    },
    {
        "name": "IRL Microfinance Bank",
        "code": "090149"
    },
    {
        "name": "Virtue Microfinance Bank",
        "code": "090150"
    },
    {
        "name": "Mutual Trust Microfinance Bank",
        "code": "090151"
    },
    {
        "name": "Nagarta Microfinance Bank",
        "code": "090152"
    },
    {
        "name": "FFS Microfinance Bank",
        "code": "090153"
    },
    {
        "name": "CEMCS Microfinance Bank",
        "code": "090154"
    },
    {
        "name": "La  Fayette Microfinance Bank",
        "code": "090155"
    },
    {
        "name": "e-Barcs Microfinance Bank",
        "code": "090156"
    },
    {
        "name": "Infinity Microfinance Bank",
        "code": "090157"
    },
    {
        "name": "Futo Microfinance Bank",
        "code": "090158"
    },
    {
        "name": "Credit Afrique Microfinance Bank",
        "code": "090159"
    },
    {
        "name": "Addosser Microfinance Bank",
        "code": "090160"
    },
    {
        "name": "Okpoga Microfinance Bank",
        "code": "090161"
    },
    {
        "name": "Stanford Microfinance Bak",
        "code": "090162"
    },
    {
        "name": "First Royal Microfinance Bank",
        "code": "090164"
    },
    {
        "name": "Petra Microfinance Bank",
        "code": "090165"
    },
    {
        "name": "Eso-E Microfinance Bank",
        "code": "090166"
    },
    {
        "name": "Daylight Microfinance Bank",
        "code": "090167"
    },
    {
        "name": "Gashua Microfinance Bank",
        "code": "090168"
    },
    {
        "name": "Alpha Kapital Microfinance Bank",
        "code": "090169"
    },
    {
        "name": "Mainstreet Microfinance Bank",
        "code": "090171"
    },
    {
        "name": "Astrapolaris Microfinance Bank",
        "code": "090172"
    },
    {
        "name": "Reliance Microfinance Bank",
        "code": "090173"
    },
    {
        "name": "Malachy Microfinance Bank",
        "code": "090174"
    },
    {
        "name": "HighStreet Microfinance Bank",
        "code": "090175"
    },
    {
        "name": "Bosak Microfinance Bank",
        "code": "090176"
    },
    {
        "name": "Lapo Microfinance Bank",
        "code": "090177"
    },
    {
        "name": "GreenBank Microfinance Bank",
        "code": "090178"
    },
    {
        "name": "FAST Microfinance Bank",
        "code": "090179"
    },
    {
        "name": "Baines Credit Microfinance Bank",
        "code": "090188"
    },
    {
        "name": "Esan Microfinance Bank",
        "code": "090189"
    },
    {
        "name": "Mutual Benefits Microfinance Bank",
        "code": "090190"
    },
    {
        "name": "KCMB Microfinance Bank",
        "code": "090191"
    },
    {
        "name": "Midland Microfinance Bank",
        "code": "090192"
    },
    {
        "name": "Unical Microfinance Bank",
        "code": "090193"
    },
    {
        "name": "NIRSAL Microfinance Bank",
        "code": "090194"
    },
    {
        "name": "Grooming Microfinance Bank",
        "code": "090195"
    },
    {
        "name": "Pennywise Microfinance Bank",
        "code": "090196"
    },
    {
        "name": "ABU Microfinance Bank",
        "code": "090197"
    },
    {
        "name": "RenMoney Microfinance Bank",
        "code": "090198"
    },
    {
        "name": "New Dawn Microfinance Bank",
        "code": "090205"
    },
    {
        "name": "UNN MFB",
        "code": "090251"
    },
    {
        "name": "Imo State Microfinance Bank",
        "code": "090258"
    },
    {
        "name": "Alekun Microfinance Bank",
        "code": "090259"
    },
    {
        "name": "Above Only Microfinance Bank",
        "code": "090260"
    },
    {
        "name": "Quickfund Microfinance Bank",
        "code": "090261"
    },
    {
        "name": "Stellas Microfinance Bank",
        "code": "090262"
    },
    {
        "name": "Navy Microfinance Bank",
        "code": "090263"
    },
    {
        "name": "Auchi Microfinance Bank",
        "code": "090264"
    },
    {
        "name": "Lovonus Microfinance Bank",
        "code": "090265"
    },
    {
        "name": "Uniben Microfinance Bank",
        "code": "090266"
    },
    {
        "name": "Adeyemi College Staff Microfinance Bank",
        "code": "090268"
    },
    {
        "name": "Greenville Microfinance Bank",
        "code": "090269"
    },
    {
        "name": "AB Microfinance Bank",
        "code": "090270"
    },
    {
        "name": "Lavender Microfinance Bank",
        "code": "090271"
    },
    {
        "name": "Olabisi Onabanjo University Microfinance Bank",
        "code": "090272"
    },
    {
        "name": "Emeralds Microfinance Bank",
        "code": "090273"
    },
    {
        "name": "Trustfund Microfinance Bank",
        "code": "090276"
    },
    {
        "name": "Al-Hayat Microfinance Bank",
        "code": "090277"
    },
    {
        "name": "FET",
        "code": "100001"
    },
    {
        "name": "Parkway-ReadyCash",
        "code": "100003"
    },
    {
        "name": "Cellulant",
        "code": "100005"
    },
    {
        "name": "eTranzact",
        "code": "100006"
    },
    {
        "name": "Stanbic IBTC @ease wallet",
        "code": "100007"
    },
    {
        "name": "Ecobank Xpress Account",
        "code": "100008"
    },
    {
        "name": "GTMobile",
        "code": "100009"
    },
    {
        "name": "TeasyMobile",
        "code": "100010"
    },
    {
        "name": "Mkudi",
        "code": "100011"
    },
    {
        "name": "VTNetworks",
        "code": "100012"
    },
    {
        "name": "AccessMobile",
        "code": "100013"
    },
    {
        "name": "FBNMobile",
        "code": "100014"
    },
    {
        "name": "Kegow",
        "code": "100015"
    },
    {
        "name": "FortisMobile",
        "code": "100016"
    },
    {
        "name": "Hedonmark",
        "code": "100017"
    },
    {
        "name": "ZenithMobile",
        "code": "100018"
    },
    {
        "name": "Flutterwave Technology Solutions Limited",
        "code": "110002"
    },
    {
        "name": "NIP Virtual Bank",
        "code": "999999"
    },
    {
        "name": "Titan Trust Bank",
        "code": "000025"
    },
    {
        "name": "ChamsMobile",
        "code": "303"
    },
    {
        "name": "MAUTECH Microfinance Bank",
        "code": "090423"
    },
    {
        "name": "Greenwich Merchant Bank",
        "code": "060004"
    },
    {
        "name": "Parallex Bank",
        "code": "000030"
    },
    {
        "name": "Firmus MFB",
        "code": "090366"
    },
    {
        "name": "PALMPAY",
        "code": "100033"
    },
    {
        "name": "Manny Microfinance bank",
        "code": "090383"
    },
    {
        "name": "Letshego MFB",
        "code": "090420"
    },
    {
        "name": "M36",
        "code": "100035"
    },
    {
        "name": "Safe Haven MFB",
        "code": "090286"
    },
    {
        "name": "9 Payment Service Bank",
        "code": "120001"
    },
    {
        "name": "Tangerine Bank",
        "code": "090426"
    },
    {
        "name": "FEDETH MICROFINANCE BANK",
        "code": "090482"
    },
    {
        "name": "Carbon",
        "code": "100026"
    },
    {
        "name": "CHANGHAN RTS MICROFINANCE BANK",
        "code": "090470"
    },
    {
        "name": "PremiumTrust Bank",
        "code": "000031"
    },
    {
        "name": "Links Microfinance Bank",
        "code": "090435"
    },
    {
        "name": "TeamApt",
        "code": "110007"
    },
    {
        "name": "ENaira",
        "code": "000033"
    }
]
