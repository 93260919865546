import { ArrowBack } from '@mui/icons-material';
import { FormGroup, IconButton } from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, CustomButton, H1, P, PaperBox,Image, Label, Input, WarningModal } from '../../../components'
import { AppColors } from '../../../components/AppColors';
import Images from '../../../components/images';
import DataGridDemo, { CustomPagination } from '../../../components/Tables';
import { apiFunctions,useFetchLCDAsByState } from '../../../utils/api';
import { Capitalize, ToastError, ToastSuccess } from '../../../utils/functions';
import styles from './styles'


const CreateLCDA = () => {
    const navigate = useNavigate()

    const {state_id,state_name} = useParams()
    const [page,setPage] = React.useState(1)
    const [show,setShow] = React.useState(false)
    const queryClient = useQueryClient()
    const [selected,setSelected] = React.useState({})
    const [pageCount,setPageCount] = React.useState(1)

    const {
        mutateAsync : deleteLCDA,
        isLoading : isDeleting
    } = useMutation(apiFunctions.deleteLCDA)
    const {
        mutateAsync : updateLCDA,
        isLoading : isUpdating
    } = useMutation(apiFunctions.updateLCDA)

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.createLCDA)
    const [data,setData] = React.useState({
        name : ""
    })
    const {
        data : lcdas,
        isLoading : loading
    } = useFetchLCDAsByState(page,state_id)

    const handleSubmit = async () => {
        try{
            if(
                data.name.toString().trim() === ""
            ){
                return ToastError("Please provide state name")
            }
            if(!data.id){
                await mutateAsync({...data,state_id})
                ToastSuccess("New record has been added")
                setData({
                    name : ""
                })
                return queryClient.invalidateQueries("lcda_by_state")
            }
            let fd = {
                name : data.name,
                id : data.id,
                state_id
            }
            await updateLCDA(fd)
            ToastSuccess("Record has been updated")
            setData({
                name : ""
            })
            queryClient.invalidateQueries("lcda_by_state")
        }catch(err){
            ToastError(err.msg)
        }
    }

    const handleDeleteWarning = (item) => {
        setSelected(item)
        setShow(true)
    }

    const handleDelete = async () => {
        try{
            await deleteLCDA(selected.id)
            ToastSuccess(`${selected.name} has been deleted`)
            queryClient.invalidateQueries("lcdas")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
    }

    const handleEdit = (item) => {
        setData(item)
        window.scrollTo(0,0)
    }

    
    useEffect(()=>{
        if(!lcdas) return
        setPage(lcdas?.data?.current_page)
        setPageCount(lcdas?.data?.last_page)
    },[lcdas])

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        // {
        //     field : "sn",
        //     header : "SN",
        //     renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        // },
        {
            field : "country_name",
            header : "Name",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.name ? Capitalize(row.name) : ""}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.created_at ? moment(row.created_at).format("DD/MM/YYYY") : null}</H1>
        },
        {
            field : "edit",
            header : "Edit",
            renderCell : (row) => <IconButton onClick={()=>handleEdit(row)}>
                    <Image backgroundimage={Images.edit} width={"1.2rem"} height={"1.2rem"} />
                </IconButton>
        },
        {
            field : "delete",
            header : "Delete",
            renderCell : (row) => <IconButton onClick={()=>handleDeleteWarning(row)}>
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"}>
            <Container marginBottom={"2rem"}>
                <form>
                    <Container 
                        container 
                        flexDirection={"row"}
                        marginBottom={"2rem"}
                        alignItems={"center"}
                    >
                        <IconButton onClick={()=>navigate(-1)} 
                            sx={styles.backButton}
                        >
                            <ArrowBack />
                        </IconButton>
                        <P fontSize={"1.018rem"}>LCDAs in {state_name ? Capitalize(state_name) : ""} ({lcdas?.data?.total ? numeral(lcdas?.data?.total).format("0,0") : 0})</P>
                    </Container>
                    <Container
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        width={"60%"}
                    >
                        <FormGroup>
                            <Label>LCDA name</Label>
                            <Input placeholder="Enter lcda name" type="text" 
                                value={data.name} 
                                onChange={e=>setData({...data,name : e.target.value})}
                            />
                        </FormGroup>
                        <CustomButton variant='outlined'
                            width={"6rem"}
                            padding={"0.4rem"}
                            border={`1.5px solid ${AppColors.red}`}
                            borderradius={"0.452rem"}
                            onClick={handleSubmit}
                            loading={isLoading || isUpdating}
                        >
                            <H1 color={AppColors.red} fontWeight={"bold"}>Save</H1>
                        </CustomButton>
                    </Container>
                </form>
            </Container>
            <Container>
                <DataGridDemo columns={columns}
                    rows={lcdas?.data && Array.isArray(lcdas?.data?.data) ? lcdas?.data?.data : []}
                    hideFooter={false}
                    loading={loading}
                    CustomPagination={RenderPagination}
                />
                <WarningModal 
                    open={show} handleDelete={handleDelete} closeWarning={closeWarning}
                    loading={isDeleting}
                    selection={selected?.name}
                />
            </Container>
        </PaperBox>
    )
}

export default CreateLCDA;