import { FormGroup, IconButton, MenuItem } from '@mui/material';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomButton, H1, Input, Label, P, PaperBox,Image, CustomSelect } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import { SUB_FEE_LIST } from '../../../../components/Constants';
import DataGridDemo from '../../../../components/Tables';
import Images from '../../../../components/images'


const CreateSubFees = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const backHandler = () => {
        navigate(-1)
    }
    const columns = [
        {
            field : "sn",
            header : "S/N",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "user_type",
            header : "User type",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{value}</H1>
        },
        {
            field : "business_name",
            header : "Business name",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{value}</H1>
        },
        {
            field : "gig_worker",
            header : "Gig worker",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{value}</H1>
        },
        {
            field : "sub_type",
            header : "Subs. type",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{value}</H1>
        },
        {
            field : "sub_fee",
            header : "Subs. Fee",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{value}</H1>
        },
        {
            field : "date",
            header : "Date created",
            renderCell : (value) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "edit",
            header : "Edit",
            renderCell : (value) => <IconButton>
                    <Image backgroundimage={Images.edit} width={"1.2rem"} height={"1.2rem"} />
                </IconButton>
        },
        {
            field : "delete",
            header : "Delete",
            renderCell : (value) => <IconButton>
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row[item.field]),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"}>
            <Container marginBottom={"2rem"}>
                <form>
                    <P fontSize={"1.018rem"} marginBottom={"2rem"}>Create Subscription Fees for Restaurants & Gig Workers</P>
                    <Container container spacing={2}>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Select user</Label>
                                <CustomSelect>
                                    <MenuItem>Restaurant</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Business name</Label>
                                <CustomSelect>
                                    <MenuItem>Bukkahut</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Gig worker name</Label>
                                <CustomSelect>
                                    <MenuItem>John Majaruola</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container container spacing={2} marginTop={"1rem"}>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Subscription type</Label>
                                <CustomSelect>
                                    <MenuItem>Daily</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Currency</Label>
                                <CustomSelect>
                                    <MenuItem>Bukkahut</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Subscription fee</Label>
                                <Input type='text' />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container marginTop={"2rem"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    >
                        <CustomButton variant='outlined'
                            width={"6rem"}
                            padding={"0.4rem"}
                            border={`1.5px solid ${AppColors.red}`}
                            borderradius={"0.452rem"}
                        >
                            <H1 fontWeight={"bold"} color={AppColors.red}>Save</H1>
                        </CustomButton>
                    </Container>
                </form>
            </Container>
            <Container>
                <DataGridDemo columns={columns} rows={SUB_FEE_LIST}
                    hideFooter={false}
                />
            </Container>
        </PaperBox>
    )
}

export default CreateSubFees;