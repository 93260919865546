import { AppColors } from "../../../components/AppColors";

const styles = {
    textNoWrap : {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    arrowUP : {
        color : AppColors.green,
        fontSize : "1rem"
    }
}
export default styles;