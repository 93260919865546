import { AppColors } from "../AppColors";

const styles = {
    backButton : {
        backgroundColor : AppColors.offWhite,
        marginRight : "1rem",
        marginBottom : "2rem"
    }
}

export default styles;