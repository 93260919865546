import { FormGroup, IconButton, MenuItem } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { Container, CustomButton, H1, P, PaperBox,Image, Label, Input, WarningModal, CustomSelect } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import { BANK_LIST } from '../../../../components/Constants';
import Images from '../../../../components/images';
import DataGridDemo, { CustomPagination } from '../../../../components/Tables';
import { apiFunctions, useFetchBanks } from '../../../../utils/api';
import { Capitalize, ToastError, ToastSuccess } from '../../../../utils/functions';


const POSDetails = () => {
    const [show,setShow] = React.useState(false)
    const queryClient = useQueryClient()
    const [selected,setSelected] = React.useState({})
    const [page,setPage] = React.useState(1);
    const [pageCount,setPageCount] = React.useState(1)

    const {
        mutateAsync : deleteBank,
        isLoading : isDeleting
    } = useMutation(apiFunctions.deleteBank)
    const {
        mutateAsync : updateBank,
        isLoading : isUpdating
    } = useMutation(apiFunctions.updateBank)

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.createBank)
    
    const [data,setData] = React.useState({
        account_number : "",
        bank_code : "",
        owner :"admin"
    })
    const {
        data : banks,
        isLoading : loading
    } = useFetchBanks(page)

    useEffect(()=>{
        if(!banks?.data?.data?.current_page) return
        setPage(banks?.data?.data?.current_page)
        setPageCount(banks?.data?.data?.last_page)
    },[banks])

    const handleSubmit = async () => {
        try{
            if(
                data.bank_code.toString().trim() === "" ||
                data.account_number.toString().trim() === ""
            ){
                return ToastError("Please all fields are required")
            }
            if(!data.id){
                await mutateAsync(data)
                ToastSuccess("New record has been added")
                setData({
                    bank_code : "",
                    account_number : "",
                    owner:"admin"
                })
                return queryClient.invalidateQueries("banks")
            }
            await updateBank(data)
            ToastSuccess("Record has been updated")
            setData({
                bank_code : "",
                account_number : "",
                owner:"admin"
            })
            queryClient.invalidateQueries("banks")
        }catch(err){
            ToastError(err.msg)
        }
    }

    const handleDeleteWarning = (item) => {
        setSelected(item)
        setShow(true)
    }

    const handleDelete = async () => {
        try{
            await deleteBank(selected.id)
            ToastSuccess(`${selected.name} has been deleted`)
            queryClient.invalidateQueries("banks")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
    }

    const handleEdit = (item) => {
        setData({...data,...item,bank_code : item?.verification_response?.bank?.code})
        window.scrollTo(0,0)
    }

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }


    const columns = [
        // {
        //     field : "sn",
        //     header : "SN",
        //     renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.name ? Capitalize(row?.name) : null}</H1>
        // },
        {
            field : "bank_name",
            header : "Bank name",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.name ? Capitalize(row?.name) : null}</H1>
        },
        {
            field : "acc_name",
            header : "Account name",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.account_name ? Capitalize(row?.account_name) : null}</H1>
        },
        {
            field : "acc_number",
            header : "Account number",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.account_number ? Capitalize(row?.account_number) : null}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.created_at ? moment(row?.created_at).format("DD/MM/YYYY") : null}</H1>
        },
        {
            field : "edit",
            header : "Edit",
            renderCell : (row) => <IconButton onClick={()=>handleEdit(row)}>
                    <Image backgroundimage={Images.edit} width={"1.2rem"} height={"1.2rem"} />
                </IconButton>
        },
        {
            field : "delete",
            header : "Delete",
            renderCell : (row) => <IconButton onClick={()=>handleDeleteWarning(row)}>
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"}>
            <Container marginBottom={"2rem"}>
                <form>
                    <P fontSize={"1.018rem"} marginBottom={"2rem"}>Create BIKEE Bank Account For Rider’s App</P>
                    <Container
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        width={"60%"}
                    >
                        <FormGroup>
                            <Label>Bank name</Label>
                            {/* <Input placeholder="Enter bank name" type="text"
                                value={data?.name}
                                onChange={e=>setData({...data, name : e.target.value})}
                            /> */}
                            <CustomSelect value={data?.bank_code || ""} onChange={(e)=>setData({...data,bank_code : e.target.value})}
                                width={"12rem"}
                            >
                                <MenuItem value="">Select bank</MenuItem>
                                {
                                    BANK_LIST.map((item,i)=><MenuItem key={i} value={item.code}>{item.name}</MenuItem>)
                                }
                            </CustomSelect>
                        </FormGroup>
                        {/* <FormGroup>
                            <Label>Account name</Label>
                            <Input placeholder="Enter account name" type="text" 
                                value={data?.account_name}
                                onChange={e=>setData({...data, account_name : e.target.value})}
                            />
                        </FormGroup> */}
                        <FormGroup>
                            <Label>Account number</Label>
                            <Input placeholder="Enter account number" type="text" 
                                value={data?.account_number}
                                onChange={e=>setData({...data, account_number : e.target.value})}
                            />
                        </FormGroup>
                        <CustomButton variant='outlined'
                            width={"6rem"}
                            padding={"0.4rem"}
                            border={`1.5px solid ${AppColors.red}`}
                            borderradius={"0.452rem"}
                            onClick={handleSubmit}
                            loading={isLoading || isUpdating}
                        >
                            <H1 color={AppColors.red} fontWeight={"bold"}>Save</H1>
                        </CustomButton>
                    </Container>
                </form>
            </Container>
            <Container>
                <DataGridDemo columns={columns}
                    hideFooter={false}
                    rows={banks?.data?.data && Array.isArray(banks?.data?.data) ? banks?.data?.data : []}
                    hideFooter={false}
                    loading={loading}
                    CustomPagination={RenderPagination}
                />
                <WarningModal 
                    open={show} handleDelete={handleDelete} closeWarning={closeWarning}
                    loading={isDeleting}
                    selection={selected?.name}
                />
            </Container>
        </PaperBox>
    )
}

export default POSDetails;