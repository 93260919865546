import React from "react";
import { Container, P, PaperBox,H1, CustomButton, LinkedButton, Image } from "../../../components";
import { AppColors } from "../../../components/AppColors";
import { DASHBOARD_TRANSACTION_LIST } from "../../../components/Constants";
import { ArrowUpward } from "@mui/icons-material";
import styles from "./styles";
import DataGridDemo from "../../../components/Tables";
import { DASHBOARD_TOOLBAR } from "../../../components/Toolbars";
import Images from "../../../components/images";
import { useDispatch } from "react-redux";
import { RIDER_DETAILS, PRODUCT_DETAILS } from "../../../actions/actionTypes";
import { useNavigate } from "react-router-dom";
import { drawerHandler } from "../../../redux/drawerSlice";

export const Dashboard = () => {
    const [current,setCurrent] = React.useState("Table") 
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onClickHandler = (type) => {
        if(type === "view_product"){
           return dispatch(drawerHandler(PRODUCT_DETAILS))
        }
        if(type === "rider_details"){
            return dispatch(drawerHandler(RIDER_DETAILS))
        }
        navigate("/user/client-details")
    }
    const columns = [
        {
            field : "sn",
            header : "S/N",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "orderID",
            header : "Order ID",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "rider",
            header : "Rider",
            renderCell : (value) => <CustomButton background={AppColors.green} height="auto"><H1 singleline="true"
                color={AppColors.white}
                fontSize={"0.698rem"}
                borderRadius={"0.387rem"}
                onClick={()=>onClickHandler("rider_details")}
            >{value}</H1></CustomButton>
        },
        {
            field : "client",
            header : "Client",
            renderCell : (value) => <CustomButton onClick={()=>onClickHandler("client_details")}>
                <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline={"true"} textDecoration={"underline"}>{value}</H1>
            </CustomButton>
        },
        {
            field : "status",
            header : "Status",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "address",
            header : "Address",
            renderCell : (item) => <Container>
                <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">
                    <P variant="span" color={AppColors.green} fontWeight={"bold"}>P-</P>
                    {" "}{item?.pickup_address}
                </H1>
                <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">
                    <P color={AppColors.red} fontWeight={"bold"} variant="span">D-</P>
                    {" "}{item?.dropoff_address}
                </H1>
            </Container>
        },
        {
            field : "product",
            header : "Product",
            renderCell : (value) =><CustomButton onClick={()=>onClickHandler("view_product")}>
                <H1 color={AppColors.red} fontWeight={"600"} textDecoration={"underline"}>View product</H1>
            </CustomButton>
        },
        {
            field : "recipient",
            header : "Recipient",
            renderCell : (value) =><H1 
                fontWeight={"bold"} fontSize={"0.844rem"} 
                color={AppColors.purple}
                singleline={"true"}
            >{value}</H1>
        },
        {
            field : "payment",
            header : "Payment",
            renderCell : (item) => <Container>
                <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{item?.amount}</H1>
                <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{item?.payment_type}</H1>
            </Container>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(!["address","product","payment"].includes(item.field) ? param.row[item.field] : param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));



    return(
        <Container>
            <PaperBox borderradius={"0.552rem"}>
                <Container flexDirection={"row"} display={"flex"} paddingTop={"1rem"}
                    paddingBottom={"1rem"}
                    paddingLeft={"0.5rem"}
                >
                    {
                        [
                            {
                                title : "Total Deliveries",
                                value : "170+"
                            },
                            {
                                title : "Assigned Deliveries",
                                value : "170+"
                            },
                            {
                                title : "Unassigned Deliveries",
                                value : "170+"
                            },{
                                title : "Ongoing Deliveries",
                                value : "170+"
                            },{
                                title : "Cancelled Deliveries",
                                value : "170+"
                            },{
                                title : "Completed Deliveries",
                                value : "170+"
                            },{
                                title : "Riders on Duty",
                                value : "50"
                            }
                        ].map((item,i)=><Container key={i} padding={"1rem"}>
                            <H1 color={AppColors.grey} fontSize={"1.258rem"}>{item.value}</H1>
                            <P color={AppColors.textGrey}>{item.title}</P>
                        </Container>)
                    }
                </Container>
            </PaperBox>
            <Container 
                margin={"2rem auto"}
                display={"grid"}
                gridTemplateColumns={"auto auto auto auto"}
                gap={2}
                width={"90%"}
            >
                {
                    [
                        {
                            title : "TOTAL DISTANCE COVERED",
                            value : '24km'
                        },
                        {
                            title : "TOTAL CO2 SAVED",
                            value : '50,000 Kg'
                        },
                        {
                            title : "TOTAL CALORIES BURNT",
                            value : '25 kCal'
                        },
                        {
                            title : "TOTAL CLIENTS",
                            value : '250 Clients'
                        }
                    ].map((item,index)=><Container 
                        key={index} padding={"1rem 2rem"}
                        borderRadius={"0.5rem"}
                        border={`1px solid ${AppColors.grayBorder}`}
                        backgroundColor={AppColors.white}
                        width={"15rem"}
                    >
                        <P fontSize={"0.661rem"}>{item.title}</P>
                        <H1 fontSize={"1.256rem"} fontWeight={"bold"}>{item.value}</H1>
                        <Container display={"flex"} alignItems={"center"} marginTop={"1rem"}>
                            <ArrowUpward sx={styles.arrowUP}/>
                            <P fontSize={"0.661rem"}>1.10% Since yesterday</P>
                        </Container>
                    </Container>)
                }
            </Container>
            <PaperBox padding={"2rem"} background={current === "Map" ? "transparent" : null}>
                <Container>
                    <DASHBOARD_TOOLBAR setCurrent={setCurrent} current={current} />
                    {
                        current === "Table" ? <DataGridDemo columns={columns} rows={DASHBOARD_TRANSACTION_LIST}
                        hideFooter={false}
                    /> : <Container
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                            <Image
                                backgroundimage={Images.Map}
                                height={'30rem'}
                                width={"50%"}
                                backgroundsize={"cover"}
                                borderRadius={"2rem"}
                            /> 
                            <PaperBox padding={"2rem"} width={"40%"}>
                                <Container
                                    flexDirection={"row"}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    marginBottom={"2rem"}
                                >
                                    <H1 fontWeight={"bold"} fontSize={"1.066rem"}>#87gTF301</H1>
                                    <CustomButton background={AppColors.yellow} width={"5rem"}>
                                        <H1 color={AppColors.darkYellow} fontSize={"0.707rem"}>In-transit</H1>
                                    </CustomButton>
                                </Container>
                                <H1 color={AppColors.purple}
                                    fontSize={"0.767rem"}
                                    fontWeight={"bold"}
                                >Admin - Bukkahut place</H1>
                                <Container padding={"0.5rem"} borderTop={`1px solid ${AppColors.line}`}
                                    marginTop={"1rem"}
                                >
                                    {
                                        [
                                            {
                                                icon : Images.AtIcon,
                                                title : "bukkahut@bukkahut.com"
                                            },
                                            {
                                                icon : Images.Phone,
                                                title : "09078651628"
                                            },
                                            {
                                                icon : Images.mappin,
                                                title : "18b Sunday Adigun Street, Yaba,Lagos, Nigeria."
                                            }
                                        ].map((item,index)=><Container key={index} display={"flex"}
                                            alignItems={"center"}
                                            marginTop={"0.8rem"}
                                        >
                                            <Image 
                                                backgroundimage={item.icon}
                                                width={"1rem"}
                                                height={"1rem"}
                                            />
                                            <P fontSize={"0.802rem"} marginLeft={"1rem"}>{item.title}</P>
                                        </Container>)
                                    }
                                </Container>
                                <Container marginTop={"2rem"}>
                                    <H1 
                                        color={AppColors.red} 
                                        fontWeight={"bold"}
                                        fontSize={"0.767rem"}
                                    >Delivery details</H1>
                                    <Container marginTop={"1rem"}
                                        padding={'1rem'}
                                        backgroundColor={AppColors.lightestRed}
                                        borderRadius={"0.767rem"}
                                    >
                                        <H1 fontSize={'0.851rem'} color={AppColors.purple}>Adekike Inien</H1>
                                        <H1 marginTop={"1rem"} fontSize={"0.7rem"}>2, Admiralty Way, Lekki, Lagos No 6 Lawanson Close, Ikeja, Lagos</H1>
                                        <Container
                                            display={"grid"}
                                            gridTemplateColumns={"auto auto"}
                                            columnGap={3}
                                            rowGap={3}
                                            width={"90%"}
                                            marginTop={"1rem"}
                                        >
                                            {
                                                [
                                                    {
                                                        title : "Phone number",
                                                        value : "09093440570"
                                                    },
                                                    {
                                                        title : "Delivery LCDA",
                                                        value : "Ikeja Alimosho"
                                                    },
                                                    {
                                                        title : "Delivery fee",
                                                        value : "₦ 457,390.00"
                                                    },
                                                    {
                                                        title : "Payment method",
                                                        value : "COD - PAID"
                                                    }
                                                ].map((item,i)=><Container key={i}>
                                                    <P marginBottom={"0.5rem"}>{item.title}</P>
                                                    <H1 color={
                                                    item.title === "Delivery fee" ? AppColors.red : 
                                                    item.title === "Payment method" ? AppColors.lime : null
                                                }>{item.value}</H1>
                                                </Container>)
                                            }
                                        </Container>
                                    </Container>
                                </Container>
                                <Container 
                                    marginTop={"2rem"}
                                
                                >
                                    <H1 color={AppColors.red} fontSize={"0.767rem"} fontWeight={"bold"}>Delivery details</H1>
                                    <Container 
                                        flexDirection={"row"}
                                        display={"flex"}
                                        marginTop={"1rem"}
                                        padding={"2rem 1rem"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        borderRadius={"0.387rem"}
                                        border={`1px solid ${AppColors.line}`}
                                    >
                                        <Container width={"35%"}>
                                            <H1 singleline="true">Festus Mark</H1>
                                            <P>10 items</P>
                                        </Container>
                                        <Container
                                            flexDirection={"row"}
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            width={"60%"}
                                        >
                                            <CustomButton width={"7rem"} variant="contained" mode="danger" onClick={()=>onClickHandler("rider_details")}>
                                                <H1 fontSize={"0.672rem"}>View rider</H1>
                                            </CustomButton>
                                            <CustomButton width={"7rem"} variant="contained" onClick={()=>onClickHandler("view_product")}>
                                                <H1 fontSize={"0.672rem"}>View product</H1>
                                            </CustomButton>
                                        </Container>
                                    </Container>
                                </Container>
                            </PaperBox>
                        </Container>
                    }
                </Container>
            </PaperBox>
        </Container>
    )
}