import { FormGroup, IconButton, MenuItem } from '@mui/material';
import React from 'react';
import { Container, CustomButton, H1, Input, Label, P, PaperBox,Image, CustomSelect } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import { MEMBER_LIST } from '../../../../components/Constants';
import DataGridDemo, { CustomPagination } from '../../../../components/Tables';
import Images from '../../../../components/images'
import { ArrowUpward, Search } from '@mui/icons-material';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { drawerHandler } from '../../../../redux/drawerSlice';
import { ADD_MEMBER, EDIT_MEMBER } from '../../../../actions/actionTypes';
import { useFetchUsers } from '../../../../utils/api';
import { Capitalize } from '../../../../utils/functions';
import { changeUserHandler } from '../../../../redux/userSlice';


const Teams = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const manageEditTeamModal = (user) => {
        dispatch(changeUserHandler(user))
        dispatch(drawerHandler(EDIT_MEMBER))
    }
    const [page,setPage] = React.useState(1)
    const {
        data : users,
        isLoading
    } = useFetchUsers(page)
    const [pageCount,setPageCount] = React.useState(1)

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        {
            field : "name",
            header : "Name",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.first_name ? `${Capitalize(row?.first_name)} ` : null} {row?.last_name ? Capitalize(row?.last_name) : null}</H1>
        },
        {
            field : "email",
            header : "Email",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.email}</H1>
        },
        {
            field : "phone",
            header : "Phone",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.phone}</H1>
        },
        {
            field : "type",
            header : "User type",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.type ? Capitalize(row?.type) : null}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.created_at}</H1>
        },
        {
            field : "view",
            header : "",
            renderCell : (row) => row?.type !== "client" ? <CustomButton 
                background={AppColors.greenBackground} 
                borderradius={"0.8rem"} width={"3rem"}
                onClick={()=>manageEditTeamModal(row)}
            >
                <P color={AppColors.green}>Edit</P>
            </CustomButton> : null
        },
        {
            field : "edit",
            header : "",
            renderCell : (row) => row?.type !== "client" ? <CustomButton 
            background={
                row?.type === "rider" ? AppColors.lightRed : AppColors.lightPurple
            } 
                borderradius={"0.8rem"} width={"3rem"}
                onClick={()=>{
                    if(row?.type === "rider") return navigate(`/user/rider-page/${row.id}`)
                    if(row?.type === "admin") return navigate(`/user/admin-page/${row.id}`)
                }}
            >
                <P color={row?.type === "rider" ? AppColors.red : AppColors.purpleBackground}>View</P>
            </CustomButton> : null 
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <Container>
            <PaperBox padding={"2rem"}>
                <Container>
                    <DataGridDemo columns={columns} rows={MEMBER_LIST}
                        hideFooter={false}
                            rows={users?.data?.data && Array.isArray(users?.data?.data) ? users?.data?.data : []}
                            hideFooter={false}
                            loading={isLoading}
                            CustomPagination={RenderPagination}
                        Toolbar={()=><Container display={"flex"} alignItems={"center"} marginBottom={"1rem"}>
                            <Container 
                                display={"flex"}
                                alignItems={"flex-end"}
                                justifyContent={"space-between"}
                            >
                                <Container width={"60%"} display={"flex"}
                                    alignItems={"center"}
                                >
                                    <H1 variant='h6' fontWeight={"bold"} fontSize={"1.011rem"}
                                        marginRight={"1rem"}
                                    >Team members list</H1>
                                    <Input type="text" placeholder='Search'
                                        startAdornment={<Search sx={styles.searchIcon} />}
                                    />
                                    <CustomSelect width={"10rem"} marginleft="1rem">
                                        <MenuItem>User type</MenuItem>
                                        <MenuItem>Admin</MenuItem>
                                        <MenuItem>Rider</MenuItem>
                                    </CustomSelect>
                                </Container>
                                <CustomButton mode="danger" variant="contained" width="10rem" 
                                    onClick={()=>dispatch(drawerHandler(ADD_MEMBER))}
                                >
                                    <H1>Add new member</H1>
                                </CustomButton>
                            </Container>
                        </Container>}
                    />
                </Container>
            </PaperBox>
        </Container>
    )
}

export default Teams;