import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomButton, H1, P, PaperBox } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import { ALL_TRANSACTION_LIST } from '../../../../components/Constants';
import DataGridDemo from '../../../../components/Tables';
import { INVOICE_TOOLBAR } from '../../../../components/Toolbars';


const CashDelivery = () => {
    const columns = [
        {
            field : "orderID",
            header : "Order ID",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "address",
            header : "Delivery Address",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{value}</H1>
        },
        {
            field : "transaction_type",
            header : "Transaction Type",
            renderCell : (value) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (value) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "lcda",
            header : "LCDA",
            renderCell : (value) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "delivery_fee",
            header : "Delivery Fee",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "status",
            header : "Status",
            renderCell : (value) => value === "Paid" ? <CustomButton background={AppColors.lightLime} borderradius={"0.5rem"}>
                <P color={AppColors.lime}>Paid</P>
            </CustomButton> : <CustomButton background={AppColors.lightRed} borderradius={"0.5rem"}>
                <P color={AppColors.red}>Unpaid</P>
            </CustomButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row[item.field]),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"}>
            <Container>
                <DataGridDemo columns={columns} rows={ALL_TRANSACTION_LIST}
                    hideFooter={false}
                    Toolbar={()=><INVOICE_TOOLBAR title={"Cash on delivery"} />}
                />
            </Container>
        </PaperBox>
    )
}

export default CashDelivery;