import { FormGroup, IconButton, MenuItem } from '@mui/material';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomButton, H1, Input, Label, P, PaperBox,Image, CustomSelect, WarningModal, SearchableSelect } from '../../../../components'
import { AppColors } from '../../../../components/AppColors';
import DataGridDemo, { CustomPagination } from '../../../../components/Tables';
import Images from '../../../../components/images'
import { Search } from '@mui/icons-material';
import styles from './styles';
import { useMutation, useQueryClient } from 'react-query';
import { apiFunctions, useFetchAreasByLCDA, useFetchClients, useFetchCountries, useFetchIndustries, useFetchKMPrice, useFetchLCDAsByState, useFetchStatesByCountry } from '../../../../utils/api';
import { Capitalize, ToastError, ToastSuccess } from '../../../../utils/functions';
import numeral from 'numeral';
import moment from 'moment';


const KMPrice = () => {
    const navigate = useNavigate()
    
    const [page,setPage] = React.useState(1)
    const [fpage,setFPage] = React.useState(1)
    const [show,setShow] = React.useState(false)
    const queryClient = useQueryClient()
    const [selected,setSelected] = React.useState({})
    const [pageCount,setPageCount] = React.useState(1)

    const [inputValue,setInputValue] = React.useState({
        industry : "",
        state : "",
        area : "",
        lcda : ""
    })
    const [data,setData] = React.useState({
        client : {},
        industry : {},
        state : {},
        lcda : {},
        area : {},
        price_morning : "",
        price_evening : "",
        currency : "",
        country_id : ""
    })

    const {
        data : clients,
        isLoading : loadingClients
    } = useFetchClients(fpage)

     const {
        data : countries
    } = useFetchCountries(fpage)

    const {
        data : states,
        isLoading : loadingStates
    } = useFetchStatesByCountry(fpage,data?.country_id)

    const {
        data : lcdas,
        isLoading : loadingLCDAs
    } = useFetchLCDAsByState(fpage,data?.state?.id)

    const {
        data : areas,
        isLoading : loadingAreas
    } = useFetchAreasByLCDA(fpage,data?.lcda?.id)

    const {
        data : industries,
        isLoading : loadingIndustries
    } = useFetchIndustries(fpage)
    const {
        data : prices,
        isLoading : loading
    } = useFetchKMPrice(page)
    const {
        mutateAsync : deleteKMPrice,
        isLoading : isDeleting
    } = useMutation(apiFunctions.deleteKMPrice)
    const {
        mutateAsync : updateKMPrice,
        isLoading : isUpdating
    } = useMutation(apiFunctions.updateKMPrice)

    const {
        mutateAsync,
        isLoading
    } = useMutation(apiFunctions.createKMPrice)

    useEffect(()=>{
        if(!prices) return
        setPage(prices?.data?.current_page)
        setPageCount(prices?.data?.last_page)
    },[prices])

    const handleSubmit = async () => {
        try{
            const required = [
                "client" ,
                "industry",
                "area",
                "price_morning",
                "price_evening",
                "currency"
            ]
            let msg = ""
            for(const req of required){
                if(!data[req] || data[req].toString().trim() === ""){
                    msg = `Please provide your ${req.replace("_"," ")}`
                    break;
                }
            }
            if(msg.trim() !== "") return ToastError(msg)
            let fd = {...data,
                industry_id : data?.industry?.id,
                area_id : data?.area?.id,
                client_id : data?.client?.id,
                price_morning : numeral(data.price_morning).format("0"),
                price_evening : numeral(data.price_evening).format("0")
            }
            let message = data.id ? "Record has been updated" : "New record has been added"
            if(data.id) await updateKMPrice(fd)
            if(!data.id) await mutateAsync(fd)
            ToastSuccess(message)
            setData({
                client : {},
                industry : {},
                area : {},
                state : {},
                lcda : {},
                country_id : "",
                price_morning : "",
                price_evening : "",
                currency : ""
            })
            setInputValue({
                industry : "",
                client : "",
                area : "",
                state : "",
                lcda : ""
            })
            return queryClient.invalidateQueries("km_prices")
        }catch(err){
            ToastError(err.msg)
        }
    }

    const handleDeleteWarning = (item) => {
        setSelected(item)
        setShow(true)
    }

    const handleDelete = async () => {
        try{
            await deleteKMPrice(selected.id)
            ToastSuccess(`${selected.business_name} has been deleted`)
            queryClient.invalidateQueries("km_prices")
            setShow(false)
        }catch(err){
            ToastError(err.msg)
        }
    }

    const closeWarning = () => {
        setShow(false)
    }

    const handleEdit = (item) => {
        setData(item)
        window.scrollTo(0,0)
    }

    const onPageChange = (value) => {
        setPage(value)
    }

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }

    const columns = [
        {
            field : "business_name",
            header : "Business name",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.client?.name ? Capitalize(row?.client?.name) : null}</H1>
        },
        {
            field : "outlet",
            header : "Outlet",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.area?.name ? Capitalize(row?.area?.name) : null}</H1>
        },
        {
            field : "currency",
            header : "Currency",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.currency}</H1>
        },
        {
            field : "off_peak",
            header : "Fee/Km (Off Peak)",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"} singleline="true">{row?.price_morning ? numeral(row?.price_morning).format("0,0") : null}</H1>
        },
        {
            field : "peak",
            header : "Fee/Km (Peak Period)",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.price_evening ? numeral(row?.price_evening).format("0,0") : null}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.created_at ? moment(row?.created_at).format("DD/MM/YYYY") : null}</H1>
        },
        {
            field : "edit",
            header : "Edit",
            renderCell : (row) => <IconButton onClick={()=>handleEdit(row)}>
                    <Image backgroundimage={Images.edit} width={"1.2rem"} height={"1.2rem"} />
                </IconButton>
        },
        {
            field : "delete",
            header : "Delete",
            renderCell : (row) => <IconButton onClick={()=>handleDeleteWarning(row)}>
                <Image backgroundimage={Images.delete} width={"1.2rem"} height={"1.2rem"} />
            </IconButton>
        },
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"}>
            <Container marginBottom={"2rem"}>
                <form>
                    <P fontSize={"1.018rem"} marginBottom={"2rem"}>Create New Prices for Businesses per KM</P>
                    <Container container spacing={2}>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Business Industry</Label>
                                <SearchableSelect 
                                    value={data?.industry}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.title === value?.title
                                    }}
                                    onChange={(e,value)=>{
                                        setData({...data,industry : value})
                                    }}
                                    inputValue={inputValue?.industry  ? inputValue?.industry : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,industry : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={industries?.data?.data || []}
                                    loading={loadingIndustries}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Client</Label>
                                <SearchableSelect 
                                    value={data?.client}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.title === value?.title
                                    }}
                                    onChange={(e,value)=>{
                                        setData({...data,client : value})
                                    }}
                                    inputValue={inputValue?.client  ? inputValue?.client : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,client : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={clients?.data?.data || []}
                                    loading={loadingClients}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Country</Label>
                                <CustomSelect value={data.country_id} onChange={(e)=>setData({...data,country_id : e.target.value,state : {}, lcda : {}})}>
                                    {
                                        countries?.data?.data && Array.isArray(countries?.data?.data) ? countries.data.data.map((item,i)=>{
                                            return <MenuItem key={i} value={item.id}>{item?.name ? Capitalize(item.name) : ""}</MenuItem>
                                        }) : <MenuItem value="">Select Country</MenuItem>
                                    }
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container container spacing={2} marginTop={"1rem"}>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>State</Label>
                                <SearchableSelect 
                                    value={data?.state}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.title === value?.title
                                    }}
                                    onChange={(e,value)=>{
                                        setData({...data,state : value})
                                    }}
                                    inputValue={inputValue?.state  ? inputValue?.state : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,state : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={states?.data?.data || []}
                                    loading={loadingStates}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>LCDA</Label>
                                <SearchableSelect 
                                    value={data?.lcda}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.title === value?.title
                                    }}
                                    onChange={(e,value)=>{
                                        setData({...data,lcda : value})
                                    }}
                                    inputValue={inputValue?.lcda  ? inputValue?.lcda : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,lcda : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={lcdas?.data?.data || []}
                                    loading={loadingLCDAs}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Area</Label>
                                <SearchableSelect 
                                    value={data?.area}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.title === value?.title
                                    }}
                                    onChange={(e,value)=>{
                                        setData({...data,area : value})
                                    }}
                                    inputValue={inputValue?.area  ? inputValue?.area : ""}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue({...inputValue,area : newInputValue });
                                    }}
                                    getOptionLabel={(option) => option?.name ? Capitalize(option?.name) : ""}
                                    options={areas?.data?.data || []}
                                    loading={loadingAreas}
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container container spacing={2} marginTop={"1rem"}>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Currency</Label>
                                <CustomSelect value={data.currency} onChange={e=>setData({...data,currency : e.target.value})}>
                                    <MenuItem value="GBP">GBP</MenuItem>
                                    <MenuItem value="NGN">NGN</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                </CustomSelect>
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Price/ Km-(7AM - 12PM)</Label>
                                <Input type="text" placeholder='Morning price'
                                    value={data?.price_morning ? numeral(data?.price_morning).format("0,0") : ""}
                                    onChange={(e)=>setData({...data,price_morning : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                        <Container item md={3}>
                            <FormGroup>
                                <Label>Price/ Km-(12:01PM -6PM)</Label>
                                <Input type="text" placeholder='Evening price'
                                    value={data?.price_evening ? numeral(data?.price_evening).format("0,0") : ""}
                                    onChange={(e)=>setData({...data,price_evening : e.target.value})}
                                />
                            </FormGroup>
                        </Container>
                    </Container>
                    <Container marginTop={"2rem"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                    >
                        <CustomButton variant='outlined'
                            width={"6rem"}
                            padding={"0.4rem"}
                            border={`1.5px solid ${AppColors.red}`}
                            borderradius={"0.452rem"}
                            loading={isLoading || isUpdating}
                            disabled={isLoading || isUpdating}
                            onClick={handleSubmit}

                        >
                            <H1 fontWeight={"bold"} color={AppColors.red}>Save</H1>
                        </CustomButton>
                    </Container>
                </form>
            </Container>
            <Container>
                <DataGridDemo columns={columns} 
                    rows={prices?.data?.data && Array.isArray(prices?.data?.data) ? prices?.data?.data : []}
                    hideFooter={false}
                    loading={loading}
                    CustomPagination={RenderPagination}
                    Toolbar={()=><Container display={"flex"} alignItems={"center"} marginBottom={"1rem"}>
                        <H1 variant='h6' fontWeight={"bold"} fontSize={"1.011rem"}
                            marginRight={"1rem"}
                        >New Price per KM</H1>
                        <Input type="text" placeholder='Search'
                            startAdornment={<Search sx={styles.searchIcon} />}
                        />
                    </Container>}
                />
                <WarningModal 
                    open={show} handleDelete={handleDelete} closeWarning={closeWarning}
                    loading={isDeleting}
                    selection={selected?.client?.name}
                />
            </Container>
        </PaperBox>
    )
}

export default KMPrice;