import { Container, H1 } from "../../components"

const NotFound = () => {
    return(
        <Container display="flex" justifyContent={"center"} flex={1} alignItems={"center"}
            height={"10rem"}
        >
            <H1>NOT FOUND! PLEASE GO BACK!</H1>
        </Container>
    )
}

export default NotFound;