import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom"
import Login from './pages/Login';
import Authentication from './layouts/Authentication';
import ResetPwd from './pages/ResetPwd';
import Recover from './pages/Recover';
import { Authorized } from './layouts/Authorized';
import { Dashboard } from './pages/User/Dashboard';
import React from 'react';
import SwipeableTemporaryDrawer from './components/Drawers';
import BikeeWallet from './pages/User/Financials/BikeeWallet';
import CashDelivery from './pages/User/Financials/CashDelivery';
import ClientsWallet from './pages/User/Financials/ClientsWallet';
import ClientsInvoice from './pages/User/Financials/ClientsInvoice';
import CreateSubFees from './pages/User/Settings/CreateSubFees';
import POSDetails from './pages/User/Settings/POSDetails';
import CreateIndustry from './pages/User/CreateIndustry';
import KMPrice from './pages/User/Prices/KMPrice';
import VolumePrice from './pages/User/Prices/VolumePrice';
import Clients from './pages/User/Clients';
import ClientDetails from './pages/User/ClientDetails';
import Teams from './pages/User/Teams/TeamMembers';
import AdminPage from './pages/User/AdminPage';
import RiderPage from './pages/User/RiderPage';
import Riders from './pages/User/Teams/Riders/Riders';
import CreateCountry from './pages/User/CreateCountry';
import CreateState from './pages/User/CreateState';
import CreateLCDA from './pages/User/CreateLCDA';
import CreateArea from './pages/User/OperationArea/CreateArea';
import ListArea from './pages/User/OperationArea/ListArea';
import { Toaster } from 'react-hot-toast';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import VerifyToken from './pages/VerifyToken';
import NotFound from './pages/NotFound';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
 
function App() {
  return (
    <QueryClientProvider client={queryClient}>
        <Router>
        <SwipeableTemporaryDrawer />
          <Routes>
            <Route path="/" element={<Authentication />}>
                <Route  index element={<Login />} />
                <Route path="login" element={<Login />} />
                <Route   path="reset-password/:otp" element={<ResetPwd />} />
                <Route   path="verify-token/:email" element={<VerifyToken />} />
                <Route   path="recover" element={<Recover />} />
            </Route>
            <Route path="/user" element={<Authorized />}>
              <Route  index path="dashboard" element={<Dashboard />} />
              <Route path="bikee-wallet" element={<BikeeWallet />} />
              <Route path="cash-delivery" element={<CashDelivery />} />
              <Route path="client-wallet" element={<ClientsWallet />} />
              <Route path="client-invoice" element={<ClientsInvoice />} />
              <Route path="client-list" element={<Clients />} />
              <Route path="client-details/:client_id/:client_name" element={<ClientDetails />} />
              <Route path="create-sub-fee" element={<CreateSubFees />} />
              <Route path="pos-details" element={<POSDetails />} />
              <Route path="km-price" element={<KMPrice />} />
              <Route path="volume-price" element={<VolumePrice />} />
              <Route path="create-industry" element={<CreateIndustry />} />
              <Route path="team-list" element={<Teams />} />
              <Route path="rider-list" element={<Riders />} />
              <Route path="admin-page/:admin_id" element={<AdminPage />} />
              <Route path="rider-page/:rider_id" element={<RiderPage />} />
              <Route path="create-country" element={<CreateCountry />} />
              <Route path="create-state/:id/:country_name" element={<CreateState />} />
              <Route path="create-lcda/:state_id/:state_name" element={<CreateLCDA />} />
              <Route path="create-area" element={<CreateArea />} />
              <Route path="list-areas" element={<ListArea />} />
              <Route path="area-riders/:area_id/:name" element={<Riders />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <Toaster />
    </QueryClientProvider>
  );
}

export default App;
