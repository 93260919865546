import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from './drawerSlice';
import pagination from './paginationSlice'
import user from "./userSlice"
import order from "./orderSlice"

const store = configureStore({
    reducer : {
        drawerReducer,
        pagination,
        user,
        order
    }
})
export default store;